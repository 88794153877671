import React, { useState, useEffect, useMemo } from 'react'
import ShortId from 'shortid'
import Lodash from 'lodash'

import Select from 'components/common/Select'
import Input from 'components/common/Input'

import { usePrevious } from 'helpers/Functions'

import Node from './index.js'

import {
  getSensitiveRefColumn,
  parseAttributeOptions,
  findAffected,
  DELIMITERS
} from './conf.js'

import Box from 'components_new/atoms/Box'
import Button from 'components_new/atoms/Button'
import Divider from 'components_new/atoms/Divider'
import Chip from 'components_new/atoms/Chip'
import Icon from 'components_new/atoms/Icon'
import IconButton from 'components_new/atoms/IconButton'

const ConcatenateNode = ({ id, selected, data, setData }) => {
  const leftNodeId = useMemo(() => data?.leftNode, [data?.globalNodeData])
  const leftNode = useMemo(
    () => data?.globalNodeData?.[data.leftNode],
    [data?.globalNodeData]
  )
  const previousLeftNodeId = usePrevious(leftNodeId)
  const leftAttributes = useMemo(() => {
    return leftNode?.data?.outputAttributes || []
  }, [leftNode])
  const [mounted, setMounted] = useState(false)

  useEffect(() => {
    if (mounted && previousLeftNodeId !== leftNodeId) {
      const timer = setTimeout(() => {
        setData(id, {
          outputAttributes: [
            ...data.outputAttributes.filter((item) => item.prepend === id),
            ...leftAttributes.map((attribute) => ({
              ...attribute,
              leftAttribute: null,
              rightAttribute: null,
              function: null
            }))
          ]
        })
      }, 1000)

      return () => clearTimeout(timer)
    }

    setMounted(true)
  }, [leftNodeId])

  return (
    <Node
      icon="MergeOutlined"
      title={'Concatenate'}
      selected={selected}
      leftInputCharacter={'a'}
      rightInputCharacter={'b'}
      nodeNumber={data.nodeNumberMapper[id]}
    >
      <Box sx={{ width: '320px' }}>
        {data.outputAttributes.map(
          (attribute, index) =>
            attribute.prepend === id && (
              <Box key={`concatenate-attr-${index}`}>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                  <Box
                    sx={{
                      alignItems: 'center',
                      display: 'flex',
                      flexDirection: 'column',
                      gap: 0.25
                    }}
                  >
                    <Chip
                      color="primary"
                      label="a"
                      size="small"
                      sx={{ mr: 0.5 }}
                    />
                    <Chip
                      color="primary"
                      label="b"
                      size="small"
                      sx={{ mr: 0.5 }}
                    />
                  </Box>
                  <Box
                    sx={{
                      flex: 1,
                      display: 'flex',
                      flexDirection: 'column',
                      gap: 0.25
                    }}
                  >
                    <Select
                      search
                      dropdownMatchSelectWidth={false}
                      ignoreLockedMode
                      inNode
                      size={'small'}
                      containerClassName={'width-100 overflow-ellipsis nodrag'}
                      value={attribute.leftAttribute}
                      onChange={(val) => {
                        const tmpOutput = Lodash.cloneDeep(
                          data.outputAttributes
                        )

                        tmpOutput[index].sensitiveRefColumnId =
                          getSensitiveRefColumn(
                            val,
                            tmpOutput[index].rightAttribute,
                            leftAttributes
                          )

                        tmpOutput[index].leftAttribute = val
                        setData(id, { outputAttributes: tmpOutput })
                      }}
                      options={parseAttributeOptions(
                        leftAttributes,
                        data.nodeNumberMapper
                      )}
                    />
                    <Select
                      search
                      dropdownMatchSelectWidth={false}
                      ignoreLockedMode
                      inNode
                      size={'small'}
                      containerClassName={'width-100 overflow-ellipsis nodrag'}
                      value={attribute.rightAttribute}
                      onChange={(val) => {
                        const tmpOutput = Lodash.cloneDeep(
                          data.outputAttributes
                        )

                        tmpOutput[index].sensitiveRefColumnId =
                          getSensitiveRefColumn(
                            tmpOutput[index].leftAttribute,
                            val,
                            leftAttributes
                          )

                        tmpOutput[index].rightAttribute = val
                        setData(id, { outputAttributes: tmpOutput })
                      }}
                      options={parseAttributeOptions(
                        leftAttributes,
                        data.nodeNumberMapper
                      )}
                    />
                  </Box>
                  <Icon name="Merge" sx={{ transform: 'rotate(90deg)' }} />
                  <Box
                    sx={{
                      flex: 1,
                      display: 'flex',
                      flexDirection: 'column',
                      gap: 0.25
                    }}
                  >
                    <Input
                      className={'nodrag'}
                      size={'small'}
                      value={attribute.name}
                      onChange={(val) => {
                        const tmpOutput = Lodash.cloneDeep(
                          data.outputAttributes
                        )

                        tmpOutput[index].name = val.target.value
                        tmpOutput[index].realName = val.target.value
                        setData(id, { outputAttributes: tmpOutput })
                      }}
                    />
                    <Select
                      className={'nodrag'}
                      dropdownMatchSelectWidth={false}
                      inNode
                      ignoreLockedMode
                      placeholder={
                        <small className="secondary tiny">
                          Välj avgränsare
                        </small>
                      }
                      value={attribute.delimiter}
                      onChange={(delimiter) => {
                        const tmpOutput = Lodash.cloneDeep(
                          data.outputAttributes
                        )

                        tmpOutput[index].delimiter = delimiter
                        setData(id, { outputAttributes: tmpOutput })
                      }}
                      size={'small'}
                      options={DELIMITERS}
                    />
                  </Box>
                  <IconButton
                    className={'nodrag'}
                    edge="end"
                    onClick={() => {
                      const tmpOutput = Lodash.cloneDeep(data.outputAttributes)
                      const removed = tmpOutput.splice(index, 1)[0]
                      const extraUpdate = findAffected(
                        data?.globalNodeData,
                        id,
                        {},
                        removed.shortId
                      )

                      setData(id, { outputAttributes: tmpOutput }, extraUpdate)
                    }}
                    size="small"
                  >
                    <Icon fontSize="small" name="Close" />
                  </IconButton>
                </Box>
                <Divider sx={{ my: 1 }} />
              </Box>
            )
        )}
        <Button
          className={'nodrag'}
          fullWidth
          onClick={() => {
            setData(id, {
              outputAttributes: [
                ...data.outputAttributes,
                {
                  leftAttribute: undefined,
                  rightAttribute: undefined,
                  name: undefined,
                  realName: undefined,
                  shortId: ShortId.generate(),
                  type: 'STRING',
                  prepend: id,
                  // default: Inget tecken
                  delimiter: ''
                }
              ]
            })
          }}
          size="small"
          startIcon={<Icon name="AddCircleOutlineOutlined" fontSize="small" />}
          variant="text"
        >
          Lägg till
        </Button>
      </Box>
    </Node>
  )
}

export default ConcatenateNode
