import { getAll, create, update, destroy } from 'redux/api/KpiVariables'
import { put } from 'redux/api/SortedKpiVariables'
import * as Types from 'redux/Types'

import { Dispatch } from 'redux'

export function tryPutSortedKpiVariables(data: string[]) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: Types.PUT_SORTED_KPI_VARIABLES
    })

    put(data)
      .then((response) => {
        dispatch({
          type: Types.PUT_SORTED_KPI_VARIABLES_SUCCESS,
          payload: response.data
        })
      })
      .catch(() => {
        dispatch({
          type: Types.PUT_SORTED_KPI_VARIABLES_FAILED
        })
      })
  }
}

export function tryGetAllKpiVariables() {
  return (dispatch: Dispatch) => {
    dispatch({
      type: Types.GET_KPI_VARIABLES
    })

    getAll()
      .then((response) => {
        dispatch({
          type: Types.GET_KPI_VARIABLES_SUCCESS,
          payload: response.data
        })
      })
      .catch(() => {
        dispatch({
          type: Types.GET_KPI_VARIABLES_FAILED
        })
      })
  }
}

export function tryCreateKpiVariable(data: {
  name: string
  level: number
  parent_kpi_variable_id?: string
}) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: Types.CREATE_KPI_VARIABLE
    })

    create({ data })
      .then((response) => {
        dispatch({
          type: Types.CREATE_KPI_VARIABLE_SUCCESS,
          payload: response.data
        })

        dispatch({
          type: Types.TOGGLE_SNACKBAR_OPEN,
          payload: { message: 'Kontonivå har skapats' }
        })
      })
      .catch(() => {
        dispatch({
          type: Types.CREATE_KPI_VARIABLE_FAILED
        })

        dispatch({
          type: Types.TOGGLE_SNACKBAR_OPEN,
          payload: { message: 'Kontonivå kunde inte skapas' }
        })
      })
  }
}

export function tryUpdateKpiVariable(
  id: string,
  data: { name: string },
  callback?: (err?: boolean) => void
) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: Types.UPDATE_KPI_VARIABLE
    })

    update(id, { data })
      .then((response) => {
        dispatch({
          type: Types.UPDATE_KPI_VARIABLE_SUCCESS,
          payload: response.data
        })

        dispatch({
          type: Types.TOGGLE_SNACKBAR_OPEN,
          payload: { message: 'Kontonivå har uppdaterats' }
        })

        if (callback) {
          callback()
        }
      })
      .catch(() => {
        dispatch({
          type: Types.UPDATE_KPI_VARIABLE_FAILED
        })

        dispatch({
          type: Types.TOGGLE_SNACKBAR_OPEN,
          payload: { message: 'Kontonivå kunde inte uppdateras' }
        })

        if (callback) {
          callback(true)
        }
      })
  }
}

export function tryDeleteKpiVariable(
  id: string,
  callback?: (err?: boolean) => void
) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: Types.DELETE_KPI_VARIABLE
    })

    destroy(id)
      .then(() => {
        dispatch({
          type: Types.DELETE_KPI_VARIABLE_SUCCESS,
          payload: { id }
        })

        dispatch({
          type: Types.TOGGLE_SNACKBAR_OPEN,
          payload: { message: 'Kontonivå har tagits bort' }
        })

        if (callback) {
          callback()
        }
      })
      .catch(() => {
        dispatch({
          type: Types.DELETE_KPI_VARIABLE_FAILED
        })

        dispatch({
          type: Types.TOGGLE_SNACKBAR_OPEN,
          payload: { message: 'Kontonivå kunde inte tas bort' }
        })

        if (callback) {
          callback(true)
        }
      })
  }
}
