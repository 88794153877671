import React from 'react'

import { getStatus } from '../utils'

import BodyCell from '../BodyCell'
import SegmentCell from '../SegmentCell'

import {
  DatasetType,
  DefaultDatasetData,
  FormattedWidgetDataDataset,
  ParsedSegmentPath,
  ParsedWidgetDataLabel,
  WidgetObject
} from 'types/GlobalWidget'

interface BodyRowProps {
  customSegmentBy: string | null
  datasets: FormattedWidgetDataDataset[]
  embedded: boolean
  isComparativeButIllegal: boolean
  label: ParsedWidgetDataLabel
  level?: number
  link: string | null
  row: number
  onClickExternalLink: ((link: string | null) => void) | null
  scaleFactor: number
  segmentMaxChars: number | null
  setCustomSegmentBy:
    | ((attributeOptionId: string, segment: ParsedSegmentPath[]) => void)
    | null
  setDetailsDialog: ((segment: ParsedSegmentPath[]) => void) | null
  setSegmentFilters: ((segment: ParsedSegmentPath[]) => void) | null
  verticalDividers: boolean
  widget: WidgetObject
}

const BodyRow = (props: BodyRowProps) => {
  const {
    customSegmentBy,
    datasets,
    embedded,
    isComparativeButIllegal,
    label,
    level = 0,
    link,
    onClickExternalLink,
    row,
    scaleFactor,
    segmentMaxChars,
    setCustomSegmentBy,
    setDetailsDialog,
    setSegmentFilters,
    verticalDividers,
    widget
  } = props

  // add current segment label to path (used with filters)
  const segments: ParsedSegmentPath[] = [
    ...label.path,
    {
      ...label,
      attribute_option_id: label.attribute_option_id,
      label: label.label,
      period: label.period
    }
  ]

  return (
    <>
      <SegmentCell
        customSegmentBy={customSegmentBy}
        divider={verticalDividers}
        label={label.display_label}
        level={level}
        link={embedded ? undefined : link}
        onClickExternalLink={onClickExternalLink}
        scaleFactor={scaleFactor}
        segments={segments}
        setCustomSegmentBy={setCustomSegmentBy}
        setDetailsDialog={setDetailsDialog}
        setSegmentFilters={setSegmentFilters}
        maxChars={segmentMaxChars}
        variant="body"
        widget={widget}
      />
      {datasets.map((dataset, column) => {
        const increaseIsPositive = dataset.increaseIsPositive
        const lowerThresholdValue = dataset.lowerBoundThreshold
        // @TODO: Make unit optional in next release (or move to head):
        const unit = dataset.prettyData[row] !== null ? dataset.unit : ''
        const upperThresholdValue = dataset.upperBoundThreshold
        const prettyValue = dataset.prettyData[row] ?? ''
        const showDiff = Boolean(dataset.comparativeDisplayTypeSelected)
        const percentageDiff = dataset.percentages
          ? dataset.percentages[row]
          : undefined
        const status = dataset.statuses[row]
        // const numberOfCharacters = dataset.numberOfCharacters

        if (dataset.type === DatasetType.NUMBER) {
          return (
            <BodyCell
              key={`row-${row}-column-${column}`}
              color={
                upperThresholdValue !== null
                  ? `${getStatus(
                    (dataset.data as DefaultDatasetData)[row],
                    upperThresholdValue,
                    lowerThresholdValue,
                    increaseIsPositive
                  )}.text`
                  : 'text.primary'
              }
              divider={verticalDividers}
              increaseIsPositive={increaseIsPositive}
              isComparativeButIllegal={isComparativeButIllegal}
              label={prettyValue + ' ' + unit} // @TODO: Unit rework.
              percentageDiff={percentageDiff}
              scaleFactor={scaleFactor}
              status={status}
              showDiff={showDiff}
              type={dataset.type}
            />
          )
        } else if (dataset.type === DatasetType.NUMBER_TAG) {
          return (
            <BodyCell
              divider={verticalDividers}
              type={dataset.type}
              label={(prettyValue as string[]).map((val) =>
                unit ? `${val} ${unit}` : val
              )}
              scaleFactor={scaleFactor}
              status={status}
              // numberOfCharacters={numberOfCharacters}
              key={`row-${row}-column-${column}`}
            />
          )
        } else {
          return (
            <BodyCell
              divider={verticalDividers}
              label={prettyValue as string[]}
              scaleFactor={scaleFactor}
              status={status}
              type={dataset.type}
              key={`row-${row}-column-${column}`}
            />
          )
        }
      })}
    </>
  )
}

export default BodyRow
