import React, { useEffect } from 'react'

import Box from 'components_new/atoms/Box'
import Link from 'components_new/atoms/Link'
import Table from 'components_new/atoms/Table'
import TableBody from 'components_new/atoms/Table/TableBody'
import TableCell from 'components_new/atoms/Table/TableCell'
import TableHead from 'components_new/atoms/Table/TableHead'
import TableRow from 'components_new/atoms/Table/TableRow'
import TableSortLabel from 'components_new/atoms/Table/TableSortLabel'
import TableFooter from 'components_new/atoms/Table/TableFooter'
import Tag from 'components_new/atoms/Tag'
import Text from 'components_new/atoms/Text'

import { TableVirtuoso, TableComponents, TableBodyProps } from 'react-virtuoso'
import { Order, Sort } from 'utils/types'
import { DatasetDataValue, DatasetType } from 'types/GlobalWidget'
import { parseDatasetValue, isNumberDatasetType } from 'utils/functions'

export type TTableRow = {
  segment: {
    label: string
    link?: string
  }
} & {
  [columnId: string]: DatasetDataValue
}

type OnSort = (sort: Sort) => void

export interface TableColumn {
  id: string
  index?: number
  hidden?: boolean
  label: string
  type?: DatasetType
}

function parseTableRowValue(
  dataValue: DatasetDataValue,
  dtype: DatasetType = DatasetType.STRING
) {
  const value = parseDatasetValue(dataValue, dtype)

  if (isNumberDatasetType(dtype)) {
    return value?.toLocaleString('sv-SE') ?? null
  } else if (dtype === DatasetType.ENUM) {
    return (
      <Box sx={{ display: 'flex', gridGap: 4, justifyContent: 'center' }}>
        {(value as string[]).map((item, i) => (
          <Tag key={i} label={item} />
        ))}
      </Box>
    )
  }

  return (value as string[]).join(',')
}

const VirtuosoTableComponents: TableComponents<TTableRow> = {
  Table: (props: any) => (
    <Table {...props} sx={{ borderCollapse: 'separate' }} size={'small'} />
  ),
  TableHead: ({ ref, ...props }) => <TableHead {...props} ref={ref} />,
  TableRow: (props: any) => <TableRow hover={true} {...props} />,
  TableBody: React.forwardRef<HTMLTableSectionElement>((props, ref) => (
    <TableBody {...props} ref={ref} />
  )) as React.ComponentType<
    TableBodyProps & {
      context?: any
    }
  >,
  TableFoot: ({ ref, ...props }) => (
    <TableFooter {...props} ref={ref} style={{ position: 'relative' }} />
  )
}

/**
 * Calculate width of column based on options and char length
 */
// const calculateWidth = (column: ColumnData) => {
//   const charactersWidth = column.label.length * 6
//   const sortIconWidth = 24
//   const filterIconWidth = column.filter ? 36 : 0

//   return charactersWidth + sortIconWidth + filterIconWidth
// }

/**
 * Get value alignment in column
 * @param dtype - DatasetType of column
 */
const getAligment = (dtype?: DatasetType) => {
  if (dtype === DatasetType.ENUM) {
    return 'center'
  }

  if (isNumberDatasetType(dtype) || dtype === DatasetType.DATE) {
    return 'right'
  }

  return 'left'
}

function fixedHeaderContent(
  columns: TableColumn[],
  sort: Sort | null,
  onSort?: (column: string) => void
) {
  return (
    <TableRow>
      {columns.map((column, i) => {
        if (column.hidden) {
          return null
        }

        const active = column.id === sort?.attribute
        const direction = sort?.attribute === column.id ? sort.direction : 'asc'

        return (
          <TableCell
            key={column.id}
            variant={'head'}
            align={getAligment(column.type)}
            sx={{
              bgcolor: 'background.paper',
              whiteSpace: 'nowrap',
              width: '1%', // For auto sized, fit content cells. (Hacker).
              ...(i === 0
                ? {
                    position: 'sticky',
                    left: 0,
                    zIndex: 2,
                    borderRight: '1px solid',
                    borderColor: 'divider',
                    bgcolor: 'background.default'
                  }
                : null)
            }}
            sortDirection={direction}
          >
            {onSort ? (
              <TableSortLabel
                onClick={() => onSort(column.id)}
                active={active}
                direction={direction}
                sx={{
                  fontSize: 'inherit',
                  '& svg': {
                    fontSize: '1.25em',
                    ...(!active
                      ? {
                          position: 'absolute',
                          right: -20
                        }
                      : null)
                  }
                }}
              >
                {column.label}
              </TableSortLabel>
            ) : (
              column.label
            )}
          </TableCell>
        )
      })}
    </TableRow>
  )
}

function rowContent(_index: number, row: TTableRow, columns: TableColumn[]) {
  return (
    <React.Fragment>
      {columns.map((column, i) => {
        if (column.hidden) {
          return null
        }

        return (
          <TableCell
            key={column.id}
            align={getAligment(column.type)}
            sx={{
              ...(i === 0
                ? {
                    position: 'sticky',
                    left: 0,
                    borderRight: '1px solid',
                    borderColor: 'divider',
                    bgcolor: 'background.default'
                  }
                : null),
              whiteSpace: 'nowrap',
              width: '1%' // For auto sized, fit content cells. (Hacker).
            }}
          >
            {i === 0 ? (
              <Text component="span" fontWeight="medium" variant="body2">
                {row.segment.link ? (
                  <Link href={row.segment.link} newTab={true}>
                    {row.segment.label}
                  </Link>
                ) : (
                  row.segment.label
                )}
              </Text>
            ) : (
              parseTableRowValue(row[column.id], column.type)
            )}
          </TableCell>
        )
      })}
    </React.Fragment>
  )
}

interface SegmentVirtualizedTableProps {
  data: TTableRow[]
  columns: TableColumn[]
  loading: boolean
  sort?: Sort | null
  onSort?: OnSort
}

// @TODO: Change this to be on the data format we use in the rest of the app:
// data: {
//   value: (null | number | string)[]
//   id: string
//   index: number
//   label: string
//   type: 'date' | 'number' | 'text'
// }[]
// /* ... */
// segments: {
//   labels: string[]
//   links?: string[]
//   title: string
// }

function suppressResizeObserver(e: ErrorEvent) {
  if (
    e.message === 'ResizeObserver loop limit exceeded' ||
    e.message ===
      'ResizeObserver loop completed with undelivered notifications.'
  ) {
    const resizeObserverErrDiv = document.getElementById(
      'webpack-dev-server-client-overlay-div'
    )
    const resizeObserverErr = document.getElementById(
      'webpack-dev-server-client-overlay'
    )

    if (resizeObserverErr) {
      resizeObserverErr.setAttribute('style', 'display: none')
    }
    if (resizeObserverErrDiv) {
      resizeObserverErrDiv.setAttribute('style', 'display: none')
    }
  }
}

const SegmentVirtualizedTable = (props: SegmentVirtualizedTableProps) => {
  const { columns, data, loading, sort, onSort } = props

  const orderBy = sort?.attribute || null
  const order = sort?.direction ?? 'asc'

  function sortHandler(id: string) {
    if (!onSort || !id || loading) {
      return
    }
    let newOrder: Order = 'asc'
    let newOrderBy = !orderBy ? id : orderBy

    if (orderBy === id) {
      // order changed
      newOrder = order === 'asc' ? 'desc' : 'asc'
    } else {
      newOrderBy = id
    }

    onSort({
      attribute: newOrderBy,
      direction: newOrder
    })
  }

  // This is used to suppress warning from resizeObserver.
  useEffect(() => {
    window.addEventListener('error', suppressResizeObserver)
  }, [])

  return (
    <>
      <TableVirtuoso
        data={data}
        components={VirtuosoTableComponents}
        fixedHeaderContent={() =>
          fixedHeaderContent(columns, sort ?? null, sortHandler)
        }
        itemContent={(index: number, row: TTableRow) =>
          rowContent(index, row, columns)
        }
        overscan={200}
      />
    </>
  )
}

SegmentVirtualizedTable.displayName = 'SegmentVirtualizedTable'
export default SegmentVirtualizedTable
