import React, {
  ElementType,
  KeyboardEventHandler,
  MouseEventHandler,
  ReactNode,
  Ref
} from 'react'
import {
  Menu as MUIMenu,
  PopoverActions,
  PopoverReference
} from '@mui/material'

export interface MenuProps {
  action?: Ref<PopoverActions>
  anchorEl?: HTMLElement | null
  anchorOrigin?: {
    horizontal: 'center' | 'left' | 'right' | number
    vertical: 'bottom' | 'center' | 'top' | number
  }
  anchorPosition?: { top: number; left: number }
  anchorReference?: PopoverReference
  children: ReactNode
  component?: ElementType<any>
  disablePortal?: boolean
  disableScrollLock?: boolean
  hideBackdrop?: boolean
  MenuListProps?: object
  onClose?: (() => void) | MouseEventHandler<HTMLElement>
  onKeyDown?: (() => void) | KeyboardEventHandler
  onMouseDown?: (() => void) | MouseEventHandler<HTMLElement>
  open: boolean
  PaperProps?: object
  slotProps?: object
  transformOrigin?: {
    horizontal: 'center' | 'left' | 'right' | number
    vertical: 'bottom' | 'center' | 'top' | number
  }
  variant?: 'menu' | 'selectedMenu'
  sx?: object
}

/**
 * The Menu component displays a list of choices
 * on a temporary surface. It appears when the
 * user interacts with a button, or other control.
 *
 * A basic menu opens over the anchor element by
 * default. Choosing an option should immediately
 * ideally commit the option and close the menu.
 */

const Menu = React.forwardRef((props: MenuProps, ref: any) => {
  const { children, ...rest } = props

  return (
    <MUIMenu {...rest} ref={ref}>
      {children}
    </MUIMenu>
  )
})

Menu.displayName = 'Menu'
export default Menu
