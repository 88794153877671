import React, { useMemo, useState } from 'react'

import Button from 'components_new/atoms/Button'
import SimpleDialog from 'components_new/molecules/SimpleDialog'

import EditVariableSection from './EditVariableSection'

import { KpiVariable } from 'types/GlobalKpiVariable'

interface EditVariableDialogProps {
  name: string
  onClose: () => void
  onSave: (name: string, callback: () => void) => void
  open: boolean
  variableId: string
  variables: KpiVariable[]
}

const EditVariableDialog = (props: EditVariableDialogProps) => {
  const { variables, name, onClose, onSave, open, variableId } = props

  const [editName, setEditName] = useState<string>(name)
  const [loading, setLoading] = useState(false)

  const isDuplicate = useMemo(() => {
    return !!variables.find((v) => v.name === editName && v.id !== variableId)
  }, [editName])

  const parsedOnSave = () => {
    setLoading(true)
    onSave(editName, () => setLoading(false))
  }

  return (
    <SimpleDialog
      title="Redigera variabel"
      open={open}
      onClose={onClose}
      actions={
        <>
          <Button variant="text" onClick={onClose}>
            Avbryt
          </Button>
          <Button
            disabled={isDuplicate}
            loading={loading}
            onClick={() => parsedOnSave()}
            variant="contained"
          >
            Spara
          </Button>
        </>
      }
    >
      <EditVariableSection
        editName={editName}
        isDuplicate={isDuplicate}
        setEditName={setEditName}
      />
    </SimpleDialog>
  )
}

export default EditVariableDialog
