import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'

import { bindActionCreators, Dispatch } from 'redux'
import { connect, ConnectedProps } from 'react-redux'
import { DashboardGroup, DashboardStatus } from 'redux/reducers/Dashboards'

import * as DashboardGroupActions from 'redux/actions/DashboardGroups'

import Button from 'components_new/atoms/Button'
import Divider from 'components_new/atoms/Divider'
import Icon from 'components_new/atoms/Icon'
import ListItemIcon from 'components_new/atoms/List/ListItemIcon'
import ListItemText from 'components_new/atoms/List/ListItemText'
import Menu from 'components_new/atoms/Menu'
import MenuItem from 'components_new/atoms/Menu/MenuItem'
import TextField from 'components_new/atoms/TextField'
import Tooltip from 'components_new/atoms/Tooltip'

import SimpleDialog from 'components_new/molecules/SimpleDialog'

import { DashboardFolder } from 'types/GlobalDashboardFolder'
import { ApplicationState } from 'redux/Stores/types'

import { getDashboardLimitReached } from 'utils/functions'

/**
 * The EditDashboardMenu component is used
 * to edit dashboard settings.
 */

interface EditDashboardMenuProps {
  deleteCallback?: () => void
  folders: DashboardFolder[]
  group: DashboardGroup
  handleClose: () => void
  menuAnchorEl: HTMLAnchorElement | null
  paperSx?: object
  anchorOrigin?: {
    horizontal: 'center' | 'left' | 'right' | number
    vertical: 'bottom' | 'center' | 'top' | number
  }
}

const EditDashboardMenu = (props: ComponentProps) => {
  const history = useHistory()

  const { AuthStore, deleteCallback = () => {} } = props

  const [deleteOpen, setDeleteOpen] = useState(false)
  const [editOpen, setEditOpen] = useState(false)
  const [moveOpen, setMoveOpen] = useState(false)
  const [newFolderId, setNewFolderId] = useState<string | null>(
    props.group.folder_id
  )
  const [newTitle, setNewTitle] = useState(props.group.title)

  const handleCloseEdit = () => {
    setEditOpen(false)

    setNewTitle(props.group.title)
  }

  const handleCloseMove = () => {
    setMoveOpen(false)

    setNewFolderId(props.group.folder_id)
  }

  const isPublished = props.group.status === DashboardStatus.PUBLISHED

  const dashboardLimitReached = getDashboardLimitReached(AuthStore)

  return (
    <>
      <Menu
        anchorEl={props.menuAnchorEl}
        anchorOrigin={
          props.anchorOrigin
            ? props.anchorOrigin
            : { horizontal: 'left', vertical: 'bottom' }
        }
        disableScrollLock
        onClose={props.handleClose}
        onMouseDown={(e) => e.stopPropagation()}
        open={Boolean(props.menuAnchorEl)}
        PaperProps={{ sx: { mt: 22, width: 360, ...props.paperSx } }}
        MenuListProps={{ pointerEvents: 'auto' }}
        sx={{ width: 360 }}
        transformOrigin={
          props.anchorOrigin
            ? props.anchorOrigin
            : { horizontal: 'left', vertical: 'bottom' }
        }
      >
        <MenuItem
          onClick={() => {
            props.handleClose()

            setEditOpen(true)
          }}
        >
          <ListItemIcon>
            <Icon name="EditOutlined" />
          </ListItemIcon>
          <ListItemText primary="Byt namn" />
        </MenuItem>

        <MenuItem
          onClick={() => {
            props.handleClose()

            setMoveOpen(true)
          }}
        >
          <ListItemIcon>
            <Icon name="DriveFileMoveOutlined" />
          </ListItemIcon>
          <ListItemText primary="Flytta" />
        </MenuItem>

        <Tooltip
          placement="right"
          title={
            dashboardLimitReached
              ? 'Din organisation använder alla dashboards som ingår i er licens. Ta bort en dashboard eller kontakta oss för att utöka din licens.'
              : undefined
          }
        >
          <span>
            <MenuItem
              disabled={dashboardLimitReached}
              onClick={() => {
                props.handleClose()

                props.tryCopyDashboardGroup(props.group.id, (id) =>
                  history.push(`/dashboards/${id}`)
                )
              }}
            >
              <ListItemIcon>
                <Icon name="ContentCopyOutlined" />
              </ListItemIcon>
              <ListItemText primary="Duplicera" />
            </MenuItem>
          </span>
        </Tooltip>

        <Divider />

        {props.AuthStore.user?.is_homepal_user ? (
          <MenuItem
            onClick={() => {
              props.tryUpdateDashboardGroup(props.group.id, {
                data: {
                  status: isPublished
                    ? DashboardStatus.DRAFT
                    : DashboardStatus.PUBLISHED
                }
              })
              props.handleClose()
            }}
          >
            <ListItemIcon>
              <Icon
                name={
                  isPublished
                    ? 'UnpublishedOutlined'
                    : 'PublishedWithChangesOutlined'
                }
              />
            </ListItemIcon>
            <ListItemText primary={isPublished ? 'Avpublicera' : 'Publicera'} />
          </MenuItem>
        ) : null}

        <MenuItem
          onClick={() => {
            props.handleClose()

            setDeleteOpen(true)
          }}
        >
          <ListItemIcon>
            <Icon name="DeleteOutlined" />
          </ListItemIcon>
          <ListItemText primary="Radera" />
        </MenuItem>
      </Menu>
      <SimpleDialog
        title="Radera dashboard?"
        contentText={
          <>
            Din dashboard <code>{props.group.title}</code> kommer att raderas
            permanent. Är du säker?
          </>
        }
        onClose={() => setDeleteOpen(false)}
        open={deleteOpen}
        actions={
          <>
            <Button onClick={() => setDeleteOpen(false)} variant="text">
              Avbryt
            </Button>
            <Button
              onClick={() =>
                props.tryDeleteDashboardGroup(props.group.id, deleteCallback)
              }
              color="error"
              variant="contained"
            >
              Radera
            </Button>
          </>
        }
      />
      <SimpleDialog
        actions={
          <Button variant="text" onClick={handleCloseMove}>
            Stäng
          </Button>
        }
        onClose={handleCloseMove}
        open={moveOpen}
        title={`Flytta dashboarden "${props.group.title}"`}
      >
        <TextField
          fullWidth={true}
          label="Välj mapp..."
          select={true}
          autoComplete="off"
          value={newFolderId || null}
          onChange={(event) => setNewFolderId(event.target.value)}
          sx={{ mt: 1, mb: 2 }}
        >
          <MenuItem value={null}>
            <em>Ingen</em>
          </MenuItem>
          {props.folders.map((folder) => {
            return (
              <MenuItem key={folder.id} value={folder.id}>
                {folder.name}
              </MenuItem>
            )
          })}
        </TextField>
        <Button
          fullWidth
          variant="contained"
          size="large"
          onClick={() => {
            props.tryUpdateDashboardGroup(props.group.id, {
              data: { folder_id: newFolderId || null }
            })

            setMoveOpen(false)
          }}
        >
          Spara
        </Button>
      </SimpleDialog>

      <SimpleDialog
        actions={
          <>
            <Button variant="text" onClick={handleCloseEdit}>
              Avbryt
            </Button>
            <Button
              disabled={newTitle.length === 0}
              variant="contained"
              onClick={() => {
                props.tryUpdateDashboardGroup(props.group.id, {
                  data: { title: newTitle }
                })

                setEditOpen(false)
              }}
            >
              Spara
            </Button>
          </>
        }
        onClose={handleCloseEdit}
        open={editOpen}
        title="Byt namn"
      >
        <TextField
          fullWidth={true}
          label="Titel"
          autoComplete="off"
          value={newTitle}
          onChange={(event) => setNewTitle(event.target.value)}
          sx={{ mt: 1, mb: 2 }}
          required
        />
      </SimpleDialog>
    </>
  )
}

/*-- redux --*/
const mapStateToProps = (state: ApplicationState) => ({
  AuthStore: state.AuthStore
})

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      ...DashboardGroupActions
    },
    dispatch
  )
}

const connector = connect(mapStateToProps, mapDispatchToProps)

type ComponentProps = ConnectedProps<typeof connector> & EditDashboardMenuProps

export default connector(EditDashboardMenu)
