import React, { ElementType, MouseEventHandler, ReactNode } from 'react'
import { Link as MUILink, Theme } from '@mui/material'

import Icon from '../Icon'
import LinkWrapper from '../LinkWrapper'

interface LinkProps {
  children?: ReactNode
  color?:
    | string
    | 'inherit'
    | 'primary'
    | 'secondary'
    | 'tertiary'
    | 'quaternary'
    | 'quintary'
    | 'error'
    | 'info'
    | 'success'
    | 'warning'
    | 'black'
    | 'white'
    | 'neutral'
  component?: ElementType<any>
  disabled?: boolean
  disableHistory?: boolean
  goBack?: boolean
  href?: string
  newTab?: boolean
  onClick?: (() => void) | MouseEventHandler<HTMLElement>
  rel?: string
  search?: string
  sx?: object
  target?: string
  to?: string
  underline?: 'always' | 'hover' | 'none'
  variant?:
    | 'body1'
    | 'body2'
    | 'button'
    | 'caption'
    | 'gigantic'
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'h5'
    | 'h6'
    | 'inherit'
    | 'overline'
    | 'subtitle1'
    | 'subtitle2'
}

/**
 * The Link component allows you to easily customize anchor
 * elements with your theme colors and typography styles.
 *
 * The Link component is built on top of the Text
 * component.
 *
 * Note: When you use target="_blank" with Links, it is
 * recommended to always set rel="noopener" or
 * rel="noreferrer" when linking to third party content.
 */

const Link = React.forwardRef((props: LinkProps, ref: any) => {
  const {
    disabled,
    disableHistory,
    newTab,
    goBack,
    href,
    search,
    sx,
    to,
    ...rest
  } = props

  return (
    <LinkWrapper
      disabled={disabled}
      disableHistory={disableHistory}
      goBack={goBack}
      href={to ?? href}
      newTab={newTab}
      search={search}
    >
      <MUILink
        sx={(theme: Theme) => {
          return {
            cursor: 'pointer',
            ...(disabled
              ? {
                  color: theme.palette.action.disabled,
                  cursor: 'not-allowed',
                  pointerEvents: 'none',
                  textDecorationColor: theme.palette.action.disabled
                }
              : null),
            ...sx
          }
        }}
        {...rest}
        ref={ref}
      >
        {props.children}
        {newTab ? (
          <Icon fontSize="inherit" name="OpenInNew" sx={{ ml: 0.5 }} />
        ) : null}
      </MUILink>
    </LinkWrapper>
  )
})

Link.displayName = 'Link'
export default Link
