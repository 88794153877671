import React, { MouseEvent, useState } from 'react'

import TableCell from 'components_new/atoms/Table/TableCell'
import Text from 'components_new/atoms/Text'
import Link from 'components_new/atoms/Link'

import SegmentMenu from 'components_new/organisms/Widget/SegmentMenu'

import { ellipseLabel, getCellScaleSx } from '../utils'
import { ParsedSegmentPath, WidgetObject } from 'types/GlobalWidget'

interface SegmentCellProps {
  customSegmentBy: string | null
  divider: boolean
  label: string
  level: number
  link?: string | null
  maxChars: number | null
  onClickExternalLink: ((link: string | null) => void) | null
  scaleFactor: number
  segments: ParsedSegmentPath[]
  setCustomSegmentBy:
    | ((attributeOptionId: string, segment: ParsedSegmentPath[]) => void)
    | null
  setDetailsDialog: ((segment: ParsedSegmentPath[]) => void) | null
  setSegmentFilters: ((segment: ParsedSegmentPath[]) => void) | null
  variant: 'body' | 'footer' | 'head'
  widget: WidgetObject
}

const SegmentCell = (props: SegmentCellProps) => {
  const {
    customSegmentBy,
    divider,
    label,
    link,
    maxChars,
    onClickExternalLink,
    scaleFactor,
    segments,
    setCustomSegmentBy,
    setDetailsDialog,
    setSegmentFilters,
    variant,
    widget
  } = props

  const [anchorPosition, setAnchorPosition] = useState<{
    top: number
    left: number
  } | null>(null)

  const allowOnClick =
    !!setSegmentFilters || !!setDetailsDialog || !!onClickExternalLink

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    setAnchorPosition({ left: event.clientX, top: event.clientY })
  }

  const handleClose = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    setAnchorPosition(null)
  }

  const segmentTextComponent = (
    <Text
      color="inherit"
      component="span"
      fontSize="inherit"
      fontWeight="medium"
      variant="body2"
      // TODO: add better styling for each level
      sx={{
        paddingLeft: (segments.length - 1) * 2
      }}
    >
      {ellipseLabel(label, maxChars)}
    </Text>
  )

  return (
    <>
      <TableCell
        onClick={allowOnClick ? handleClick : undefined}
        sx={{
          borderRight: divider ? '1px solid' : undefined,
          '&:last-child': { borderRight: 0 },
          borderRightColor: 'divider',
          cursor: allowOnClick ? 'pointer' : undefined,
          whiteSpace: 'nowrap',
          width: '1%', // For auto sized, fit content cells. (Hacker).
          '&:hover': allowOnClick
            ? {
                bgcolor: 'action.hover',
                color: 'text.solid',
                textDecoration: 'underline'
              }
            : undefined,
          ...getCellScaleSx(scaleFactor)
        }}
        variant={variant}
      >
        {link ? (
          <Link
            underline="none"
            newTab={true}
            color="inherit"
            sx={{ '&:hover': { color: 'inherit' } }}
          >
            {segmentTextComponent}
          </Link>
        ) : (
          segmentTextComponent
        )}
      </TableCell>

      {/* ----- Menu ----- */}
      {allowOnClick && (
        <SegmentMenu
          anchorPosition={anchorPosition}
          customSegmentBy={customSegmentBy}
          link={link}
          onClickExternalLink={onClickExternalLink}
          setCustomSegmentBy={setCustomSegmentBy}
          setDetailsDialog={setDetailsDialog}
          setFilters={setSegmentFilters}
          onClose={handleClose}
          segments={segments}
          widget={widget}
        />
      )}
    </>
  )
}

export default SegmentCell
