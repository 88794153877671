import React, { useMemo } from 'react'

import { useTheme } from '@mui/material'

import { User } from 'types/GlobalUser'

import Box from 'components_new/atoms/Box'
import Card from 'components_new/atoms/Card'
import CardContent from 'components_new/atoms/Card/CardContent'
import CardActionArea from 'components_new/atoms/Card/CardActionArea'
import Chip from 'components_new/atoms/Chip'
import Icon from 'components_new/atoms/Icon'
import LinkWrapper from 'components_new/atoms/LinkWrapper'
import Text from 'components_new/atoms/Text'
import Tooltip from 'components_new/atoms/Tooltip'

import { getDashboardImageUrl, getThumbnail } from 'utils/functions'

interface DashboardExampleThumbnailProps {
  category?: string
  createdAt: string
  disabled?: boolean
  disableHistory?: boolean
  href: string
  id: string
  search?: string
  sx?: object
  systems?: any[]
  thumbnail?: string
  title: string
  totalVisits?: number
  updatedAt: string
  user: User | null
}

const DashboardExampleThumbnail = (props: DashboardExampleThumbnailProps) => {
  const {
    category,
    createdAt,
    disabled,
    disableHistory,
    href,
    id,
    search,
    sx,
    systems,
    title,
    totalVisits,
    updatedAt,
    user
  } = props

  const theme = useTheme()

  const createdDiff = Date.now() - Date.parse(createdAt)
  const createdDayDiff = Math.round(createdDiff / (1000 * 3600 * 24))
  const showNew = createdDayDiff < 30

  const updatedDiff = Date.now() - Date.parse(updatedAt)
  const updatedDayDiff = Math.round(updatedDiff / (1000 * 3600 * 24))
  const showUpdated = updatedDayDiff < 14 && !showNew

  const thumbnail = useMemo(() => {
    return `url(${getThumbnail(getDashboardImageUrl(id))})`
  }, [id])

  return (
    <Card
      variant="outlined"
      sx={{
        width: '100%',
        height: '100%',
        borderRadius: 4,
        '&:hover': !disabled
          ? {
              '& .DashboardImage': {
                transform: 'scale(1.1)'
              },
              '& .DashboardImageOverlay': {
                background:
                  'linear-gradient(180deg, transparent, 0%, transparent 100%)'
              }
            }
          : undefined,
        ...sx
      }}
    >
      <LinkWrapper
        disabled={disabled}
        disableHistory={disableHistory}
        href={href}
        search={search}
      >
        <CardActionArea
          disabled={disabled}
          sx={{ width: '100%', height: '100%', position: 'relative' }}
        >
          <Box sx={{ height: 200, position: 'relative', overflow: 'hidden' }}>
            {showNew || showUpdated ? (
              <Box
                className="DashboardImageOverlay"
                sx={{
                  position: 'absolute',
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  flexWrap: 'wrap',
                  gap: 0.5,
                  p: 1,
                  top: 0,
                  left: 0,
                  zIndex: 1,
                  background: `linear-gradient(180deg, ${theme.palette.background.transparent} -100%, transparent 200%)`,
                  transition: 'all .5s'
                }}
              >
                {showNew ? (
                  <Tooltip title={`${createdDayDiff} dagar sedan`}>
                    <Chip
                      color="primary"
                      label="Nyhet ✨"
                      size="small"
                      sx={{ bgcolor: theme.palette.background.default }}
                      variant="outlined"
                    />
                  </Tooltip>
                ) : null}
                {showUpdated ? (
                  <Tooltip title={`${updatedDayDiff} dagar sedan`}>
                    <Chip
                      color="primary"
                      label="Uppdaterad 🎉"
                      size="small"
                      sx={{ bgcolor: theme.palette.background.default }}
                      variant="outlined"
                    />
                  </Tooltip>
                ) : null}
              </Box>
            ) : null}
            <Box
              className="DashboardImage"
              sx={{
                height: '100%',
                width: '100%',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                WebkitBackgroundPosition: 'center',
                backgroundPosition: 'center',
                backgroundImage: thumbnail,
                transform: 'scale(1)',
                transition: 'all .5s'
              }}
            />
          </Box>
          <CardContent sx={{ borderTop: '1px solid', borderColor: 'divider' }}>
            <Box sx={{ flexGrow: 1, minWidth: 0 }}>
              {systems || totalVisits ? (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    mb: 1
                  }}
                >
                  {systems ? (
                    <Box sx={{ display: 'flex', gap: 0.5 }}>
                      {systems?.map((system: any) => (
                        <img
                          key={system.fields.slug}
                          src={system.fields.icon.fields.file.url}
                          height={12}
                          width={12}
                        />
                      ))}
                    </Box>
                  ) : null}

                  {totalVisits && user && user.is_homepal_user ? (
                    <Box
                      sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}
                    >
                      <Icon
                        name="VisibilityOutlined"
                        sx={{ color: 'text.secondary', fontSize: 14 }}
                      />
                      <Text color="text.secondary" variant="caption">
                        {totalVisits}
                      </Text>
                    </Box>
                  ) : null}

                  {/* Keep until next release: */}
                  {/* <Tooltip
                  placement="right"
                  title={
                    <Box sx={{ p: 2 }}>
                      <Box
                        sx={{
                          position: 'relative',
                          width: '100%',
                          paddingBottom: '56.25%'
                        }}
                      >
                        <Box
                          sx={{
                            position: 'absolute',
                            zIndex: 1,
                            top: '0%',
                            bottom: '0%',
                            left: '0%',
                            right: '0%',
                            overflow: 'hidden',
                            borderRadius: 1
                          }}
                        >
                          <Box
                            sx={{
                              position: 'relative',
                              width: '100%',
                              height: '100%'
                            }}
                          >
                            <iframe
                              src={`https://www.loom.com/embed/${'8a4affeafc384ffd90e38b6b2d54472c'}`}
                              title={title}
                              allow="accelerometer; autoplay; clipboard-write; 
                              encrypted-media; gyroscope; picture-in-picture; 
                              web-share; fullscreen"
                              frameBorder="0"
                              scrolling="no"
                              allowFullScreen={true}
                              width="100%"
                              height="100%"
                            ></iframe>
                          </Box>
                        </Box>
                      </Box>
                      <Box sx={{ mt: 2 }}>
                        <Text align="center" gutterBottom={true}>
                          Den här dashboarden kommer med en förklarande{' '}
                          <b>video</b>.
                        </Text>
                        <Text align="center">
                          <Link>Öppna video</Link>
                        </Text>
                      </Box>
                    </Box>
                  }
                >
                  <Chip
                    color="brand"
                    label="Video"
                    size="small"
                    icon={<Icon name="OndemandVideoOutlined" />}
                  />
                </Tooltip> */}
                </Box>
              ) : null}

              <Text noWrap={true} variant="subtitle1" color={'text.primary'}>
                {title}
              </Text>
              {category ? (
                <Text
                  variant="body1"
                  color="text.secondary"
                  sx={{
                    display: '-webkit-box',
                    overflow: 'hidden',
                    WebkitLineClamp: '3',
                    WebkitBoxOrient: 'vertical'
                  }}
                >
                  {category}
                </Text>
              ) : null}
            </Box>
          </CardContent>
        </CardActionArea>
      </LinkWrapper>
    </Card>
  )
}

export default DashboardExampleThumbnail
