import React, { useState, useMemo } from 'react'

import Button from 'components_new/atoms/Button'

import SimpleDialog from 'components_new/molecules/SimpleDialog'

import EditVariableSection from './EditVariableSection'

import { KpiVariable } from 'types/GlobalKpiVariable'

interface AddVariableDialogProps {
  onClose: () => void
  onSave: (name: string) => void
  open: boolean
  variables: KpiVariable[]
}

const AddVariableDialog = (props: AddVariableDialogProps) => {
  const { variables, onClose, onSave, open } = props

  const [editName, setEditName] = useState<string>('')

  const isDuplicate = useMemo(() => {
    return !!variables.find((v) => v.name === editName)
  }, [editName])

  return (
    <SimpleDialog
      title="Lägg till kontonivå"
      open={open}
      onClose={() => {
        onClose()
        setEditName('')
      }}
      actions={
        <>
          <Button
            variant="text"
            onClick={() => {
              onClose()
              setEditName('')
            }}
          >
            Avbryt
          </Button>
          <Button
            disabled={!editName || isDuplicate}
            variant="contained"
            onClick={() => {
              onSave(editName)
              setEditName('')
            }}
          >
            Spara
          </Button>
        </>
      }
    >
      <EditVariableSection
        editName={editName}
        isDuplicate={isDuplicate}
        setEditName={setEditName}
      />
    </SimpleDialog>
  )
}

export default AddVariableDialog
