import { createAction, createReducer } from '@reduxjs/toolkit'
import * as Types from 'redux/Types'

import { Email } from 'types/GlobalEmails'

export type EmailBody = {
  [key: string]: Email
}

export interface EmailReducerType {
  data: Email[]
  fetched: boolean
}

// Initial state
const INITIAL_STATE: EmailReducerType = {
  data: [],
  fetched: false
}

// Actions
const getAllSuccessAction = createAction<{ data: Email[] }>(
  Types.GET_ALL_EMAILS_SUCCESS
)
const createSuccessAction = createAction<{ data: Email }>(
  Types.CREATE_EMAIL_SUCCESS
)

const signOutAction = createAction(Types.SIGN_OUT)

// Reducer
const emailReducer = createReducer(INITIAL_STATE, (builder) => {
  builder
    .addCase(getAllSuccessAction, (state, action) => {
      const { payload } = action

      return {
        ...state,
        data: payload.data.sort((a, b) =>
          a.batch_name.localeCompare(b.batch_name, 'sv')
        ),
        fetched: true
      }
    })
    .addCase(createSuccessAction, (state, action) => {
      const { payload } = action

      return {
        ...state,
        data: [...state.data, payload.data].sort((a, b) =>
          a.batch_name.localeCompare(b.batch_name, 'sv')
        ),
        fetched: true
      }
    })
    .addCase(signOutAction, () => INITIAL_STATE)
    .addDefaultCase((state) => state)
})

export default emailReducer
