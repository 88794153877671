import React, { useRef, useState } from 'react'
import { useTheme } from '@mui/material/styles'

import 'chart.js/auto'
import { Chart } from 'react-chartjs-2'

import { translateWidgetType } from 'utils/enumTranslator'

import {
  htmlLegendPlugin,
  horizontalLinePlugin,
  verticalLinePlugin,
  statusAreaPlugin,
  getLegendBoxSize,
  getLegendFontSize
} from '../utils'

import Box from 'components_new/atoms/Box'
import Text from 'components_new/atoms/Text'

import NoValue from '../../errors/NoValue'
import SegmentMenu from '../../SegmentMenu'

import * as BubbleChartUtils from './utils'

import {
  FormattedWidgetData,
  ParsedSegmentPath,
  ParsedWidgetDataLabel,
  WidgetObject,
  WidgetType
} from 'types/GlobalWidget'

interface BubbleChartProps {
  customSegmentBy: string | null
  formattedData: FormattedWidgetData
  scaleFactor: number
  setCustomSegmentBy:
    | ((attributeOptionId: string, segment: ParsedSegmentPath[]) => void)
    | null
  setDetailsDialog: ((segment: ParsedSegmentPath[]) => void) | null
  setSegmentFilters: ((segment: ParsedSegmentPath[]) => void) | null
  widget: WidgetObject
}

const BubbleChart = (props: BubbleChartProps) => {
  const {
    customSegmentBy,
    formattedData,
    scaleFactor,
    setCustomSegmentBy,
    setDetailsDialog,
    setSegmentFilters,
    widget
  } = props

  const chartRef = useRef()
  const [anchorPosition, setAnchorPosition] = useState<{
    top: number
    left: number
  } | null>(null)
  const [selectedSegments, setSelectedSegments] = useState<
    ParsedWidgetDataLabel[] | null
  >(null)
  const allowOnClick = !!setSegmentFilters || !!setDetailsDialog

  const theme = useTheme()

  const handleClick = (
    event: PointerEvent,
    dataLabels: ParsedWidgetDataLabel[]
  ) => {
    event.stopPropagation()

    setAnchorPosition({ left: event.clientX, top: event.clientY })
    setSelectedSegments(dataLabels)
  }

  const handleClose = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.stopPropagation()

    setAnchorPosition(null)
    setSelectedSegments(null)
  }

  if (formattedData.datasets.length === 0) {
    return (
      <NoValue
        iconName={translateWidgetType[WidgetType.BUBBLE_CHART].iconName}
        scaleFactor={scaleFactor}
      />
    )
  }

  const bubbles = BubbleChartUtils.getBubbles(formattedData)

  const rAxis = formattedData.datasets.find((item) => item.index === 2)
  const rAxisUnit = rAxis?.unit || null
  const rAxisLabel = rAxis?.label || ''

  return (
    <>
      {rAxisLabel ? (
        <Box
          sx={{
            height: 32 * scaleFactor,
            overflowY: 'auto'
          }}
        >
          <RLabel
            scaleFactor={scaleFactor}
            title={rAxisLabel}
            unit={rAxisUnit}
          />
        </Box>
      ) : null}
      <Box sx={{ position: 'relative', flexGrow: 1, minHeight: 0 }}>
        <Chart
          type="bubble"
          ref={chartRef}
          data={bubbles}
          options={BubbleChartUtils.getOptions(
            allowOnClick,
            bubbles,
            formattedData,
            handleClick,
            theme,
            widget,
            scaleFactor
          )}
          plugins={[
            htmlLegendPlugin,
            horizontalLinePlugin,
            verticalLinePlugin,
            statusAreaPlugin
          ]}
        />
      </Box>
      {allowOnClick && selectedSegments && (
        <SegmentMenu
          anchorPosition={anchorPosition}
          customSegmentBy={customSegmentBy}
          onClose={handleClose}
          segments={selectedSegments}
          setCustomSegmentBy={setCustomSegmentBy}
          setDetailsDialog={setDetailsDialog}
          setFilters={setSegmentFilters}
          widget={widget}
        />
      )}
    </>
  )
}

const RLabel = (props: {
  scaleFactor: number
  title: string
  unit: string | null
}) => {
  const theme = useTheme()

  const boxSize = getLegendBoxSize(props.scaleFactor)
  const fontSize = getLegendFontSize(props.scaleFactor)

  // styled manually since this is not actually a legend
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        ml: `${boxSize}px`
      }}
    >
      <Box
        sx={{
          height: boxSize,
          width: boxSize,
          bgcolor: theme.palette.primary.main,
          borderRadius: boxSize / 2,
          mr: `${boxSize / 2}px`
        }}
      />
      <Text
        sx={{
          fontSize: fontSize,
          color: theme.palette.text.secondary
        }}
      >
        {props.title}
        {props.unit ? ` (${props.unit})` : ''}
      </Text>
    </Box>
  )
}

export default BubbleChart
