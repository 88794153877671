import { Dispatch } from 'redux'

import { generateXlsxFromData } from 'redux/api/Utilities'

import * as Types from 'redux/Types'

export function tryExportDataXlsx(
  headers: string[],
  rows: { [key: string]: string | number | null }[],
  fileName: string,
  callback?: (err?: boolean) => void
) {
  return (dispatch: Dispatch) => {
    generateXlsxFromData(headers, rows)
      .then((response) => {
        const downloadUrl = window.URL.createObjectURL(
          new Blob([response.data])
        )
        const link = document.createElement('a')

        link.href = downloadUrl
        link.setAttribute('download', `${fileName}.xlsx`)
        document.body.appendChild(link)
        link.click()
        link.remove()

        dispatch({
          type: Types.TOGGLE_SNACKBAR_OPEN,
          payload: { message: 'Export lyckades' }
        })

        if (callback) {
          callback()
        }
      })
      .catch(() => {
        dispatch({
          type: Types.TOGGLE_SNACKBAR_OPEN,
          payload: { message: 'Export misslyckades' }
        })

        if (callback) {
          callback(true)
        }
      })
  }
}
