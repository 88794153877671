import React from 'react'

import { Option } from 'components_new/molecules/SelectSearch'
import Tag from 'components_new/atoms/Tag'

import { InternalCustomer, translateLicense } from 'types/GlobalCustomer'
import Link from 'components_new/atoms/Link'

const CUSTOMER_HEADERS = [
  { label: 'Kund' },
  { label: 'Licens', align: 'center' as const },
  { label: 'Typ av kund', align: 'center' as const }
]

export const getCustomerData = (
  customers: InternalCustomer[],
  customerFilter: Option[],
  licenseFilter: Option[],
  typeFilter: Option[]
) => {
  const rows = customers
    .filter((customer) => {
      if (customerFilter.length === 0) {
        return true
      }

      return customerFilter.map((filter) => filter.id).includes(customer.id)
    })
    .filter((customer) => {
      if (licenseFilter.length === 0) {
        return true
      }

      return licenseFilter.map((filter) => filter.id).includes(customer.license)
    })
    .filter((customer) => {
      if (typeFilter.length === 0) {
        return true
      }

      return typeFilter
        .map((filter) => filter.id)
        .includes(customer.is_demo.toString())
    })
    .map((customer) => {
      return {
        label: (
          <Link
            key={customer.id}
            color="primary"
            href={'/our-customers/' + customer.id}
            underline="hover"
            sx={{ fontWeight: 500 }}
          >
            {customer.name}
          </Link>
        ),
        values: [
          {
            value: translateLicense[customer.license] ? (
              <Tag label={translateLicense[customer.license].display} />
            ) : null,
            align: 'center' as const
          },
          {
            value: <Tag label={customer.is_demo ? 'Demo' : 'Riktig'} />,
            align: 'center' as const
          }
        ]
      }
    })

  return {
    rows,
    headers: CUSTOMER_HEADERS
  }
}
