import Box from 'components_new/atoms/Box'
import React, { ReactNode } from 'react'

import type { DraggableSyntheticListeners } from '@dnd-kit/core'

import Icon from 'components_new/atoms/Icon'
import Paper from 'components_new/atoms/Paper'

interface DndListItemProps extends React.HTMLAttributes<HTMLDivElement> {
  children: ReactNode
  disabled?: boolean
  listeners?: DraggableSyntheticListeners
  sx?: object
  variant?: 'blank' | 'elevation' | 'outlined'
}

const DndListItem = React.forwardRef((props: DndListItemProps, ref: any) => {
  const { children, disabled, listeners, sx, variant, ...rest } = props

  return (
    <Paper
      component="li"
      elevation={variant === 'blank' ? 0 : undefined}
      {...rest}
      ref={ref}
      sx={{
        alignItems: 'stretch',
        bgcolor: disabled ? 'background.overlay' : undefined,
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        opacity: disabled ? 0.5 : undefined,
        pointerEvents: disabled ? 'none' : undefined,
        ...sx
      }}
      variant={variant !== 'blank' ? variant : 'elevation'}
    >
      <Box
        {...listeners}
        sx={{
          display: 'flex',
          alignItems: 'center',
          cursor: disabled ? 'not-allowed' : 'grab',
          borderRadius: 1,
          '&:hover': !disabled
            ? {
                bgcolor: 'action.hover'
              }
            : undefined
        }}
      >
        <Icon color="disabled" name="DragIndicatorOutlined" />
      </Box>
      <Box sx={{ flexGrow: 1 }}>{children}</Box>
    </Paper>
  )
})

DndListItem.displayName = 'DndListItem'
export default DndListItem
