import { Dispatch } from 'redux'
import { nanoid } from 'nanoid'

import {
  create,
  destroy,
  getOneTotal,
  getOneTotalPublic,
  getOne,
  getOnePublic,
  putImage,
  QueryParams,
  update,
  putFunnelStages,
  getOneAdditionalSegment,
  getOneAdditionalSegmentPublic,
  getOneTotalApiAccess,
  getOneAdditionalSegmentApiAccess,
  getOneApiAccess
} from 'redux/api/Widgets'
import * as Types from 'redux/Types'

import {
  WidgetType,
  WidgetPatchBody,
  WidgetPostBody,
  FunnelStagePutBody,
  WidgetData,
  GetOneWidget,
  WidgetDataLabel
} from 'types/GlobalWidget'
import { AdditionalSegment } from 'types/GlobalAdditionalSegment'
import { cloneDeep } from 'lodash'
import { lastIndexOf } from 'utils/functions'

export function tryPutFunnelStages(
  id: string,
  dashboardId: string,
  body: FunnelStagePutBody
) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: Types.PUT_WIDGET_FUNNEL_STAGES
    })
    putFunnelStages(id, body)
      .then((response) => {
        dispatch({
          type: Types.PUT_WIDGET_FUNNEL_STAGES_SUCCESS,
          payload: { data: response.data.data, widgetId: id, dashboardId }
        })

        dispatch({
          type: Types.TOGGLE_SNACKBAR_OPEN,
          payload: { message: 'Ordningen har uppdaterats' }
        })
      })
      .catch(() => {
        dispatch({
          type: Types.PUT_WIDGET_FUNNEL_STAGES_FAILED
        })

        dispatch({
          type: Types.TOGGLE_SNACKBAR_OPEN,
          payload: { message: 'Ordningen kunde inte uppdateras' }
        })
      })
  }
}

/**
 * Merge additional segments data with default get one widget data.
 * By utilizing the group of a segment we can insert the data on its
 * correct place. "group" describes the path of segments for the new segment.
 * (Hierarchical structure), index = 0 is the top segment.
 *
 * @param data - get one widget reponse data
 * @param responseAdditionals - Array of reponses from additional segments
 * @returns - additional segments merged with get one widget
 */
export function mergeAdditionalSegments(
  data: GetOneWidget,
  responseAdditionals: { data: { data: WidgetData } }[]
) {
  responseAdditionals.forEach((additional) => {
    // iterate each label to find the correct index to insert into.
    additional.data.data.labels.data.forEach((segment, labelIndex) => {
      let segmentIndex = -1
      let subIndex = -1

      if (segment.path.length === 1) {
        // Only one parent, search parent with segment
        const parent = segment.path[0].label

        segmentIndex = data.data.labels.data.findIndex(
          (topSegment) => topSegment.label === parent
        )

        subIndex = lastIndexOf<WidgetDataLabel>(
          data.data.labels.data,
          (row) => {
            if (row.path.length !== 1) {
              return false
            }

            return row.path[0].label === parent
          }
        )
      } else {
        segmentIndex = data.data.labels.data.findIndex(
          (item) =>
            // find label for direct parent
            item.label === segment.path[segment.path.length - 1].label &&
            item.path.length === segment.path.length - 1 &&
            // then match rest of parents
            segment.path
              .slice(0, segment.path.length - 1)
              .every((path, i) => path.label === item.path[i].label)
        )

        subIndex = lastIndexOf<WidgetDataLabel>(
          data.data.labels.data,
          (row) => {
            if (row.path.length !== segment.path.length) {
              return false
            }

            return row.path.every(
              (path, i) => path.label === segment.path[i].label
            )
          }
        )
      }

      const index = subIndex === -1 ? segmentIndex : subIndex

      // insert label
      data.data.labels.data.splice(index + 1, 0, segment)

      // insert data
      data.data.data.forEach((row, dataIndex) => {
        row.data.splice(
          index + 1,
          0,
          additional.data.data.data[dataIndex].data[labelIndex]
        )

        if (row.percentages) {
          // insert percentages
          row.percentages.splice(
            index + 1,
            0,
            (
              additional.data.data.data[dataIndex].percentages as (
                | number
                | null
              )[]
            )[labelIndex]
          )
        }
      })
    })
  })

  return data
}

export function tryGetOneWidget(
  id: string,
  type: WidgetType,
  showTotal: boolean,
  additionalSegments: AdditionalSegment[],
  dashboardId: string,
  queryParam: QueryParams | null
) {
  return (dispatch: Dispatch) => {
    // No need to run getOne TEXT.
    if (type === WidgetType.TEXT) {
      return
    }

    let totalPromise = null

    if (showTotal) {
      totalPromise = getOneTotal(id, queryParam)
    }

    const additionalPromise = Promise.all(
      additionalSegments.map((item) =>
        getOneAdditionalSegment(id, item.id, queryParam)
      )
    )

    const fetchId = nanoid()

    dispatch({
      type: Types.GET_ONE_WIDGET,
      payload: { id, dashboardId, fetchId }
    })

    Promise.all([getOne(id, queryParam), totalPromise, additionalPromise])
      .then(([response, responseTotal, responseAdditionals]) => {
        let data = cloneDeep(response.data.data)

        if (responseTotal) {
          data.data.totals = responseTotal.data.data
        }

        if (responseAdditionals.length > 0) {
          data = mergeAdditionalSegments(data, responseAdditionals)
        }

        dispatch({
          type: Types.GET_ONE_WIDGET_SUCCESS,
          payload: { data: data, fetchId }
        })
      })
      .catch(() => {
        dispatch({
          type: Types.GET_ONE_WIDGET_FAILED
        })
      })
  }
}

export function tryGetOnePublicWidget(
  id: string,
  type: WidgetType,
  showTotal: boolean,
  additionalSegments: AdditionalSegment[],
  dashboardId: string,
  queryParam: QueryParams | null = null,
  rawQueryParams = ''
) {
  return (dispatch: Dispatch) => {
    if (type === WidgetType.TEXT) {
      return
    }

    let totalPromise = null

    if (showTotal) {
      totalPromise = getOneTotalPublic(id, queryParam, rawQueryParams)
    }

    const additionalPromise = Promise.all(
      additionalSegments.map((item) =>
        getOneAdditionalSegmentPublic(id, item.id, queryParam, rawQueryParams)
      )
    )

    const fetchId = nanoid()

    dispatch({
      type: Types.GET_ONE_WIDGET,
      payload: { id, dashboardId, fetchId }
    })

    Promise.all([
      getOnePublic(id, queryParam, rawQueryParams),
      totalPromise,
      additionalPromise
    ])
      .then(([response, responseTotal, responseAdditionals]) => {
        let data = cloneDeep(response.data.data)

        if (responseTotal) {
          data.data.totals = responseTotal.data.data
        }

        if (responseAdditionals.length > 0) {
          data = mergeAdditionalSegments(data, responseAdditionals)
        }

        dispatch({
          type: Types.GET_ONE_WIDGET_SUCCESS,
          payload: { data: data, fetchId }
        })
      })
      .catch(() => {
        dispatch({
          type: Types.GET_ONE_WIDGET_FAILED
        })
      })
  }
}

export function tryGetOneApiAccessWidget(
  id: string,
  token: string,
  type: WidgetType,
  showTotal: boolean,
  additionalSegments: AdditionalSegment[],
  dashboardId: string,
  queryParam: QueryParams | null = null,
  rawQueryParams = ''
) {
  return (dispatch: Dispatch) => {
    if (type === WidgetType.TEXT) {
      return
    }

    let totalPromise = null

    if (showTotal) {
      totalPromise = getOneTotalApiAccess(id, token, queryParam, rawQueryParams)
    }

    const additionalPromise = Promise.all(
      additionalSegments.map((item) =>
        getOneAdditionalSegmentApiAccess(
          id,
          token,
          item.id,
          queryParam,
          rawQueryParams
        )
      )
    )

    const fetchId = nanoid()

    dispatch({
      type: Types.GET_ONE_WIDGET,
      payload: { id, dashboardId, fetchId }
    })

    Promise.all([
      getOneApiAccess(id, token, queryParam, rawQueryParams),
      totalPromise,
      additionalPromise
    ])
      .then(([response, responseTotal, responseAdditionals]) => {
        let data = cloneDeep(response.data.data)

        if (responseTotal) {
          data.data.totals = responseTotal.data.data
        }

        if (responseAdditionals.length > 0) {
          data = mergeAdditionalSegments(data, responseAdditionals)
        }

        dispatch({
          type: Types.GET_ONE_WIDGET_SUCCESS,
          payload: { data: data, fetchId }
        })
      })
      .catch(() => {
        dispatch({
          type: Types.GET_ONE_WIDGET_FAILED
        })
      })
  }
}

export function tryCreateWidget(body: WidgetPostBody) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: Types.CREATE_WIDGET
    })

    create(body)
      .then((response) => {
        dispatch({
          type: Types.CREATE_WIDGET_SUCCESS,
          payload: response.data
        })

        dispatch({
          type: Types.TOGGLE_SNACKBAR_OPEN,
          payload: { message: 'Nyckeltalet har skapats' }
        })
      })
      .catch(() => {
        dispatch({
          type: Types.CREATE_WIDGET_FAILED
        })

        dispatch({
          type: Types.TOGGLE_SNACKBAR_OPEN,
          payload: { message: 'Nyckeltalet kunde inte skapas' }
        })
      })
  }
}

export function tryUpdateWidget(
  id: string,
  body: WidgetPatchBody,
  periodFilterIsSet = false
) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: Types.UPDATE_WIDGET
    })

    const skipFetchData =
      'title' in body.data ||
      'subtitle' in body.data ||
      'embeddable' in body.data ||
      'min_value' in body.data ||
      'max_value' in body.data ||
      'show_title' in body.data ||
      'show_date' in body.data ||
      'label_chars' in body.data ||
      'invert_y_axis' in body.data ||
      'top_n_limit' in body.data ||
      ('date_filter' in body.data && periodFilterIsSet) ||
      'breakdown_by_display_type' in body.data ||
      'horizontal' in body.data ||
      'show_header' in body.data ||
      'show_vertical_dividers' in body.data ||
      'element_order' in body.data ||
      'segment_by_display_name' in body.data ||
      ('longitude' in body.data &&
        'latitude' in body.data &&
        'zoom' in body.data) ||
      'pitch' in body.data ||
      'show_subtitle' in body.data ||
      'subtitle' in body.data ||
      'key_figure_visualization_type' in body.data ||
      'pie_chart_value_visualization_type' in body.data

    update(id, body)
      .then((response) => {
        dispatch({
          type: Types.UPDATE_WIDGET_SUCCESS,
          payload: { data: response.data.data, skipFetchData }
        })

        dispatch({
          type: Types.TOGGLE_SNACKBAR_OPEN,
          payload: { message: 'Nyckeltalet har uppdaterats' }
        })
      })
      .catch(() => {
        dispatch({
          type: Types.UPDATE_WIDGET_FAILED
        })

        dispatch({
          type: Types.TOGGLE_SNACKBAR_OPEN,
          payload: { message: 'Nyckeltalet kunde inte uppdateras' }
        })
      })
  }
}

export function tryDeleteWidget(id: string, dashboardId: string) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: Types.DELETE_WIDGET
    })

    destroy(id)
      .then(() => {
        dispatch({
          type: Types.DELETE_WIDGET_SUCCESS,
          payload: { id, dashboardId }
        })

        dispatch({
          type: Types.TOGGLE_SNACKBAR_OPEN,
          payload: { message: 'Nyckeltalet har tagits bort' }
        })
      })
      .catch(() => {
        dispatch({
          type: Types.DELETE_WIDGET_FAILED
        })

        dispatch({
          type: Types.TOGGLE_SNACKBAR_OPEN,
          payload: { message: 'Nyckeltalet kunde inte tas bort' }
        })
      })
  }
}

export function tryUploadWidgetImage(id: string, image: Blob, name: string) {
  return (dispatch: Dispatch) => {
    dispatch({ type: Types.UPLOAD_WIDGET_IMAGE })

    putImage(id, image, name)
      .then((response) => {
        dispatch({
          type: Types.UPLOAD_WIDGET_IMAGE_SUCCESS,
          payload: response.data.data
        })

        dispatch({
          type: Types.TOGGLE_SNACKBAR_OPEN,
          payload: { message: 'Bilden har laddats upp.' }
        })
      })
      .catch(() => {
        dispatch({ type: Types.UPLOAD_WIDGET_IMAGE_FAILED })

        dispatch({
          type: Types.TOGGLE_SNACKBAR_OPEN,
          payload: { message: 'Bilden kunde inte laddas upp.' }
        })
      })
  }
}
