import React, { useEffect, useState } from 'react'

import { bindActionCreators, Dispatch } from 'redux'
import { connect, ConnectedProps } from 'react-redux'
import { ApplicationState } from 'redux/Stores/types'

import * as EntityActions from 'redux/actions/Entities'

import Box from 'components_new/atoms/Box'
import Button from 'components_new/atoms/Button'
import Chip from 'components_new/atoms/Chip'

import CheckboxItem from 'components_new/molecules/CheckboxItem'
import DndList from 'components_new/molecules/DndList'
import DndListItem from 'components_new/molecules/DndList/DndListItem'
import SimpleDialog from 'components_new/molecules/SimpleDialog'

import { Entity, EntityColumn } from 'types/GlobalEntities'

/*-- redux --*/
const mapStateToProps = (state: ApplicationState) => ({
  EntityStore: state.EntityStore
})

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(EntityActions, dispatch)
}

const connector = connect(mapStateToProps, mapDispatchToProps)

export type TColumn = {
  hidden: boolean
  id: string
  index: number
}

interface EditColumnsDialogProps {
  kpi: string | null // name of KPI if displayed
  columns: { [columnId: string]: TColumn }
  entityId: string
  onClose: () => void
  onSubmit: (columns: TColumn[]) => void
  open: boolean
}

type ComponentProps = ConnectedProps<typeof connector> & EditColumnsDialogProps

const EditColumnsDialog = (props: ComponentProps) => {
  const {
    columns,
    entityId,
    EntityStore,
    kpi,
    onClose,
    onSubmit,
    open,
    tryGetAllEntities
  } = props

  const [tempElements, setTempElements] = useState<EntityColumn[]>([])
  const [entity, setEntity] = useState<Entity | null>(null)

  useEffect(() => {
    if (EntityStore.fetched && entityId) {
      const currentEntity = EntityStore.data[entityId]
      const currentSelectedEntityColumns = currentEntity.columns
        .map((item) => {
          const selectedColumn = columns[item.id]

          // use default index for -1
          const selectedIndex = selectedColumn?.index ?? -1

          return {
            ...item,
            index: selectedIndex === -1 ? item.index : selectedIndex,
            hidden: selectedColumn?.hidden ?? item.hidden
          }
        })
        .sort((a, b) => a.index - b.index)

      setEntity(currentEntity)
      setTempElements(currentSelectedEntityColumns)
    }
  }, [EntityStore.fetched, entityId, open])

  const handleSubmit = () => {
    onSubmit(
      tempElements.map((item) => ({
        id: item.id,
        index: item.index,
        hidden: item.hidden
      }))
    )
    onClose()
  }

  const handleToggle = (id: string) => {
    setTempElements((prevElements) =>
      prevElements.map((column) =>
        column.id === id ? { ...column, hidden: !column.hidden } : column
      )
    )
  }

  useEffect(() => {
    if (!EntityStore.fetched && !EntityStore.fetching) {
      tryGetAllEntities()
    }
  }, [])

  return (
    <>
      <SimpleDialog
        actions={
          <>
            <Button onClick={onClose} variant="text">
              Avbryt
            </Button>
            <Button onClick={handleSubmit}>Spara</Button>
          </>
        }
        onClose={onClose}
        open={open}
        maxWidth="sm"
        title="Redigera kolumner"
      >
        <Box>
          {entity?.segment_is_hidden ? null : (
            <DndListItem disabled={true} sx={{ mb: 0.5 }} variant="outlined">
              <CheckboxItem
                checked={true}
                label={entity?.segment}
                sx={{ mr: 1 }}
              />
              <Chip label={'Identifierare'} size="small" />
            </DndListItem>
          )}
          {kpi ? (
            <DndListItem disabled={true} sx={{ mb: 0.5 }} variant="outlined">
              <CheckboxItem checked={true} label={kpi} sx={{ mr: 1 }} />
              <Chip label={'Nyckeltal'} size="small" />
            </DndListItem>
          ) : null}
          <DndList
            handleDragEnd={(newOrder) => {
              setTempElements((prevState) => {
                return newOrder.map((id, index) => {
                  const element = prevState.find(
                    (item) => item.id === id
                  ) as EntityColumn

                  return {
                    ...element,
                    index
                  }
                })
              })
            }}
            items={tempElements.map((item) => {
              return {
                id: item.id,
                content: (
                  <CheckboxItem
                    checked={!item.hidden}
                    label={item.name}
                    onChange={() => handleToggle(item.id)}
                  />
                ),
                containerSx: {
                  mb: 0.5
                }
              }
            })}
          />
        </Box>
      </SimpleDialog>
    </>
  )
}

export default connector(EditColumnsDialog)
