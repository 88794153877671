import { createAction, createReducer } from '@reduxjs/toolkit'

import * as Types from 'redux/Types'

import { Entity, PutEntityAttributeResponse } from 'types/GlobalEntities'
import { arrayToObject } from 'utils/functions'

export interface IEntityReducerType {
  data: { [entityId: string]: Entity }
  fetched: boolean
  fetching: boolean
}

// Initial state
const INITIAL_STATE: IEntityReducerType = {
  data: {},
  fetched: false,
  fetching: false
}

// Actions
const getAllAction = createAction(Types.GET_ALL_ENTITIES)
const getAllSuccessAction = createAction<{ data: Entity[] }>(
  Types.GET_ALL_ENTITIES_SUCCESS
)
const getAllFailedAction = createAction(Types.GET_ALL_ENTITIES_FAILED)
const putEntityAttributesSuccessAction = createAction<{
  data: PutEntityAttributeResponse
}>(Types.PUT_ENTITY_ATTRIBUTES_SUCCESS)
const signOutAction = createAction(Types.SIGN_OUT)

// Reducer
const accountReducer = createReducer(INITIAL_STATE, (builder) => {
  builder
    .addCase(getAllAction, (state) => {
      return {
        ...state,
        fetching: true
      }
    })
    .addCase(getAllSuccessAction, (state, action) => {
      const { payload } = action

      return {
        ...state,
        data: arrayToObject<Entity>(payload.data),
        fetched: true,
        fetching: false
      }
    })
    .addCase(getAllFailedAction, (state) => {
      return {
        ...state,
        fetching: false
      }
    })
    .addCase(putEntityAttributesSuccessAction, (state, action) => {
      const { payload } = action

      return {
        ...state,
        data: {
          ...state.data,
          [payload.data.id]: {
            ...payload.data,
            columns: payload.data.columns.map((column) => {
              const stateColumn = state.data[payload.data.id].columns.find(
                (stateColumn) => stateColumn.id === column.id
              )

              return {
                ...column,
                kpis: stateColumn ? stateColumn.kpis : []
              }
            })
          }
        }
      }
    })
    .addCase(signOutAction, () => INITIAL_STATE)
    .addDefaultCase((state) => state)
})

export default accountReducer
