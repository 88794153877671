import React, { useMemo } from 'react'

import('dayjs/locale/sv')
import dayjs from 'dayjs'

import Box from 'components_new/atoms/Box'
import Table from 'components_new/atoms/Table'
import TableCell from 'components_new/atoms/Table/TableCell'
import TableContainer from 'components_new/atoms/Table/TableContainer'
import TableHead from 'components_new/atoms/Table/TableHead'
import TableBody from 'components_new/atoms/Table/TableBody'
import TableRow from 'components_new/atoms/Table/TableRow'
import Text from 'components_new/atoms/Text'

import UserListItem from 'components_new/molecules/UserListItem'

import { toNowFrom } from 'utils/dateParser'
import {
  TDashboardActivityPeriod,
  TDashboardUserActivity,
  filterViewsByPeriod
} from './utils'
import { TDashboardGroupActivity } from 'types/GlobalDashboardGroup'

interface UsersViewProps {
  data: TDashboardGroupActivity
  period: TDashboardActivityPeriod
}

const UsersView = (props: UsersViewProps) => {
  const { data, period } = props

  const filteredData = useMemo(() => {
    const filteredViews = filterViewsByPeriod(
      data.views,
      data.created_at,
      period
    ).views

    const activityMap: {
      [userId: string]: { views: number; latestActivity: string | null }
    } = {}

    filteredViews.forEach((view) => {
      const { user_id, date } = view

      if (!activityMap[user_id]) {
        activityMap[user_id] = { views: 0, latestActivity: null }
      }

      activityMap[user_id].views += 1

      if (
        !activityMap[user_id].latestActivity ||
        dayjs(date).isAfter(activityMap[user_id].latestActivity)
      ) {
        activityMap[user_id].latestActivity = date
      }
    })

    const userActivities: TDashboardUserActivity[] = Object.keys(data.accounts)
      .map((userId) => {
        const user = data.accounts[userId]
        const activity = activityMap[userId] || {
          views: 0,
          latestActivity: null
        }

        return {
          user_id: userId,
          first_name: user.first_name,
          last_name: user.last_name,
          email: user.email,
          views: activity.views,
          latest_activity: activity.latestActivity
        }
      })
      .sort((a, b) => b.views - a.views)

    return userActivities
  }, [period])

  return (
    <Box
      sx={{
        p: 2,
        gap: 2,
        height: '100%',
        width: '100%',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        minWidth: 0,
        position: 'relative',
        bgcolor: 'background.default',
        borderRadius: 2
      }}
    >
      <TableContainer>
        <Table size="small" stickyHeader={true}>
          <TableHead>
            <TableRow>
              <TableCell>Användare</TableCell>
              <TableCell align="right">Visningar</TableCell>
              <TableCell>Senaste aktivitet</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredData.map((user) => (
              <TableRow key={user.email}>
                <TableCell>
                  <UserListItem
                    body={user.email}
                    component="div"
                    disablePadding={true}
                    size="medium"
                    sx={{ p: 0 }}
                    title={`${user.first_name} ${user.last_name}`}
                  />
                </TableCell>
                <TableCell align="right">
                  <Text fontWeight="medium" variant="body2">
                    {user.views}
                  </Text>
                </TableCell>
                <TableCell>
                  {user.latest_activity
                    ? toNowFrom(user.latest_activity)
                    : 'Ingen aktivitet'}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}

export default UsersView
