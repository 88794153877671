import('dayjs/locale/sv')
import dayjs from 'dayjs'
import { TDashboardGroupActivityView } from 'types/GlobalDashboardGroup'

export type TDashboardActivityPeriod =
  | 'THIS_WEEK'
  | 'THIS_MONTH'
  | 'THIS_YEAR'
  | 'SINCE_START'

export const filterViewsByPeriod = (
  views: TDashboardGroupActivityView[],
  created_at: string,
  period: TDashboardActivityPeriod
): {
  start_date: dayjs.Dayjs
  views: TDashboardGroupActivityView[]
} => {
  const now = dayjs()
  let startDate: dayjs.Dayjs

  switch (period) {
  case 'THIS_WEEK':
    startDate = now.startOf('week')
    break
  case 'THIS_MONTH':
    startDate = now.startOf('month')
    break
  case 'THIS_YEAR':
    startDate = now.startOf('year')
    break
  default: // 'SINCE_START'
    startDate = dayjs(created_at.split('T')[0])
    break
  }

  return {
    start_date: startDate,
    views: views.filter(
      (view) =>
        dayjs(view.date).isAfter(startDate) ||
        dayjs(view.date).isSame(startDate)
    )
  }
}

export type TDashboardUserActivity = {
  user_id: string
  first_name: string
  last_name: string
  email: string
  views: number
  latest_activity: string | null
}
