import React, { useRef, useState } from 'react'

import { useTheme } from '@mui/material/styles'

import 'chart.js/auto'
import { Chart } from 'react-chartjs-2'

import { horizontalLinePlugin } from '../utils'

import Box from 'components_new/atoms/Box'

import NoValue from '../../errors/NoValue'
import SegmentMenu from '../../SegmentMenu'

import {
  FormattedWidgetData,
  ParsedSegmentPath,
  ParsedWidgetDataLabel,
  WidgetObject,
  WidgetType
} from 'types/GlobalWidget'

import { getLines, getOptions } from './utils'
import { translateWidgetType } from 'utils/enumTranslator'

interface LineChartProps {
  formattedData: FormattedWidgetData
  scaleFactor: number
  setDetailsDialog: ((segment: ParsedSegmentPath[]) => void) | null
  setSegmentFilters: ((segment: ParsedSegmentPath[]) => void) | null
  widget: WidgetObject
}

const LineChart = (props: LineChartProps) => {
  const {
    formattedData,
    scaleFactor,
    setDetailsDialog,
    setSegmentFilters,
    widget
  } = props

  const theme = useTheme()
  const chartRef = useRef<any>(null)
  const [anchorPosition, setAnchorPosition] = useState<{
    top: number
    left: number
  } | null>(null)
  const [selectedSegment, setSelectedSegment] =
    useState<ParsedWidgetDataLabel | null>(null)

  if (formattedData.datasets.length === 0) {
    return (
      <NoValue
        iconName={translateWidgetType[WidgetType.LINE_CHART].iconName}
        scaleFactor={scaleFactor}
      />
    )
  }
  const allowOnClick = !!setSegmentFilters || !!setDetailsDialog
  const handleClick = (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    dataLabel: ParsedWidgetDataLabel
  ) => {
    event.stopPropagation()

    setAnchorPosition({ left: event.clientX, top: event.clientY })
    setSelectedSegment(dataLabel)
  }
  const handleClose = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.stopPropagation()

    setAnchorPosition(null)
    setSelectedSegment(null)
  }

  const hasBreakdown =
    formattedData.datasets.length > 1 ||
    !!widget.settings.kpi_options.find(
      (option) => !!option.breakdown_by.selected
    )

  const lines = getLines(theme, formattedData, scaleFactor)

  return (
    <>
      <Box sx={{ position: 'relative', flexGrow: 1, minHeight: 0 }}>
        <Chart
          ref={chartRef}
          type="line"
          data={lines}
          options={getOptions(
            widget,
            hasBreakdown,
            theme,
            formattedData,
            scaleFactor,
            allowOnClick
          )}
          plugins={[horizontalLinePlugin]}
          onClick={
            allowOnClick
              ? (event) => {
                  if (chartRef.current) {
                    const { top } = chartRef.current.chartArea

                    if (event.nativeEvent.offsetY <= top) {
                      // If click is above legend
                      return
                    }

                    const index = chartRef.current.scales.x.getValueForPixel(
                      event.nativeEvent.offsetX
                    )

                    const segment = lines.dataLabels[index]

                    if (segment) {
                      handleClick(event, segment)
                    }
                  }
                }
              : undefined
          }
        />
      </Box>
      {allowOnClick && selectedSegment && (
        <SegmentMenu
          anchorPosition={anchorPosition}
          setDetailsDialog={setDetailsDialog}
          setFilters={setSegmentFilters}
          onClose={handleClose}
          segments={[selectedSegment]}
          widget={widget}
        />
      )}
    </>
  )
}

export default LineChart
