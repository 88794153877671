import React, { ReactNode } from 'react'

import { useTheme } from '@mui/material'

import { IGNORE_ON_ALL_EXPORT } from 'components_new/organisms/dialogs/ExportDialog'

import Box from 'components_new/atoms/Box'
import Paper from 'components_new/atoms/Paper'
import Icon from 'components_new/atoms/Icon'
import Text from 'components_new/atoms/Text'

import { CustomColors } from 'types/GlobalCustomization'
import { WidgetType } from 'types/GlobalWidget'

interface WidgetBaseProps {
  children: ReactNode
  colors?: CustomColors | null
  editMode: boolean
  plain: boolean
  scaleFactor: number
  widgetType: WidgetType
}

const WidgetBase = (props: WidgetBaseProps) => {
  const { colors, children, editMode, plain, scaleFactor, widgetType } = props

  const theme = useTheme()

  return (
    <>
      <Box
        data-openreplay-obscured
        sx={{
          position: 'relative',
          height: '100%',
          width: '100%',
          p: 1 * scaleFactor,
          // toggle widget menu
          '& .widget-menu': {
            display: editMode ? 'block' : 'none'
          },
          '&:hover': {
            '& .widget-menu': {
              display: 'block'
            },
            '& .widget-paper': {
              outlineStyle: editMode ? 'solid' : undefined,
              outlineColor: editMode ? theme.palette.action.focus : undefined,
              outlineWidth: editMode ? '3.5px' : undefined
            }
          },
          '& .widget-resize-icon': {
            display: editMode ? 'block' : 'none'
          }
        }}
      >
        <Box
          id={IGNORE_ON_ALL_EXPORT}
          sx={{
            display: plain ? 'none' : undefined,
            position: 'absolute',
            height: `calc(100% - ${8 * scaleFactor * 2}px)`,
            width: `calc(100% - ${8 * scaleFactor * 2}px)`,
            borderRadius: 1,
            boxShadow:
              widgetType !== WidgetType.COMMENT
                ? '0px 2px 4px rgba(31, 41, 55, 0.06), 0px 4px 6px rgba(15, 23, 42, 0.12)'
                : undefined // elevation: 8
          }}
        />
        <Paper
          className="widget-paper"
          elevation={0}
          sx={{
            background:
              widgetType === WidgetType.COMMENT
                ? 'rgba(0,0,0, 0.1)'
                : colors?.widget_background_color
                  ? colors.widget_background_color
                  : undefined,
            height: '100%',
            width: '100%',
            overflow: 'hidden',
            display: 'flex',
            flexDirection: 'column',
            minWidth: 0,
            position: 'relative'
          }}
        >
          {children}
          {widgetType === WidgetType.COMMENT ? (
            <Box
              id={IGNORE_ON_ALL_EXPORT}
              sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <Text color="text.secondary" variant="caption" align="center">
                (Reserverad till externa kommentarer)
              </Text>
            </Box>
          ) : null}
        </Paper>
        <Box
          className="widget-resize-icon"
          sx={{
            display: 'none',
            position: 'absolute',
            bottom: 12,
            right: 12,
            zIndex: 2
          }}
        >
          <Icon
            name="ArrowForwardIos"
            color="disabled"
            sx={{
              zIndex: 2,
              transform: 'rotate(45deg)',
              fontSize: 24 * scaleFactor
            }}
          />
        </Box>
      </Box>
    </>
  )
}

export default WidgetBase
