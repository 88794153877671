import React, { useState, useRef, useMemo } from 'react'

import { useTheme } from '@mui/material/styles'

import 'chart.js/auto'
import { Chart } from 'react-chartjs-2'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import ChartjsPluginStacked100 from 'chartjs-plugin-stacked100'

import Box from 'components_new/atoms/Box'

import {
  boxBorderDashPlugin,
  htmlLegendPlugin,
  horizontalLinePlugin
} from '../utils'

import { getData, getOptions } from './utils'

import { translateWidgetType } from 'utils/enumTranslator'

import NoValue from '../../errors/NoValue'

import {
  FormattedWidgetData,
  ParsedSegmentPath,
  ParsedWidgetDataLabel,
  WidgetObject,
  WidgetType
} from 'types/GlobalWidget'
import SegmentMenu from '../../SegmentMenu'

interface ComboChartProps {
  customSegmentBy: string | null
  formattedData: FormattedWidgetData
  isComparativeButIllegal: boolean
  scaleFactor: number
  setCustomSegmentBy:
    | ((attributeOptionId: string, segment: ParsedSegmentPath[]) => void)
    | null
  setDetailsDialog: ((segment: ParsedSegmentPath[]) => void) | null
  setSegmentFilters: ((segment: ParsedSegmentPath[]) => void) | null
  widget: WidgetObject
}

const ComboChart = (props: ComboChartProps) => {
  const {
    customSegmentBy,
    formattedData,
    isComparativeButIllegal,
    scaleFactor,
    setCustomSegmentBy,
    setDetailsDialog,
    setSegmentFilters,
    widget
  } = props

  const chartRef = useRef<any>(null)
  const [anchorPosition, setAnchorPosition] = useState<{
    top: number
    left: number
  } | null>(null)
  const [selectedSegment, setSelectedSegment] =
    useState<ParsedWidgetDataLabel | null>(null)
  const allowOnClick = !!setSegmentFilters || !!setDetailsDialog

  const theme = useTheme()

  const hasBreakdown = !!widget.settings.kpi_options.find(
    (option) => !!option.breakdown_by.selected
  )

  const data = getData(widget, theme, hasBreakdown, formattedData, scaleFactor)

  const options = useMemo(
    () =>
      getOptions(
        widget,
        hasBreakdown,
        theme,
        allowOnClick,
        scaleFactor,
        formattedData,
        isComparativeButIllegal
      ),
    [widget, scaleFactor]
  )

  if (formattedData.datasets.length === 0) {
    return (
      <NoValue
        iconName={translateWidgetType[WidgetType.BAR_CHART].iconName}
        scaleFactor={scaleFactor}
      />
    )
  }

  const handleClick = (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    dataLabel: ParsedWidgetDataLabel
  ) => {
    event.stopPropagation()

    setAnchorPosition({ left: event.clientX, top: event.clientY })
    setSelectedSegment(dataLabel)
  }

  const handleClose = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.stopPropagation()

    setAnchorPosition(null)
    setSelectedSegment(null)
  }

  return (
    <>
      <Box sx={{ position: 'relative', flexGrow: 1, minHeight: 0 }}>
        <Chart
          ref={chartRef}
          type={'bar'}
          data={data}
          options={options}
          plugins={[
            boxBorderDashPlugin,
            htmlLegendPlugin,
            horizontalLinePlugin,
            ChartDataLabels,
            ChartjsPluginStacked100
          ]}
          onClick={
            allowOnClick
              ? (event) => {
                  if (chartRef.current) {
                    const { top } = chartRef.current.chartArea

                    // If click is below legend
                    if (event.nativeEvent.offsetY > top) {
                      const index = chartRef.current.scales.x.getValueForPixel(
                        event.nativeEvent.offsetX
                      )

                      const segment = data.dataLabels[index]

                      if (segment) {
                        handleClick(event, segment)
                      }
                    }
                  }
                }
              : undefined
          }
        />
      </Box>
      {allowOnClick && selectedSegment && (
        <SegmentMenu
          anchorPosition={anchorPosition}
          customSegmentBy={customSegmentBy}
          onClose={handleClose}
          segments={[selectedSegment]}
          setDetailsDialog={setDetailsDialog}
          setCustomSegmentBy={setCustomSegmentBy}
          setFilters={setSegmentFilters}
          widget={widget}
        />
      )}
    </>
  )
}

export default ComboChart
