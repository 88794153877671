import axios, { ResponseType } from 'axios'

import { parseAuthenticationHeader } from 'helpers/Functions'

const BASE_URL =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:3990/'
    : process.env.REACT_APP_HOMEPAL_MDM_API_PROD

const URL = `${BASE_URL}v1/utilities`

export async function generateXlsxFromData(
  headers: string[],
  rows: { [key: string]: string | number | null }[]
) {
  const options = {
    ...parseAuthenticationHeader(),
    responseType: 'blob' as ResponseType
  }

  return axios.post(
    `${URL}/generate-xlsx-from-data`,
    { data: { headers, rows } },
    options
  )
}
