import React, { useEffect, useState } from 'react'

import { AspectRatio, Dashboard } from 'redux/reducers/Dashboards'
import {
  CustomPeriodFilter,
  WidgetBody,
  WidgetPatchBody
} from 'types/GlobalWidget'
import { StatusBannerData } from 'utils/types'
import { DashboardFilter } from 'types/GlobalDashboardFilter'

import Box from 'components_new/atoms/Box'

import Banner from 'components_new/molecules/Banner'

import DashboardGrid from 'components_new/organisms/DashboardGrid'
import DashboardUserFilterBar from 'components_new/organisms/DashboardUserFilterBar'
import Widget from 'components_new/organisms/Widget'

import { formatWidgetData } from 'components_new/organisms/Dashboard/utils'

export type InnerWidgetPatchBody = WidgetPatchBody['data']

interface Props {
  dashboard: Dashboard
  dashboardFilter: DashboardFilter
  hideFooter?: boolean
  internal: boolean
  periodFilter: CustomPeriodFilter | null
  ratio?: AspectRatio
  resetAllTempStates: () => void
  setDashboardFilter: (
    filter: DashboardFilter,
    periodFilter?: CustomPeriodFilter
  ) => void
  setPeriodFilter: (periodFilter: CustomPeriodFilter | null) => void
  widgets: WidgetBody
  statusBanner: StatusBannerData[]
}

const PublicDashboardTemplate = (props: Props) => {
  const [dashboardWidgets, setWidgets] = useState([] as string[])
  const {
    dashboard,
    dashboardFilter,
    hideFooter = false,
    periodFilter,
    ratio = AspectRatio['16_9'],
    resetAllTempStates,
    setDashboardFilter,
    setPeriodFilter,
    statusBanner,
    widgets
  } = props

  useEffect(() => {
    setWidgets(Object.values(widgets).map((widget) => widget.id))
  }, [widgets])

  const layout = dashboardWidgets
    .filter((id) => widgets[id])
    .map((id) => {
      const widget = widgets[id]

      return {
        x: widget.layout.x,
        y: widget.layout.y,
        w: widget.layout.width,
        h: widget.layout.height,
        i: id
      }
    })

  return (
    <Box
      sx={{
        height: '100vh',
        width: '100vw',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        overflow: 'hidden'
      }}
    >
      {statusBanner.length > 0 &&
        statusBanner.map((banner) => (
          <Banner
            body={banner.description}
            href={banner.link}
            iconName={banner.icon}
            key={banner.id}
            linkLabel={banner.link_label}
            severity={banner.severity}
            title={banner.title}
          />
        ))}
      <DashboardUserFilterBar
        dashboardFilter={dashboardFilter}
        periodFilter={periodFilter}
        resetAllTempStates={resetAllTempStates}
        setDashboardFilter={setDashboardFilter}
        setPeriodFilter={setPeriodFilter}
      />
      <Box
        sx={{
          position: 'relative',
          flex: '1 1 auto',
          height: '100%', // For Chrome 53
          width: '100%' // For Chrome 53
        }}
      >
        <DashboardGrid
          colors={dashboard.white_label_settings}
          editable={false}
          embedded={true}
          hideFooter={hideFooter}
          gridRef={null}
          layout={layout}
          ratio={ratio}
          title={dashboard.title}
          updateLayout={() => {}}
        >
          {({ scaleFactor }) => {
            return dashboardWidgets
              .map((id) => {
                const widget = widgets[id]

                if (!widget) {
                  return null
                }

                const formattedData = formatWidgetData(widget)

                return (
                  <Box
                    onMouseOver={() => {}}
                    onMouseLeave={() => {}}
                    key={id}
                    sx={{ position: 'relative' }}
                  >
                    <Widget
                      availableSpace={false}
                      colors={dashboard.white_label_settings}
                      customer={null}
                      dashboardFilter={dashboardFilter}
                      dashboardFilterOptions={[]}
                      editMode={false}
                      embedded={true}
                      enableFullscreen={true}
                      formattedData={formattedData}
                      isAdmin={false}
                      loading={!widget.data && !widget.status.broken}
                      resetAllDashboardTempStates={() => {}}
                      scaleFactor={scaleFactor}
                      setDashboardFilter={setDashboardFilter}
                      widget={widget}
                    />
                  </Box>
                )
              })
              .filter(Boolean)
          }}
        </DashboardGrid>
      </Box>
    </Box>
  )
}

export default PublicDashboardTemplate
