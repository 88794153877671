import React, { useMemo } from 'react'

import { useParams } from 'react-router-dom'
import { connect, ConnectedProps } from 'react-redux'
import { ApplicationState } from 'redux/Stores/types'

import Box from 'components_new/atoms/Box'
import Button from 'components_new/atoms/Button'
import Icon from 'components_new/atoms/Icon'
import Text from 'components_new/atoms/Text'
import List from 'components_new/atoms/List'
import ListItem from 'components_new/atoms/List/ListItem'
import ListItemIcon from 'components_new/atoms/List/ListItemIcon'
import ListItemText from 'components_new/atoms/List/ListItemText'

interface ChecklistProps {
  data?: any
  onShowMore?: () => void
}

const Checklist = (props: ComponentProps) => {
  const {
    onShowMore,
    // redux stores:
    KPIDashboardStore
  } = props

  const params = useParams<{ id: string }>()
  const dashboard = KPIDashboardStore.data[params.id]
  const group = KPIDashboardStore.groups[dashboard?.dashboard_group_id]

  const showMore = !Boolean(onShowMore)

  const isValidTitle = (title: string) => {
    return !/test|kopia|namnlös/i.test(title)
  }

  const checklistItems = useMemo(() => {
    return [
      {
        title: 'Dashboarden har en titel',
        body: (
          <>
            Se till att din dashboard inte heter &quot;Namnlös&quot; eller
            innehåller ord så som &quot;Test&quot; och &quot;Kopia&quot;.
          </>
        ),
        value: isValidTitle(group.title)
      },
      {
        title: 'Alla flikar har titlar',
        body: (
          <>
            Se till att dina flikar inte heter &quot;Namnlös&quot; eller
            innehåller ord så som &quot;Test&quot; och &quot;Kopia&quot;.
          </>
        ),
        value: group.dashboards.every((dashboardId) =>
          isValidTitle(KPIDashboardStore.data[dashboardId].title)
        )
      },
      {
        title: 'Max 3 flikar',
        body: (
          <>
            Vi rekommenderar att undvika flikar{' '}
            <u>om målet är att öka användaraktiviteten</u>. Skapa istället
            relevanta användarfilter för att ge varje sin specifika vy. Om du
            ändå vill använda flikar, försök att hålla antalet till 2-3 stycken.
          </>
        ),
        value: group.dashboards.length <= 3
      },
      {
        title: 'Max 10 widgets',
        body: (
          <>
            Om du har många widgets har du troligen inte fokuserat på det mest
            väsentliga. Det kan då bli svårt för användaren att veta vad de ska
            fokusera på.
          </>
        ),
        value: group.dashboards.every(
          (dashboardId) =>
            Object.keys(KPIDashboardStore.data[dashboardId].widgets).length <=
            10
        )
      }
    ]
  }, [KPIDashboardStore.groups, KPIDashboardStore.data])

  return (
    <>
      <Box
        sx={{
          bgcolor: 'success.transparent',
          color: 'success.text',
          p: showMore ? 4 : 2,
          borderRadius: 2
        }}
      >
        <Text
          color="inherit"
          gutterBottom={true}
          variant={showMore ? 'h4' : 'subtitle1'}
        >
          Grundläggande checklista
        </Text>
        <Text color="inherit" variant={showMore ? 'body1' : 'body2'}>
          Ge din dashboard förutsättningar att lyckas genom att checka av de
          mest grundläggande sakerna.
        </Text>
        <List dense={true} sx={{ py: 2 }}>
          {checklistItems.map((item) => (
            <ListItem
              key={item.title}
              sx={{ opacity: item.value ? 0.5 : 1, px: 0 }}
            >
              <ListItemIcon sx={{ minWidth: showMore ? 48 : 32 }}>
                <Icon
                  color="success"
                  name={
                    item.value
                      ? 'CheckBoxOutlined'
                      : 'CheckBoxOutlineBlankOutlined'
                  }
                />
              </ListItemIcon>
              <ListItemText
                primary={item.title}
                primaryTypographyProps={{
                  color: 'success.text',
                  fontWeight: 'medium',
                  variant: 'body1'
                }}
                secondary={showMore ? item.body : undefined}
                secondaryTypographyProps={{
                  color: 'success.text',
                  variant: 'body1'
                }}
              />
            </ListItem>
          ))}
        </List>
        {onShowMore ? (
          <Button color="success" onClick={onShowMore} variant="text">
            Visa mer
          </Button>
        ) : null}
      </Box>
    </>
  )
}

/*-- redux --*/
const mapStateToProps = (state: ApplicationState) => ({
  AuthStore: state.AuthStore,
  KPIDashboardStore: state.KPIDashboardStore,
  KPITemplateStore: state.KPITemplateStore
})

const connector = connect(mapStateToProps)

type ComponentProps = ConnectedProps<typeof connector> & ChecklistProps

export default connector(Checklist)
