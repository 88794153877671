/* Fake feature for sales and review */

import React, { useEffect, useState } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { nanoid } from 'nanoid'

import { ThemeProvider } from '@mui/material'
import { getTheme } from 'themes'

import Button from 'components_new/atoms/Button'
import Icon from 'components_new/atoms/Icon'
import IconButton from 'components_new/atoms/IconButton'

import SidebarDialog from 'components_new/molecules/SidebarDialog'
import SimpleDialog from 'components_new/molecules/SimpleDialog'

import WidgetNotifcationContent from './WidgetNotifcationContent'
import WidgetNotificationTabTitle from './WidgetNotificationTabTitle'

import * as WidgetNotificationActions from 'redux/actions/WidgetNotifications'

import { ApplicationState } from 'redux/Stores/types'
import { WidgetObject, WidgetType } from 'types/GlobalWidget'
import {
  ListenerSettings,
  PeriodicSettings,
  WidgetNotification,
  WidgetNotificationType
} from 'types/GlobalWidgetNotification'

import { Tab } from './utils'

interface ComponentOwnProps {
  buttonSize: number
  embedded: boolean
  iconSize: number
  loading: boolean
  widget: WidgetObject
}

const NotificationSettings = (props: ComponentProps) => {
  const [open, setOpen] = useState(false)

  const [tab, setTab] = useState<Tab>(Tab.DEFAULT)
  const [notificationToDelete, setNotificationToDelete] =
    useState<WidgetNotification | null>(null)
  const [editSubscribers, setEditSubscribers] = useState<string | null>(null)

  // Notifications for the widget.
  const widgetNotifications = props.WidgetNotifcationStore?.[
    props.widget.id
  ] || { fetched: false, notifications: {} }

  const notificationToEditSubscribers =
    widgetNotifications.notifications[editSubscribers || '']

  // Fetch notifications on open if not fetched for the widget.
  useEffect(() => {
    if (open && !widgetNotifications.fetched) {
      props.tryGetWidgetNotifications(props.widget.id)
    }

    if (!open) {
      setTab(Tab.DEFAULT)
      setEditSubscribers(null)
    }
  }, [open])

  function handleOpen() {
    setOpen(true)
  }

  function handleClose() {
    setOpen(false)
  }

  if (
    props.embedded ||
    props.loading ||
    props.widget.settings.type.selected === WidgetType.TEXT ||
    props.widget.settings.type.selected === WidgetType.COMMENT ||
    props.widget.settings.type.selected === WidgetType.IMAGE ||
    !props.AuthStore.user?.is_homepal_user
  ) {
    return null
  }

  return (
    <>
      <IconButton
        color="disabled"
        onClick={handleOpen}
        onMouseDown={(event) => event.stopPropagation()}
        size="small"
        sx={{
          width: props.buttonSize,
          height: props.buttonSize
        }}
      >
        <Icon
          fontSize="small"
          name="NotificationsActiveOutlined"
          sx={{ fontSize: props.iconSize }}
        />
      </IconButton>
      <ThemeProvider theme={getTheme('light')}>
        <SidebarDialog
          onClose={handleClose}
          open={open}
          PaperProps={{
            sx: {
              width: '100%',
              maxWidth:
                tab === Tab.DEFAULT && !Boolean(editSubscribers)
                  ? 'md'
                  : '512px!important',
              transition: 'max-width 0.5s ease'
            }
          }}
          title={
            <WidgetNotificationTabTitle
              setTab={setTab}
              tab={tab}
              editSubscribers={notificationToEditSubscribers}
              closeEditSubscribers={() => setEditSubscribers(null)}
            />
          }
        >
          <WidgetNotifcationContent
            accounts={props.AccountStore.data}
            addSubscriber={(userId: string) => {
              if (notificationToEditSubscribers) {
                props.tryUpdateWidgetNotifications(
                  notificationToEditSubscribers.id,
                  props.widget.id,
                  {
                    ...notificationToEditSubscribers,
                    subscribers: [
                      ...notificationToEditSubscribers.subscribers,
                      userId
                    ]
                  }
                )
              }
            }}
            removeSubscriber={(userId: string) => {
              if (editSubscribers) {
                props.tryUpdateWidgetNotifications(
                  notificationToEditSubscribers.id,
                  props.widget.id,
                  {
                    ...notificationToEditSubscribers,
                    subscribers:
                      notificationToEditSubscribers.subscribers.filter(
                        (sub) => sub !== userId
                      )
                  }
                )
              }
            }}
            editSubscribers={notificationToEditSubscribers}
            kpiOptions={props.widget.settings.kpi_options}
            onCreate={(
              type: WidgetNotificationType,
              periodSettings?: PeriodicSettings,
              listenerSettings?: ListenerSettings
            ) => {
              props.tryCreateWidgetNotification(props.widget.id, {
                id: nanoid(),
                dashboard_id: props.widget.dashboard_id,
                widget_id: props.widget.id,
                type,
                periodic_settings: periodSettings,
                listener_settings: listenerSettings,
                subscribers: []
              })

              setTab(Tab.DEFAULT)
            }}
            onEditSubscribers={setEditSubscribers}
            onDelete={setNotificationToDelete}
            tab={tab}
            widgetNotifications={widgetNotifications}
          />
        </SidebarDialog>

        {/* Dialogs */}
        <SimpleDialog
          title={'Radera notifikation?'}
          open={Boolean(notificationToDelete)}
          onClose={() => setNotificationToDelete(null)}
          actions={
            <>
              <Button
                variant="text"
                onClick={() => setNotificationToDelete(null)}
              >
                Stäng
              </Button>
              <Button
                color="error"
                onClick={() => {
                  props.tryDeleteWidgetNotifications(
                    notificationToDelete?.id as string,
                    props.widget.id
                  )

                  setNotificationToDelete(null)
                }}
                variant="contained"
              >
                Radera
              </Button>
            </>
          }
          contentText={
            <>
              Notifikationen kommer att raderas permanent.{' '}
              <b>
                Användare som prenumererar på notifikationen kommer inte längre
                att bli notifierade.
              </b>{' '}
              Är du säker?
            </>
          }
        />
      </ThemeProvider>
    </>
  )
}

/*-- redux --*/
const mapStateToProps = (state: ApplicationState) => ({
  AccountStore: state.AccountStore,
  AuthStore: state.AuthStore,
  WidgetNotifcationStore: state.WidgetNotifcationStore
})

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      ...WidgetNotificationActions
    },
    dispatch
  )
}

const connector = connect(mapStateToProps, mapDispatchToProps)

export type ComponentProps = ConnectedProps<typeof connector> &
  ComponentOwnProps

export default connector(NotificationSettings)
