import React, { useState } from 'react'

import { getTheme } from 'themes'
import { ThemeProvider } from '@mui/material'

import Alert from 'components_new/atoms/Alert'
import Box from 'components_new/atoms/Box'
import Button from 'components_new/atoms/Button'
import Text from 'components_new/atoms/Text'
import TextField from 'components_new/atoms/TextField'
import Paper from 'components_new/atoms/Paper'

import { validateEmail } from '../utils'
import Icon from 'components_new/atoms/Icon'

interface SignInWithMicrosoftProps {
  errors: { message: string }[]
  handleLogin: (email: string, password: string) => void
  loading: boolean
  onSignInWithMicrosoft: (email: string) => void
  onSignInWithHomepal: () => void
  setErrors: (errors: { message: string }[]) => void
}

const SignInWithMicrosoft = (props: SignInWithMicrosoftProps) => {
  const [email, setEmail] = useState('')
  const [errorInEmail, setErrorInEmail] = useState(false)

  return (
    <ThemeProvider theme={getTheme('dark')}>
      <Box sx={{ maxWidth: 600, width: '100%', pl: 3, pr: 3 }}>
        <Box>
          <Text variant="h3" gutterBottom>
            Logga in med Mircosoft
          </Text>
          <Text sx={{ mb: 4 }}>
            Fyll i din emailadress nedan för att logga in med Microsoft. Tänk på
            att detta endast fungerar om din organisation har aktiverat
            möjligheten att logga in med Microsoft.
          </Text>
        </Box>

        <Paper sx={{ width: '100%', px: 2, py: 4, mb: 4 }}>
          {props.errors.length > 0 ? (
            <Alert severity={'error'} variant={'filled'}>
              {props.errors[0].message}
            </Alert>
          ) : null}
          <TextField
            error={errorInEmail}
            fullWidth
            helperText={
              errorInEmail ? 'Fyll i en giltig emailadress' : undefined
            }
            label={'Email'}
            onChange={(event) => {
              props.setErrors([])

              setErrorInEmail(false)

              setEmail(event.target.value)
            }}
            value={email}
            sx={{ mb: 4 }}
          />
          <Button
            color="success"
            loading={props.loading}
            loadingTitle="Kontrollerar dina uppgifter"
            startIcon={<Icon name="Microsoft" />}
            onClick={() => {
              const validEmail = validateEmail(email)

              if (validEmail) {
                props.onSignInWithMicrosoft(email)
              } else {
                setErrorInEmail(true)
              }
            }}
            fullWidth
          >
            Logga in med Microsoft
          </Button>
        </Paper>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start'
          }}
        >
          <Button
            color="primary"
            onClick={() => {
              props.setErrors([])

              props.onSignInWithHomepal()
            }}
            variant="text"
          >
            Logga in med Homepal-konto
          </Button>
          <Button
            color="info"
            onClick={() => {
              props.setErrors([])

              props.onSignInWithHomepal()
            }}
            variant="text"
          ></Button>
        </Box>
      </Box>
    </ThemeProvider>
  )
}

SignInWithMicrosoft.displayName = 'SignInWithMicrosoft'

export default SignInWithMicrosoft
