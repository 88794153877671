import React, { useEffect, useState } from 'react'

import Box from 'components_new/atoms/Box'
import InputBase from 'components_new/atoms/InputBase'
import { getScrollbarSx } from 'themes'
import { useTheme } from '@mui/material'

interface EditableTextProps {
  allowTextOverflow?: boolean
  disabled?: boolean
  onSubmit: (value: string) => void
  scaleFactor: number
  value: any
  variant: 'subtitle' | 'title'
}

const EditableText = (props: EditableTextProps) => {
  const { allowTextOverflow, disabled, onSubmit, scaleFactor, value, variant } =
    props

  const [editValue, setEditValue] = useState<string>('')
  const [isActive, setIsActive] = useState<boolean>(false)

  const handleSubmit = () => {
    if (editValue !== value) {
      onSubmit(editValue)
    }
    setIsActive(false)
  }

  useEffect(() => {
    setEditValue(value || 'Namnlös')
  }, [value])

  const color = variant === 'title' ? 'text.primary' : 'text.secondary'
  const fontSize = variant === 'title' ? 20 : 16
  const fontWeight = variant === 'title' ? 'bold' : 'medium'

  const theme = useTheme()

  return (
    <Box
      onClick={() => {
        if (!isActive && !disabled) {
          setIsActive(true)
        }
      }}
    >
      <InputBase
        fullWidth={true}
        readOnly={!isActive || disabled}
        maxRows={allowTextOverflow ? undefined : 2}
        multiline={true}
        onBlur={() => {
          handleSubmit()
        }}
        onChange={(event) => {
          setEditValue(event.target.value.replace('\n', ''))
        }}
        onKeyUp={(event) => {
          if (event.key === 'Enter') {
            handleSubmit()
          }
        }}
        onMouseDown={(event) => {
          event.stopPropagation()
        }}
        sx={{
          color: color,
          fontWeight: fontWeight,
          fontSize: fontSize * scaleFactor,
          lineHeight: 1.1,
          bgcolor: isActive ? 'action.focus' : undefined,
          p: 0,
          m: 0,
          '& .MuiInputBase-input': {
            p: 0,
            m: 0,
            cursor: disabled ? 'default' : undefined,
            ...getScrollbarSx(theme, scaleFactor),
            // overflow
            ...(!isActive && {
              overflow: 'hidden',
              display: '-webkit-box',
              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: '2',
              whiteSpace: 'pre-wrap'
            })
          }
        }}
        value={editValue}
      />
    </Box>
  )
}

export default EditableText
