import React, { useEffect, useState, useMemo } from 'react'

import { cloneDeep } from 'lodash'

import { usePrevious } from 'helpers/Functions'

import Node from 'components/common/Node'
import Mentions from 'components/common/Mentions'
import Input from 'components/common/Input'

import { MATH_OPERATIONS } from 'helpers/Constants'

import Styles from './styles.module.css'
import { NodeTooltipText, parseAttributeOptions } from './conf'

const CalculateNode = ({ id, selected, data, setData }) => {
  const [mounted, setMounted] = useState(false)
  const leftNodeId = useMemo(() => data?.leftNode, [data?.globalNodeData])
  const previousLeftNodeId = usePrevious(leftNodeId)
  const leftNode = useMemo(
    () => data?.globalNodeData?.[data.leftNode],
    [data?.globalNodeData]
  )

  const leftAttributes = useMemo(() => {
    return leftNode?.data?.outputAttributes || []
  }, [leftNode])

  const newAttributeIndex = useMemo(() => {
    return data.outputAttributes.findIndex(
      (attr) => attr.shortId === data.newAttribute
    )
  }, [data.outputAttributes])

  const newAttribute =
    newAttributeIndex !== -1 ? data.outputAttributes[newAttributeIndex] : null

  const options = parseAttributeOptions(
    data.outputAttributes,
    data.nodeNumberMapper,
    ['BIGINT', 'DOUBLE']
  )
    .filter((opt) => opt.value !== newAttribute?.shortId)
    .map((opt) => ({
      label: opt.searchLabel,
      value: opt.label
    }))

  useEffect(() => {
    if (mounted && previousLeftNodeId !== leftNodeId) {
      const timer = setTimeout(() => {
        setData(id, {
          outputAttributes: [
            ...leftAttributes.map((attr) => ({
              ...attr,
              leftAttribute: null,
              rightAttribute: null
            })),
            newAttribute
          ]
        })
      }, 500)

      return () => clearTimeout(timer)
    } else {
      setMounted(true)
    }
  }, [leftNodeId])

  return (
    <Node
      icon={'PercentOutlined'}
      title={'Calculate'}
      selected={selected}
      nodeNumber={data.nodeNumberMapper[id]}
    >
      <div
        className={`${Styles['node-padding']} ${Styles['calculate-node-container']}`}
      >
        <Input
          className={'nodrag'}
          size={'small'}
          value={newAttribute ? newAttribute.name : null}
          placeholder={'Kolumnnamn'}
          onChange={(ev) => {
            const tmpOutput = cloneDeep(data.outputAttributes)

            if (newAttributeIndex !== -1) {
              tmpOutput[newAttributeIndex].name = ev.target.value
              tmpOutput[newAttributeIndex].realName = ev.target.value

              setData(id, { outputAttributes: tmpOutput })
            }
          }}
        />
        <div>
          <small className="secondary tiny">Uttryck</small>
          <Mentions
            className={'nodrag'}
            autoSize
            value={data.value}
            options={options}
            onChange={(value) => setData(id, { value })}
          />

          <NodeTooltipText
            tooltipText={'Hur ser ett giltigt uttryck ut'}
            description={`Ett giltigt uttryck får endast innehålla någon av följande matematiska tecken: ${MATH_OPERATIONS.join(
              ', '
            )} och uttrycket får inte innehålla några mellanrum mellan operanderna.`}
          />
        </div>
      </div>
    </Node>
  )
}

export default CalculateNode
