import React from 'react'

import Box from 'components_new/atoms/Box'
import Tag from 'components_new/atoms/Tag'
import Text from 'components_new/atoms/Text'
import Tooltip from 'components_new/atoms/Tooltip'

interface DragItem {
  index: number
  sx?: object
  title: string
}

const DragItem = (props: DragItem) => {
  const { title } = props

  return (
    <Box
      sx={{
        px: 1,
        display: 'flex',
        alignItems: 'center',
        gap: 1
      }}
    >
      <Text
        sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
        variant="subtitle2"
      >
        {title}
      </Text>
      <Tooltip placement={'top'} title={'Kontonivå'}>
        <Box>
          <Tag startIconName={'SellOutlined'} color="primary" />
        </Box>
      </Tooltip>
    </Box>
  )
}

export default DragItem
