import React, { ReactNode, useEffect, useState } from 'react'

import { ThemeProvider } from '@mui/material'
import { getTheme } from 'themes'

import Box from 'components_new/atoms/Box'
import Button from 'components_new/atoms/Button'
import Drawer from 'components_new/atoms/Drawer'
import Text from 'components_new/atoms/Text'
import Tooltip from 'components_new/atoms/Tooltip'

import { SETTINGS_DRAWER_SX } from '../SettingsDrawer'

interface SettingsPopupProps {
  cancelLabel?: string
  children: ReactNode
  disabled?: boolean
  disabledTitle?: ReactNode
  onCancel: () => void
  onClose: () => void
  onSubmit?: () => void
  open: boolean
  submitLabel?: string
  title: string
}

const SettingsPopup = (props: SettingsPopupProps) => {
  const {
    cancelLabel,
    children,
    disabled,
    disabledTitle,
    onCancel,
    onClose,
    onSubmit,
    open,
    submitLabel,
    title
  } = props

  const theme = getTheme('light')

  const PADDING = 4

  const [backdrop, setBackdrop] = useState<boolean>(false)
  const [background, setBackground] = useState<boolean>(false)

  useEffect(() => {
    if (open === false) {
      setBackdrop(false)
      setBackground(false)

      return () => {}
    } else {
      const backdropTimer = setTimeout(() => {
        setBackdrop(true)
      }, 200)

      const backgroundTimer = setTimeout(() => {
        setBackground(true)
      }, 250)

      return () => {
        clearTimeout(backdropTimer)
        clearTimeout(backgroundTimer)
      }
    }
  }, [open])

  return (
    <ThemeProvider theme={theme}>
      <Drawer
        anchor="bottom"
        elevation={0}
        open={open}
        onClose={onClose}
        onMouseDown={(event) => event.stopPropagation()}
        PaperProps={{
          sx: {
            top: 0,
            left: 'auto',
            right: 0,
            maxWidth: SETTINGS_DRAWER_SX.maxWidth,
            minWidth: SETTINGS_DRAWER_SX.minWidth,
            width: SETTINGS_DRAWER_SX.width,
            bgcolor: 'none'
          }
        }}
        sx={{
          zIndex: SETTINGS_DRAWER_SX.zIndex, // for fullSize
          '.MuiModal-backdrop': {
            background: 'none'
          }
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            width: '100%',
            height: PADDING * 4,
            left: 0,
            top: 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'flex-end',
            zIndex: 0
          }}
        >
          <Box
            sx={{
              width: background ? 'calc(100% - 48px)' : '100%',
              height: background ? '80%' : '100%',
              bgcolor: 'background.paper',
              opacity: background ? 0.5 : 0,
              transition: 'all 0.1s ease-in-out',
              borderTopLeftRadius: 12,
              borderTopRightRadius: 12
            }}
          />
        </Box>

        <Box
          sx={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            left: 0,
            top: 0,
            right: 0,
            bottom: 0,
            bgcolor: 'background.backdrop',
            opacity: backdrop ? 1 : 0,
            transition: 'all 0.2s ease-in-out',
            zIndex: -1
          }}
        />
        <Box
          sx={{
            top: PADDING * 4,
            left: 0,
            width: '100%',
            minHeight: `calc(100vh - ${PADDING * 4}px)`,
            overflowY: 'auto',
            position: 'relative',
            bgcolor:
              theme.palette.mode === 'dark' ? 'background.default' : 'grey.100',
            borderTopLeftRadius: 12,
            borderTopRightRadius: 12,
            transition: 'all 0.2s ease-in-out'
          }}
        >
          <Box
            sx={{
              position: 'sticky',
              top: 0,
              display: 'flex',
              gap: 0.5,
              justifyContent: 'center',
              bgcolor: 'background.paper',
              p: 1,
              zIndex: 100,
              borderBottom: '1px solid',
              borderColor: 'divider',
              minHeight: 45
            }}
          >
            <Box sx={{ flex: '1 0 auto' }}>
              <Button
                color="primary"
                onClick={onCancel}
                size="small"
                sx={{
                  position: 'absolute',
                  left: '12px'
                }}
                variant="text"
              >
                {cancelLabel ?? 'Avbryt'}
              </Button>
            </Box>
            <Box sx={{ width: 'calc(100% - 160px)', minWidth: 0 }}>
              <Text align="center" noWrap={true} variant="subtitle1">
                {title}
              </Text>
            </Box>
            <Tooltip
              placement="left"
              title={disabled && disabledTitle ? disabledTitle : ''}
            >
              <Box
                sx={{
                  position: 'relative',
                  flex: '1 0 auto',
                  ...(disabled ? { cursor: 'not-allowed' } : undefined)
                }}
              >
                {onSubmit ? (
                  <Button
                    color="brand"
                    disabled={disabled}
                    onClick={disabled ? undefined : onSubmit}
                    size="small"
                    sx={{
                      position: 'absolute',
                      right: '12px'
                    }}
                    variant="text"
                  >
                    {submitLabel ?? 'Lägg till'}
                  </Button>
                ) : null}
              </Box>
            </Tooltip>
          </Box>
          <Box sx={{ p: 1 }}>{children}</Box>
        </Box>
      </Drawer>
    </ThemeProvider>
  )
}

export default SettingsPopup
