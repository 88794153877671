import React from 'react'

import Box from 'components_new/atoms/Box'
import TableCell from 'components_new/atoms/Table/TableCell'
import Tag from 'components_new/atoms/Tag'
import Text from 'components_new/atoms/Text'

import TrendTag from 'components_new/molecules/TrendTag'

import {
  // ellipseLabel,
  getCellAlignment,
  getCellScaleSx
} from '../utils'
import { DatasetType, StatusData } from 'types/GlobalWidget'

interface BodyCellProps {
  color?: string
  divider: boolean
  increaseIsPositive?: boolean
  isComparativeButIllegal?: boolean
  label: string | number | null | string[] | number[]
  // numberOfCharacters?: number
  percentageDiff?: number | null
  scaleFactor: number
  showDiff?: boolean
  status: StatusData
  type: DatasetType
  variant?: 'body' | 'footer' | 'head'
}

const BodyCell = (props: BodyCellProps) => {
  const {
    color,
    divider,
    increaseIsPositive,
    isComparativeButIllegal,
    label,
    // numberOfCharacters,
    percentageDiff,
    scaleFactor,
    showDiff,
    status,
    type,
    variant
  } = props

  return (
    <TableCell
      align={getCellAlignment(type)}
      sx={{
        borderRight: divider ? '1px solid' : undefined,
        '&:last-child': { borderRight: 0 },
        borderRightColor: 'divider',
        whiteSpace: 'nowrap',
        width: '1%', // For auto sized, fit content cells. (Hacker).
        ...getCellScaleSx(scaleFactor)
      }}
      variant={variant}
    >
      <TableCellBody
        color={color}
        increaseIsPositive={increaseIsPositive}
        isComparativeButIllegal={Boolean(isComparativeButIllegal)}
        label={label}
        percentageDiff={percentageDiff}
        showDiff={Boolean(showDiff)}
        status={status}
        type={type}
      />
    </TableCell>
  )
}

interface TableCellBodyProps {
  color?: string
  increaseIsPositive?: boolean
  isComparativeButIllegal: boolean
  label: string | number | string[] | number[] | null
  percentageDiff?: number | null
  showDiff: boolean
  status: StatusData
  type: DatasetType
}

const TableCellBody = (props: TableCellBodyProps) => {
  const {
    color,
    increaseIsPositive,
    isComparativeButIllegal,
    label,
    percentageDiff,
    showDiff,
    status,
    type
  } = props

  const showTrendTag =
    !!showDiff &&
    percentageDiff != null &&
    increaseIsPositive !== undefined &&
    !isComparativeButIllegal

  if (type === DatasetType.ENUM) {
    return (
      // Use Chip in next release: <Chip label={label} size="small" />
      <Box sx={{ display: 'flex', gridGap: 4, justifyContent: 'center' }}>
        {(label as string[]).map((item, i) => (
          <Tag key={i} label={item} color={status ? status[i] : undefined} />
        ))}
      </Box>
    )
  }

  if (
    type === DatasetType.STRING ||
    type === DatasetType.DATE ||
    type === DatasetType.NUMBER_TAG
  ) {
    const getTextColor = (i: number) =>
      color || (status ? `${status[i]}.text` : undefined)

    return (
      <Box
        sx={{
          display: 'flex',
          gridGap: 4,
          justifyContent:
            type === DatasetType.NUMBER_TAG || type === DatasetType.DATE
              ? 'flex-end'
              : undefined
        }}
      >
        {(label as string[]).map((item, index, labels) => (
          <Text
            component="span"
            fontSize="inherit"
            key={index}
            variant="body2"
            sx={{ color: getTextColor(index) }}
          >
            {/* {ellipseLabel(label, numberOfCharacters)} */}
            {item}
            {index < labels.length - 1 ? ', ' : ''}
          </Text>
        ))}
      </Box>
    )
  }

  return (
    <>
      <Text component="span" fontSize="inherit" variant="body2" sx={{ color }}>
        {/* {ellipseLabel(label, numberOfCharacters)} */}
        {label}
      </Text>
      {showTrendTag ? (
        <TrendTag
          increaseIsPositive={increaseIsPositive}
          sx={{ ml: 1 }}
          value={percentageDiff}
        />
      ) : null}
    </>
  )
}

export default BodyCell
