import React, { ChangeEvent, useState } from 'react'

import Alert from 'components_new/atoms/Alert'
import Box from 'components_new/atoms/Box'
import Button from 'components_new/atoms/Button'
import Link from 'components_new/atoms/Link'
import Text from 'components_new/atoms/Text'
import TextField from 'components_new/atoms/TextField'

import AuthHero from '../AuthHero'
import ShemEmailHint from './ShemEmailHint'

import { handleEnter, validateEmail, validateRequired } from '../utils'
import Divider from 'components_new/atoms/Divider'
import Icon from 'components_new/atoms/Icon'

interface SignInProps {
  errors: { message: string }[]
  loading: boolean
  onForgetPassword: () => void
  onSignIn: (email: string, password: string) => void
  onSignInWithMicrosoft: () => void
  setErrors: (errors: { message: string }[]) => void
}

const SignIn = (props: SignInProps) => {
  const [email, setEmail] = useState('')
  const [errorInEmail, setErrorInEmail] = useState(false)
  const [password, setPassword] = useState('')
  const [errorInPassword, setErrorInPassword] = useState(false)

  const handleChangeEmail = (e: ChangeEvent<HTMLInputElement>) => {
    props.setErrors([])
    setErrorInEmail(false)
    setEmail(e.target.value)
  }

  const handleChangePassword = (e: ChangeEvent<HTMLInputElement>) => {
    props.setErrors([])
    setErrorInPassword(false)
    setPassword(e.target.value)
  }

  const handleSignIn = () => {
    const validEmail = validateEmail(email)
    const validPassword = validateRequired(password)

    if (validEmail && validPassword) {
      props.onSignIn(email, password)
    } else {
      if (!validEmail) {
        setErrorInEmail(true)
      }

      if (!validPassword) {
        setErrorInPassword(true)
      }
    }
  }

  return (
    <>
      <AuthHero
        title="Välkommen tillbaka!"
        subtitle="Ange din email och ditt lösenord för att logga in."
      />
      <Box sx={{ mb: 4, maxWidth: 400 }}>
        <Box sx={{ mb: 2 }}>
          <TextField
            error={errorInEmail}
            fullWidth
            helperText={errorInEmail ? 'Ange en giltig email' : undefined}
            label={'Email'}
            onChange={handleChangeEmail}
            onKeyDown={(e) => handleEnter(e, handleSignIn)}
            value={email}
          />
          {/*-- Custom hint for Stockholmshem --*/}
          <ShemEmailHint
            open={
              props.errors.length > 0 && email.includes('@stockholmshem.se')
            }
          />
          <TextField
            error={errorInPassword}
            fullWidth
            helperText={errorInPassword ? 'Ange ditt lösenord' : undefined}
            label={'Lösenord'}
            onChange={handleChangePassword}
            onKeyDown={(e) => handleEnter(e, handleSignIn)}
            value={password}
            type={'password'}
            sx={{ mt: 2, mb: 0.5 }}
          />
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Link
              onClick={() => {
                props.setErrors([])

                props.onForgetPassword()
              }}
            >
              Glömt ditt lösenord?
            </Link>
          </Box>
        </Box>
        <Button
          color="brand"
          fullWidth={true}
          loading={props.loading}
          loadingTitle="Loggar in"
          onClick={handleSignIn}
          size="large"
        >
          Logga in
        </Button>
      </Box>
      {props.errors.length > 0 && !email.includes('@stockholmshem.se') ? (
        <Alert severity={'error'} variant={'outlined'} sx={{ mb: 4 }}>
          {props.errors[0].message}
        </Alert>
      ) : null}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center'
        }}
      >
        <Text align="center" color="text.secondary" variant="body2">
          Har du inget konto?{' '}
          <Link href={'mailto:hej@homepal.se'}>Kontakta oss</Link>
        </Text>
        <Divider sx={{ my: 4 }} />
        <Button
          color="primary"
          onClick={() => {
            props.setErrors([])

            props.onSignInWithMicrosoft()
          }}
          startIcon={<Icon name="Microsoft" />}
          variant="text"
        >
          Logga in med Microsoft
        </Button>
      </Box>
    </>
  )
}

SignIn.displayName = 'SignIn'

export default SignIn
