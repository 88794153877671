import React, { ChangeEvent, useState } from 'react'

import Alert from 'components_new/atoms/Alert'
import Box from 'components_new/atoms/Box'
import Button from 'components_new/atoms/Button'
import Collapse from 'components_new/atoms/Collapse'
import List from 'components_new/atoms/List'
import ListItem from 'components_new/atoms/List/ListItem'
import ListItemText from 'components_new/atoms/List/ListItemText'
import ListItemIcon from 'components_new/atoms/List/ListItemIcon'
import Icon from 'components_new/atoms/Icon'
import TextField from 'components_new/atoms/TextField'

import AuthHero from '../AuthHero'

import {
  getValidateItems,
  handleEnter,
  validateNewPasswordCapsCharacter,
  validateNewPasswordLength,
  validateNewPasswordNumber,
  validateNewPasswordSmallCharacter,
  validateRequired
} from '../utils'

interface ActivateAccountProps {
  dashboardName?: string
  email: string
  errors: { message: string }[]
  invitedBy?: string
  loading: boolean
  onActivateAccount: (
    password: string,
    firstName?: string,
    lastName?: string
  ) => void
  setErrors: (errors: { message: string }[]) => void
}

const ActivateAccount = (props: ActivateAccountProps) => {
  const [newPassword, setNewPassword] = useState('')
  const [repeatNewPassword, setRepeatNewPassword] = useState('')
  const [errorRepeatPassword, setErrorRepeatPassword] = useState(false)
  const [firstName, setFirstName] = useState('')
  const [errorFirstName, setErrorFirstName] = useState(false)
  const [lastName, setLastName] = useState('')
  const [errorLastName, setErrorLastName] = useState(false)

  // If these query parameters are set, we require that the user
  // adds first and last name on creating account.
  const namesRequired = !!props.invitedBy && !!props.dashboardName

  const validLength = validateNewPasswordLength(newPassword)
  const validSmallChar = validateNewPasswordSmallCharacter(newPassword)
  const validCapsChar = validateNewPasswordCapsCharacter(newPassword)
  const validNumber = validateNewPasswordNumber(newPassword)
  const validPassword =
    validLength && validSmallChar && validCapsChar && validNumber

  const handleChangeNewPassword = (e: ChangeEvent<HTMLInputElement>) => {
    props.setErrors([])
    setErrorRepeatPassword(false)
    setNewPassword(e.target.value)
  }

  const handleChangeRepeatNewPassword = (e: ChangeEvent<HTMLInputElement>) => {
    props.setErrors([])
    setErrorRepeatPassword(false)
    setRepeatNewPassword(e.target.value)
  }

  const handleActivateAccount = () => {
    const validFirstName = validateRequired(firstName, namesRequired)
    const validLastName = validateRequired(lastName, namesRequired)
    const passwordMatch = newPassword === repeatNewPassword

    if (passwordMatch && validFirstName && validLastName) {
      props.onActivateAccount(
        newPassword,
        namesRequired ? firstName : undefined,
        namesRequired ? lastName : undefined
      )
    } else {
      if (!passwordMatch) {
        setErrorRepeatPassword(true)
      }

      if (!validFirstName) {
        setErrorFirstName(true)
      }

      if (!validLastName) {
        setErrorLastName(true)
      }
    }
  }

  return (
    <>
      <AuthHero
        title="Aktivera ditt konto"
        subtitle="Välj ett lösenord för att komma igång!"
      />
      <Box sx={{ mb: 4 }}>
        <TextField
          fullWidth
          label={'Email'}
          disabled
          value={props.email}
          sx={{ mb: 2 }}
        />
        <Collapse in={namesRequired}>
          <TextField
            error={errorFirstName}
            fullWidth
            helperText={errorFirstName ? 'Ange ditt förnamn.' : undefined}
            label={'Förnamn'}
            onChange={(event) => {
              props.setErrors([])
              setErrorFirstName(false)
              setFirstName(event.target.value)
            }}
            sx={{ mb: 2 }}
            value={firstName}
          />
          <TextField
            error={errorLastName}
            fullWidth
            helperText={errorLastName ? 'Ange ditt efternamn.' : undefined}
            label={'Efternamn'}
            onChange={(event) => {
              props.setErrors([])
              setErrorLastName(false)
              setLastName(event.target.value)
            }}
            sx={{ mb: 2 }}
            value={lastName}
          />
        </Collapse>
        <Box sx={{ display: 'flex', gap: 2 }}>
          <Box>
            <TextField
              error={errorRepeatPassword}
              fullWidth
              helperText={
                errorRepeatPassword
                  ? 'Lösenorden som du angivit matchar inte.'
                  : undefined
              }
              label={'Nytt lösenord'}
              onChange={handleChangeNewPassword}
              onKeyDown={(e) => handleEnter(e, handleActivateAccount)}
              sx={{ mb: 2 }}
              value={newPassword}
              type={'password'}
            />
            <TextField
              error={errorRepeatPassword}
              fullWidth
              label={'Återupprepa nytt lösenord'}
              helperText={
                errorRepeatPassword
                  ? 'Lösenorden som du angivit matchar inte.'
                  : undefined
              }
              onChange={handleChangeRepeatNewPassword}
              onKeyDown={(e) => handleEnter(e, handleActivateAccount)}
              value={repeatNewPassword}
              type={'password'}
              sx={{ mb: 4 }}
            />
          </Box>
          <List dense={true}>
            {getValidateItems(newPassword).map((item, i) => {
              const color = !newPassword
                ? undefined
                : item.valid
                  ? 'success'
                  : 'error'

              return (
                <ListItem key={i}>
                  <ListItemIcon>
                    <Icon
                      color={color}
                      name={
                        item.valid ? 'CheckCircleOutlined' : 'CancelOutlined'
                      }
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={item.title}
                    primaryTypographyProps={{
                      color: color ? `${color}.text` : 'text.primary',
                      noWrap: true
                    }}
                  />
                </ListItem>
              )
            })}
          </List>
        </Box>
        <Button
          color="brand"
          disabled={!validPassword || !repeatNewPassword}
          fullWidth={true}
          loading={props.loading}
          loadingTitle="Aktiverar ditt konto"
          onClick={handleActivateAccount}
          size="large"
          sx={{ mb: 4 }}
        >
          Aktivera konto
        </Button>
        {props.errors.length > 0 ? (
          <Alert severity={'error'} variant={'filled'}>
            {props.errors[0].message}
          </Alert>
        ) : null}
      </Box>
    </>
  )
}

ActivateAccount.displayName = 'ActivateAccount'

export default ActivateAccount
