import React, { ReactNode, useState } from 'react'

import Autocomplete from 'components_new/atoms/Autocomplete'
import Box from 'components_new/atoms/Box'
import Chip from 'components_new/atoms/Chip'
import Divider from 'components_new/atoms/Divider'
import Icon from 'components_new/atoms/Icon'
import InputAdornment from 'components_new/atoms/InputAdornment'
import MenuItem from 'components_new/atoms/Menu/MenuItem'
import Paper from 'components_new/atoms/Paper'
import Text from 'components_new/atoms/Text'
import TextField from 'components_new/atoms/TextField'
import TooltipWrapper from 'components_new/atoms/TooltipWrapper'

import IconTip from 'components_new/molecules/IconTip'

import { sortAlphabeticalAsc } from 'utils/sortHelper'
import { PaperProps } from '@mui/material'

export type Option = {
  disabled?: boolean
  disabledTitle?: ReactNode
  group?: string
  id: string | number | null
  label: string | number | null
}

interface SelectSearchProps {
  closeOnSelect?: boolean
  color?: 'primary' | 'secondary'
  customIcon?: ReactNode
  disabled?: boolean
  externalLabel?: string
  helperText?: ReactNode
  label?: string
  lastOption?: ReactNode
  multiple?: boolean
  // List if multiple=true
  onChange: ((params: Option) => void) | ((params: Option[]) => void)
  options: Option[]
  placeholder?: string
  size?: 'small' | 'medium'
  sx?: object
  selected: Option[] | Option | null // List if multiple=true
  sort?: boolean
}

const SelectSearch = React.forwardRef((props: SelectSearchProps, ref: any) => {
  const [inputValue, setInputValue] = useState<string>('')
  const [keepInputValue, setKeepInputValue] = useState<boolean>(false)

  const showGroups =
    props.options[0]?.group &&
    !props.options.every(({ group }) => group === props.options[0].group)

  const getSortedOptions = (): any[] => {
    return props.options.sort((a, b) => {
      if (showGroups && a.group && b.group) {
        if (a.group.toLowerCase() === b.group.toLowerCase()) {
          return sortAlphabeticalAsc(
            a.label as string | null,
            b.label as string | null
          )
        }

        return sortAlphabeticalAsc(a.group, b.group)
      }

      return sortAlphabeticalAsc(
        a.label as string | null,
        b.label as string | null
      )
    })
  }

  const PaperComponent = (paperProps: PaperProps) => {
    return (
      <Paper sx={{ overflow: 'hidden' }}>
        {props.multiple ? (
          <>
            <Box
              onClick={() => setKeepInputValue(!keepInputValue)}
              onMouseDown={(e) => {
                e.stopPropagation()
                e.preventDefault()
              }}
              sx={{
                cursor: 'pointer',
                gap: 0.5,
                p: 1
              }}
            >
              <Icon
                className="CheckBoxIcon"
                color={keepInputValue ? 'primary' : 'disabled'}
                name={keepInputValue ? 'CheckBox' : 'CheckBoxOutlineBlank'}
                fontSize="small"
                sx={{
                  display: 'inline-block',
                  verticalAlign: '-0.325rem',
                  mr: '0.25rem'
                }}
              />
              <Text
                className="CheckBoxLabel"
                color={keepInputValue ? 'text.primary' : 'text.disabled'}
                variant="body2"
                sx={{
                  display: 'inline-block',
                  mr: '0.25rem'
                }}
              >
                Behåll sökning vid val
              </Text>
              <IconTip
                sx={{ color: 'action.disabled' }}
                title="Markera för att låta sökfrasen stanna kvar i fältet efter varje val, så att du kan välja flera alternativ utan att behöva skriva om samma sökord."
              />
            </Box>
            <Divider />
          </>
        ) : null}
        {paperProps.children}
        {props.lastOption ? (
          <Box
            onMouseDown={(e) => {
              e.stopPropagation()
              e.preventDefault()
            }}
          >
            {props.lastOption}
          </Box>
        ) : null}
      </Paper>
    )
  }

  return (
    <>
      {props.externalLabel ? (
        <Text
          color="text.primary"
          fontWeight="medium"
          variant="body2"
          sx={{ mb: 0.25 }}
        >
          {props.externalLabel}
        </Text>
      ) : null}
      <Autocomplete
        autoHighlight={true}
        disableCloseOnSelect={props.multiple ?? !props.closeOnSelect}
        disabled={props.disabled}
        filterSelectedOptions={props.multiple}
        groupBy={showGroups ? (option) => option.group : undefined}
        helperText={props.helperText}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        ListboxProps={{
          sx: {
            maxHeight: 200
          }
        }}
        multiple={props.multiple ?? true}
        onChange={(event, newValue) => {
          props.onChange(newValue)
        }}
        onInputChange={(event, value, reason) => {
          if (keepInputValue) {
            if (event && event.type === 'blur') {
              setInputValue('')
            } else if (reason !== 'reset') {
              setInputValue(value)
            }
          } else {
            setInputValue(value)
          }
        }}
        onClose={() => setInputValue('')}
        openOnFocus={true}
        options={props.sort ? getSortedOptions() : props.options}
        PaperComponent={PaperComponent}
        ref={ref}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip
              color={props.color ?? undefined}
              label={option.label}
              size={props.size ?? 'small'}
              sx={{
                maxWidth: 'calc(100% - 36px)'
              }}
              {...getTagProps({ index })}
              key={index}
            />
          ))
        }
        inputValue={inputValue}
        renderInput={(params) => (
          <TextField
            {...params}
            label={props.label}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <>
                  <InputAdornment
                    position="start"
                    sx={{ width: 20, height: 20, mr: 0 }}
                  >
                    {props.customIcon ?? (
                      <Icon color="disabled" fontSize="small" name="Search" />
                    )}
                  </InputAdornment>
                  {params.InputProps.startAdornment}
                </>
              ),
              sx: {
                '& .MuiAutocomplete-input': { paddingLeft: '4px!important' }
              }
            }}
            placeholder={props.placeholder ?? 'Sök...'}
          />
        )}
        renderOption={(props, option) => (
          <div>
            <TooltipWrapper
              key={option.id}
              placement="right-end"
              title={option.disabled ? option.disabledTitle : ''}
            >
              <MenuItem
                {...props}
                disabled={option.disabled}
                sx={{
                  '&.Mui-focused': {
                    borderLeft: '2px solid',
                    borderColor: 'primary.main',
                    pl: 'calc(16px - 2px)'
                  }
                }}
              >
                {option.label}
              </MenuItem>
            </TooltipWrapper>
          </div>
        )}
        size={props.size ?? 'small'}
        sx={{
          width: '100%',
          maxWidth: '100%',
          minWidth: 0,
          mt: props.label ? 1 : undefined,
          '& .MuiFormControl-root': {
            m: 0
          },
          ...props.sx
        }}
        value={props.selected}
      />
    </>
  )
})

SelectSearch.displayName = 'SelectSearch'
export default SelectSearch
