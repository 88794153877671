import React, { useEffect, useState } from 'react'
import { bindActionCreators, Dispatch } from 'redux'
import { connect, ConnectedProps } from 'react-redux'

import Box from 'components_new/atoms/Box'
import Button from 'components_new/atoms/Button'
import Text from 'components_new/atoms/Text'

import DndList from 'components_new/molecules/DndList'
import SimpleDialog from 'components_new/molecules/SimpleDialog'

import DragItem from './DragItem'

import * as KpiVariableActions from 'redux/actions/KpiVariables'

import { KpiVariable } from 'types/GlobalKpiVariable'
import { getPutOrder } from 'components_new/templates/settings/Economy/utils'

interface EditKpiVariableOrderDialogProps {
  id?: string
  open: boolean
  onClose: () => void
  variables: KpiVariable[]
}

const EditKpiVariableOrderDialog = (props: ComponentProps) => {
  const { variables } = props
  const [order, setOrder] = useState<KpiVariable[]>([])

  useEffect(() => {
    if (props.open) {
      let variablesToOrder = [...variables]

      if (props.id) {
        variablesToOrder = variablesToOrder.filter(
          (v) => v.parent_kpi_variable_id === props.id
        )
      } else {
        variablesToOrder = variablesToOrder.filter((v) => v.level === 0)
      }

      setOrder([...variablesToOrder])
    }
  }, [props.open])

  return (
    <SimpleDialog
      actions={
        <>
          <Button onClick={props.onClose} variant="text">
            Avbryt
          </Button>
          <Button
            onClick={() => {
              props.tryPutSortedKpiVariables(
                getPutOrder(variables, order, props.id)
              )

              props.onClose()
            }}
          >
            Spara
          </Button>
        </>
      }
      open={props.open}
      onClose={props.onClose}
      title={
        <Text variant="h3" gutterBottom={true}>
          Redigera ordning
        </Text>
      }
      sx={{ zIndex: 1302 }}
    >
      <Box sx={{ maxWidth: 'title', mb: 2 }}>
        <Text variant="subtitle2" gutterBottom={true}>
          Ordningen används för att sortera visualiseringar i en naturlig och
          logisk följd för ekonomiska konton.
        </Text>
      </Box>
      <DndList
        handleDragEnd={(newOrder) => {
          setOrder((prevState) => {
            return newOrder.map((id, index) => {
              const element = prevState.find(
                (item) => item.id === id
              ) as KpiVariable

              return {
                ...element,
                index
              }
            })
          })
        }}
        items={order.map((item, i) => {
          return {
            id: item.id,
            content: <DragItem index={i + 1} title={item.name} />,
            containerSx: {
              mb: 1,
              p: 1
            }
          }
        })}
      />
    </SimpleDialog>
  )
}
const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators({ ...KpiVariableActions }, dispatch)
}

const connector = connect(() => ({}), mapDispatchToProps)

type ComponentProps = ConnectedProps<typeof connector> &
  EditKpiVariableOrderDialogProps

export default connector(EditKpiVariableOrderDialog)
