import React, { ReactNode } from 'react'

import Icon from 'components_new/atoms/Icon'
import IconButton from 'components_new/atoms/IconButton'
import Tooltip from 'components_new/atoms/Tooltip'

interface IconTipProps {
  disabled?: boolean
  edge?: 'end' | 'start'
  icon?: ReactNode
  placement?:
    | 'bottom-end'
    | 'bottom-start'
    | 'bottom'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top-start'
    | 'top'
  size?: 'small' | 'medium'
  sx?: object
  title: ReactNode
  variant?: 'info' | 'help' | 'note'
}

/**
 * The IconTip component is used to show
 * an informative tooltip when hovering
 * an icon.
 *
 * 'info' is used for providing users with
 * an extra bit of insight or knowledge.
 * Information that is not critical, but
 * still really good to know.
 *
 * 'help' is used if the user has to take
 * an action that might trigger a “…but
 * why?”, “…what does this mean?” etc.
 *
 * 'note' is used when there’s information
 * about a field or action that your user
 * should know before they move forward
 * (but you feel that most might be aware
 * of this).
 *
 * It is also possible to set a custom icon
 * using the icon prop.
 */

const IconTip = React.forwardRef((props: IconTipProps, ref: any) => {
  const { disabled, edge, icon, placement, size, sx, title, variant } = props

  return (
    <Tooltip placement={placement} title={title}>
      <IconButton
        disabled={disabled}
        edge={edge}
        onClick={(e) => {
          e.stopPropagation()
          e.preventDefault()
        }}
        size="small"
        sx={{
          cursor: 'default',
          width: size === 'small' ? '1rem' : '1.25rem',
          height: size === 'small' ? '1rem' : '1.25rem',
          p: 0,
          pointerEvents: disabled ? 'none' : 'all!important',
          ...sx
        }}
        ref={ref}
      >
        {icon ? (
          icon
        ) : (
          <Icon
            name={
              variant === 'help'
                ? 'HelpOutlined'
                : variant === 'note'
                  ? 'FeedbackOutlined'
                  : 'InfoOutlined'
            }
            sx={{ fontSize: size === 'small' ? '1rem' : '1.25rem' }}
          />
        )}
      </IconButton>
    </Tooltip>
  )
})

IconTip.defaultProps = {
  variant: 'info'
}

IconTip.displayName = 'IconTip'
export default IconTip
