import React, { useMemo, useState } from 'react'

import Box from 'components_new/atoms/Box'
import Collapse from 'components_new/atoms/Collapse'
import Icon from 'components_new/atoms/Icon'
import IconButton from 'components_new/atoms/IconButton'
import TableRow from 'components_new/atoms/Table/TableRow'
import TableCell from 'components_new/atoms/Table/TableCell'
import Tag from 'components_new/atoms/Tag'
import Text from 'components_new/atoms/Text'

import OptionsMenuButton from 'components_new/molecules/OptionsMenuButton'

import { KpiTemplate } from 'types/GlobalKpiTemplates'

import VariablesSection from './'
import { KpiVariable } from 'types/GlobalKpiVariable'

interface RowProps {
  inUse: boolean
  kpis: KpiTemplate[]
  level: number
  onCreateVariable: (name: string, level: number, parentId?: string) => void
  onDeleteVariable: (id: string, callback: (err?: boolean) => void) => void
  onUpdateVariable: (
    id: string,
    name: string,
    callback: (err?: boolean) => void
  ) => void
  onDelete: () => void
  onEdit: () => void
  onEditOrder: (id: string) => void
  variable: KpiVariable
  allVariables: KpiVariable[]
}
const Row = (props: RowProps) => {
  const {
    kpis,
    level,
    inUse,
    onDelete,
    onEdit,
    onEditOrder,
    variable,
    allVariables,
    onCreateVariable,
    onDeleteVariable,
    onUpdateVariable
  } = props

  const [open, setOpen] = useState(false)

  const editable = variable.editable
  const children = useMemo(
    () => allVariables.filter((v) => v.parent_kpi_variable_id === variable.id),
    [allVariables]
  )

  return (
    <>
      <TableRow hover={true} noBorder={true}>
        <TableCell>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 1,
              pl: level * 4
            }}
          >
            <Box sx={{ width: 30 }}>
              {level < 2 ? (
                <IconButton size="small" onClick={() => setOpen(!open)}>
                  {open ? (
                    <Icon fontSize="small" name="KeyboardArrowUp" />
                  ) : (
                    <Icon fontSize="small" name="KeyboardArrowDown" />
                  )}
                </IconButton>
              ) : null}
            </Box>
            <Tag startIconName="SellOutlined" color="primary" />
            <Text variant="subtitle2">{variable.name}</Text>
          </Box>
        </TableCell>
        <TableCell sx={{ width: 180 }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              gap: 1
            }}
          >
            <Box component="span">
              <OptionsMenuButton
                color="disabled"
                iconName="MoreHorizOutlined"
                options={[
                  {
                    disabled: !editable,
                    iconName: 'EditOutlined',
                    onClick: onEdit,
                    title: 'Redigera',
                    tooltip: !editable
                      ? 'Den här variabeln är en del av Homepals standard och går därför inte att redigera.'
                      : ''
                  },
                  {
                    disabled: inUse || !editable,
                    iconName: 'DeleteOutlined',
                    onClick: onDelete,
                    title: 'Radera',
                    tooltip: inUse
                      ? 'Den här variabeln används i ett nyckeltal och går därför inte att radera.'
                      : !editable
                          ? 'Den här variabeln är en del av Homepals standard och går därför inte att radera.'
                          : ''
                  },
                  {
                    disabled: children.length === 0,
                    iconName: 'LowPriorityOutlined',
                    onClick: () => onEditOrder(variable.id),
                    title: 'Redigera ordning',
                    tooltip:
                      children.length === 0
                        ? 'Det går inte att ändra ordningen för en kontonivå utan under kontonivåer.'
                        : ''
                  }
                ]}
              />
            </Box>
          </Box>
        </TableCell>
      </TableRow>
      <TableRow noBorder={true}>
        <TableCell colSpan={3} sx={{ p: 0 }}>
          <Collapse in={open} timeout="auto" unmountOnExit={true}>
            <VariablesSection
              allVariables={allVariables}
              kpis={kpis}
              level={variable.level + 1}
              onCreateVariable={onCreateVariable}
              onDeleteVariable={onDeleteVariable}
              onUpdateVariable={onUpdateVariable}
              parentVariable={variable}
              variables={children}
            />
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}

export default Row
