import React, { ElementType, MouseEvent } from 'react'

import { useState } from 'react'

import { Popper } from '@mui/material'
import { AvatarGroup as MUIAvatarGroup } from '@mui/material'

import Avatar from 'components_new/atoms/Avatar'
import Box from 'components_new/atoms/Box'
import Stack from 'components_new/atoms/Stack'
import Text from 'components_new/atoms/Text'
import Tooltip from 'components_new/atoms/Tooltip'
import Fade from 'components_new/atoms/Fade'

interface AvatarGroupProps {
  component?: ElementType<any>
  max?: number
  spacing?: 'medium' | 'small' | number
  sx?: object
  users: string[]
}

/**
 * The AvatarGroup component is used to
 * to display a group of users as Avatars
 * in a compact way.
 */

const AvatarGroup = React.forwardRef((props: AvatarGroupProps, ref: any) => {
  const { sx, users, ...rest } = props

  const [additionalAvatarAnchorEl, setAdditionalAvatarAnchorEl] =
    useState<HTMLElement | null>(null)
  const open = Boolean(additionalAvatarAnchorEl)
  const handleLeaveAdditionalAvatar = () => {
    setAdditionalAvatarAnchorEl(null)
  }

  return (
    <>
      <MUIAvatarGroup
        {...rest}
        max={4}
        ref={ref}
        total={props.users.length}
        slotProps={{
          additionalAvatar: {
            onMouseEnter: (event: MouseEvent<HTMLElement>) => {
              setAdditionalAvatarAnchorEl(event.currentTarget)
            },
            onMouseLeave: handleLeaveAdditionalAvatar,
            onClick: (event) => {
              event.stopPropagation()
              event.preventDefault()
            },
            onMouseDown: (event) => event.stopPropagation(),
            sx: {
              bgcolor: 'grey.200',
              color: 'text.secondary',
              border: '1px solid',
              borderColor: 'divider'
            }
          }
        }}
        sx={{
          width: 'fit-content',
          '& .MuiAvatar-root': {
            width: 24,
            height: 24,
            fontSize: 12,
            cursor: 'default',
            border: '1px solid',
            borderColor: 'divider',
            '&:hover': {
              transform: 'translateY(-2px)',
              transition: 'transform 0.5s'
            }
          },
          ...sx
        }}
      >
        {users.map((user, i) => (
          <Tooltip title={user} key={i}>
            <Avatar
              alt={user}
              onClick={(event) => {
                event.stopPropagation()
                event.preventDefault()
              }}
              onMouseDown={(event) => event.stopPropagation()}
            />
          </Tooltip>
        ))}
      </MUIAvatarGroup>

      <Popper
        open={open}
        anchorEl={additionalAvatarAnchorEl}
        onMouseLeave={handleLeaveAdditionalAvatar}
        sx={{ zIndex: 1500 }}
      >
        <Fade in={open}>
          <Box
            sx={{
              p: 1,
              mt: '14px',
              bgcolor: 'background.tooltip',
              color: 'common.white'
            }}
          >
            <Stack direction="column" spacing={1}>
              {props.users.slice(3, props.users.length).map((user, i) => (
                <Stack
                  direction="row"
                  spacing={1}
                  key={i}
                  sx={{ alignItems: 'center' }}
                >
                  <Avatar
                    alt={user}
                    sx={{
                      width: 24,
                      height: 24,
                      fontSize: 12,
                      mr: 0.5
                    }}
                  />
                  <Text
                    sx={{
                      fontWeight: 500,
                      fontSize: '0.6875rem',
                      color: 'inherit'
                    }}
                  >
                    {user}
                  </Text>
                </Stack>
              ))}
            </Stack>
          </Box>
        </Fade>
      </Popper>
    </>
  )
})

AvatarGroup.displayName = 'AvatarGroup'
export default AvatarGroup
