import React from 'react'

import Button from 'components_new/atoms/Button'
import Table from 'components_new/atoms/Table'
import TableBody from 'components_new/atoms/Table/TableBody'
import TableRow from 'components_new/atoms/Table/TableRow'
import TableCell from 'components_new/atoms/Table/TableCell'
import TableHead from 'components_new/atoms/Table/TableHead'

import SimpleDialog from 'components_new/molecules/SimpleDialog'

import Lock from '../../WidgetFooter/DateFilterSection/Lock'

import { ThemeProvider } from '@mui/material'
import { getTheme } from 'themes'

import { getParsedDate } from 'utils/dateParser'
import { isCustomPeriodFilter } from 'utils/functions'
import { CustomPeriodFilter, PeriodFilter } from 'types/GlobalWidget'

interface DetailsDialogProps {
  comparativePeriodFromDate: string | null
  comparativePeriodLabel: string | null
  comparativePeriodToDate: string | null
  dateFilter: PeriodFilter | CustomPeriodFilter
  isLocked: boolean
  isPoint: boolean
  open: boolean
  onClose: () => void
  periodFromDate: string | null
  periodLabel: string | null
  periodToDate: string | null
  showComparativePeriod: boolean
}

const DetailsDialog = (props: DetailsDialogProps) => {
  const {
    comparativePeriodFromDate,
    comparativePeriodLabel,
    comparativePeriodToDate,
    dateFilter,
    isLocked,
    isPoint,
    open,
    onClose,
    periodFromDate,
    periodLabel,
    periodToDate,
    showComparativePeriod
  } = props

  const getPeriodDisplayLabel = () => {
    if (isCustomPeriodFilter(dateFilter)) {
      return 'Anpassad'
    }

    if (showComparativePeriod) {
      return periodLabel?.toLowerCase()
    } else {
      return periodLabel
    }
  }

  const getComparativePeriodDisplayLabel = () => {
    if (isCustomPeriodFilter(dateFilter)) {
      return 'Anpassad'
    }

    return comparativePeriodLabel?.toLowerCase()
  }

  return (
    <ThemeProvider theme={getTheme('light')}>
      <SimpleDialog
        actions={
          <Button onClick={onClose} variant="text">
            Stäng
          </Button>
        }
        onClose={onClose}
        open={open}
      >
        <Table>
          <TableHead>
            <TableRow>
              {showComparativePeriod ? <TableCell>{''}</TableCell> : null}
              <TableCell>{isPoint ? 'Tidpunkt' : 'Tidsperiod'}</TableCell>
              <TableCell>{isPoint ? 'Datum' : 'Från'}</TableCell>
              {periodToDate ? <TableCell>Till</TableCell> : null}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              {showComparativePeriod ? (
                <TableCell>
                  <b>Visar</b>
                </TableCell>
              ) : null}
              <TableCell>
                {getPeriodDisplayLabel()}
                {isLocked ? <Lock /> : null}
              </TableCell>
              <TableCell>{getParsedDate(periodFromDate)}</TableCell>
              {periodToDate ? (
                <TableCell>{getParsedDate(periodToDate)}</TableCell>
              ) : null}
            </TableRow>
            {showComparativePeriod ? (
              <TableRow>
                <TableCell>
                  <b>Jämför med</b>
                </TableCell>
                <TableCell>
                  {getComparativePeriodDisplayLabel()}
                  {isLocked ? <Lock /> : null}
                </TableCell>
                <TableCell>
                  {getParsedDate(comparativePeriodFromDate)}
                </TableCell>
                {comparativePeriodToDate ? (
                  <TableCell>
                    {getParsedDate(comparativePeriodToDate)}
                  </TableCell>
                ) : null}
              </TableRow>
            ) : null}
          </TableBody>
        </Table>
      </SimpleDialog>
    </ThemeProvider>
  )
}

export default DetailsDialog
