import React from 'react'

import EntityColumnSettings from 'components_new/organisms/EntityColumnSettings'
import SettingsTemplate from 'components_new/templates/SettingsTemplate'

const EntityColumnsPage = () => {
  return (
    <SettingsTemplate>
      <EntityColumnSettings />
    </SettingsTemplate>
  )
}

export default EntityColumnsPage
