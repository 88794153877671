import React, { ReactNode } from 'react'

import Box from 'components_new/atoms/Box'
import Button from 'components_new/atoms/Button'
import Chip from 'components_new/atoms/Chip'
import Divider from 'components_new/atoms/Divider'
import FormControlLabel from 'components_new/atoms/FormControlLabel'
import List from 'components_new/atoms/List'
import ListItem from 'components_new/atoms/List/ListItem'
import MenuItem from 'components_new/atoms/Menu/MenuItem'
import Icon from 'components_new/atoms/Icon'
import Switch from 'components_new/atoms/Switch'
import Text from 'components_new/atoms/Text'
import TextField from 'components_new/atoms/TextField'
import UserListItem from 'components_new/molecules/UserListItem'

import {
  FrequencyOfDataSynchronization,
  InternalCustomer,
  License,
  PatchInternalCustomerBody,
  SupportLevel,
  translateFrequencyOfDataSynchronization,
  translateLicense,
  translateSupportLevel
} from 'types/GlobalCustomer'

import Paper from 'components_new/atoms/Paper'
import LoadingSection from 'components_new/molecules/LoadingSection'
import TableContainer from 'components_new/atoms/Table/TableContainer'
import TableHead from 'components_new/atoms/Table/TableHead'
import TableRow from 'components_new/atoms/Table/TableRow'
import TableCell from 'components_new/atoms/Table/TableCell'
import TableBody from 'components_new/atoms/Table/TableBody'
import { default as AtomTable } from 'components_new/atoms/Table'
import { parseKeyFigureData } from './utils'
import { PatchOrderedKpiTemplateBody } from 'types/GlobalOrderedKpiTemplate'
import { KpiTemplateRedux, KpiTemplateType } from 'types/GlobalKpiTemplates'

interface OneCustomerProps {
  createOrderedKpiTemplate: (kpiTemplateId: string) => void
  customer: InternalCustomer
  deleteOrderedKpiTemplate: (id: string) => void
  kpiTemplates: KpiTemplateRedux
  kpiTemplatesFetched: boolean
  updateCustomer: (body: PatchInternalCustomerBody) => void
  updateOrderedKpiTemplate: (
    id: string,
    body: PatchOrderedKpiTemplateBody
  ) => void
}

interface AvatarSectionProps {
  customer: InternalCustomer
}

interface TableProps {
  headers: {
    label: string
    align?: 'center' | 'left' | 'right'
  }[]
  loading?: boolean
  rows: {
    label: string
    isHeader?: boolean
    colSpan?: number
    values: ReactNode[]
  }[]
  sx?: object
}

const OneCustomer = (props: OneCustomerProps) => {
  const kpiTemplates = Object.values(props.kpiTemplates).filter(
    (template) => template.type === KpiTemplateType.STANDARD
  )

  const keyFigureData = parseKeyFigureData(
    props.customer?.allow_access,
    kpiTemplates,
    props.customer.ordered_kpi_templates,
    props.createOrderedKpiTemplate,
    props.deleteOrderedKpiTemplate,
    props.updateOrderedKpiTemplate
  )

  return (
    <Box
      sx={{
        height: '100%',
        width: '100%',
        display: 'flex'
      }}
    >
      <Box
        sx={{
          height: '100%',
          width: '100%',
          bgcolor: 'background.paper',
          borderRightStyle: 'solid',
          borderRightColor: 'divider',
          borderRightWidth: 1,
          p: 2,
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <Box sx={{ borderBottom: '1px solid', borderBottomColor: 'divider' }}>
          <Button
            startIcon={<Icon name="ArrowBack" />}
            variant="text"
            to={'/our-customers'}
          >
            Tillbaka
          </Button>

          <AvatarSection customer={props.customer} />
        </Box>
        <Box sx={{ flex: '1 1 auto', overflowY: 'auto' }}>
          <List dense={true}>
            <ListItem>
              <Text variant="h6">Licens</Text>
            </ListItem>
            <ListItem>
              <TextField
                label={'Välj licens...'}
                select
                onChange={(e) =>
                  props.updateCustomer({
                    data: {
                      license: e.target.value as License
                    }
                  })
                }
                size={'small'}
                value={props.customer.license}
              >
                {Object.values(License).map((opt) => {
                  return (
                    <MenuItem value={opt} key={opt}>
                      {translateLicense[opt].display}
                    </MenuItem>
                  )
                })}
              </TextField>
            </ListItem>
            <ListItem>
              <FormControlLabel
                control={<Switch color="primary" />}
                checked={props.customer.is_demo}
                onChange={() =>
                  props.updateCustomer({
                    data: { is_demo: !props.customer.is_demo }
                  })
                }
                label="Demokonto"
                labelPlacement="end"
              />
            </ListItem>
          </List>
          <Divider />
          <List dense={true}>
            <ListItem>
              <Text variant="h6">Datasynkronisering</Text>
            </ListItem>
            <ListItem>
              <TextField
                label={'Välj frekvens...'}
                select
                onChange={(e) =>
                  props.updateCustomer({
                    data: {
                      frequency_of_data_synchronization: e.target
                        .value as FrequencyOfDataSynchronization
                    }
                  })
                }
                size={'small'}
                value={
                  props.customer.allow_access.frequency_of_data_synchronization
                }
              >
                {Object.values(FrequencyOfDataSynchronization).map((opt) => {
                  return (
                    <MenuItem value={opt} key={opt}>
                      {translateFrequencyOfDataSynchronization[opt].display}
                    </MenuItem>
                  )
                })}
              </TextField>
            </ListItem>
          </List>
          <Divider />
          <List dense={true}>
            <ListItem>
              <Text variant="h6">Dashboards</Text>
            </ListItem>
            <ListItem>
              <TextField
                label={'Välj antal...'}
                select
                onChange={(e) => {
                  let numberOfDashboards: string | null = e.target.value

                  if (e.target.value === 'unlimited') {
                    numberOfDashboards = null
                  }

                  props.updateCustomer({
                    data: { number_of_dashboards: numberOfDashboards }
                  })
                }}
                size={'small'}
                value={props.customer.number_of_dashboards || 'unlimited'}
              >
                {Array.from(Array(100).keys()).map((opt) => {
                  if (opt === 0) {
                    return (
                      <MenuItem value={'unlimited'} key={'unlimited'}>
                        Obegränsat antal
                      </MenuItem>
                    )
                  }

                  return (
                    <MenuItem value={opt} key={opt}>
                      {opt}
                    </MenuItem>
                  )
                })}
              </TextField>
            </ListItem>
          </List>
          <Divider />
          <List dense={true}>
            <ListItem>
              <Text variant="h6">Nyckeltal</Text>
            </ListItem>
            <ListItem>
              <FormControlLabel
                control={<Switch color="primary" />}
                checked={props.customer.allow_access.premium_kpis}
                onChange={() =>
                  props.updateCustomer({
                    data: {
                      premium_kpis: !props.customer.allow_access.premium_kpis
                    }
                  })
                }
                label="Premium"
                labelPlacement="end"
              />
            </ListItem>
          </List>
          <Divider />
          <List dense={true}>
            <ListItem>
              <Text variant="h6">Benchmarking</Text>
            </ListItem>
            <ListItem>
              <FormControlLabel
                control={<Switch color="primary" />}
                checked={props.customer.allow_access.benchmarking}
                onChange={() =>
                  props.updateCustomer({
                    data: {
                      benchmarking: !props.customer.allow_access.benchmarking
                    }
                  })
                }
                label="Intern"
                labelPlacement="end"
              />
            </ListItem>
          </List>
          <Divider />
          <List dense={true}>
            <ListItem>
              <Text variant="h6">Användare</Text>
            </ListItem>
            <ListItem>
              <FormControlLabel
                control={<Switch color="primary" />}
                checked={props.customer.allow_access.sso}
                onChange={() =>
                  props.updateCustomer({
                    data: { sso: !props.customer.allow_access.sso }
                  })
                }
                label="Microsoft AD (SSO)"
                labelPlacement="end"
              />
            </ListItem>
          </List>
          <Divider />
          <List dense={true}>
            <ListItem>
              <Text variant="h6">Delning</Text>
            </ListItem>
            <ListItem>
              <FormControlLabel
                control={<Switch color="primary" />}
                checked={props.customer.allow_access.embed}
                onChange={() =>
                  props.updateCustomer({
                    data: { embed: !props.customer.allow_access.embed }
                  })
                }
                label="Inbäddning"
                labelPlacement="end"
              />
            </ListItem>
          </List>
          <ListItem>
            <FormControlLabel
              control={<Switch color="primary" />}
              checked={props.customer.is_management_company}
              onChange={() =>
                props.updateCustomer({
                  data: {
                    is_management_company: !props.customer.is_management_company
                  }
                })
              }
              label="Till kunder (förvaltningsbolag)"
              labelPlacement="end"
            />
          </ListItem>
          <Divider />
          <List dense={true}>
            <ListItem>
              <Text variant="h6">Profilering</Text>
            </ListItem>
            <ListItem>
              <FormControlLabel
                control={<Switch color="primary" />}
                checked={props.customer.allow_access.white_label_colors}
                onChange={() =>
                  props.updateCustomer({
                    data: {
                      white_label_colors:
                        !props.customer.allow_access.white_label_colors
                    }
                  })
                }
                label="Sätta färger"
                labelPlacement="end"
              />
            </ListItem>
            <ListItem>
              <FormControlLabel
                control={<Switch color="primary" />}
                checked={props.customer.allow_access.white_label_logotype}
                onChange={() =>
                  props.updateCustomer({
                    data: {
                      white_label_logotype:
                        !props.customer.allow_access.white_label_logotype
                    }
                  })
                }
                label="Sätta logotyp"
                labelPlacement="end"
              />
            </ListItem>
          </List>
          <Divider />
          <List dense={true}>
            <ListItem>
              <Text variant="h6">Support</Text>
            </ListItem>
            <ListItem>
              <TextField
                label={'Välj supportnivå...'}
                select
                onChange={(e) =>
                  props.updateCustomer({
                    data: {
                      support_level: e.target.value as SupportLevel
                    }
                  })
                }
                size={'small'}
                value={props.customer.allow_access.support_level}
              >
                {Object.values(SupportLevel).map((opt) => {
                  return (
                    <MenuItem value={opt} key={opt}>
                      {translateSupportLevel[opt].display}
                    </MenuItem>
                  )
                })}
              </TextField>
            </ListItem>
          </List>
        </Box>
      </Box>
      <Box>
        <Table
          headers={keyFigureData.headers}
          loading={!props.kpiTemplatesFetched}
          rows={keyFigureData.rows}
          sx={{ maxHeight: '100%' }}
        />
      </Box>
    </Box>
  )
}

const AvatarSection = (props: AvatarSectionProps) => {
  const { customer } = props

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        py: 1
      }}
    >
      <UserListItem
        component="div"
        size="large"
        sx={{ p: 0, width: 'auto' }}
        title={customer.name}
      />
      {customer.is_demo ? <Chip label={'Demo'} size="small" /> : null}
    </Box>
  )
}

const Table = (props: TableProps) => {
  if (props.loading) {
    return (
      <Paper sx={{ mt: 2, width: '100%', height: 720 }}>
        <LoadingSection
          titles={[
            'Hämtar information om din licens',
            'Hämtar information om dina nyckeltal'
          ]}
          loading
        />
      </Paper>
    )
  }

  return (
    <TableContainer
      component={Paper}
      sx={{ mt: 2, ...(props.sx ? props.sx : {}) }}
    >
      <AtomTable
        sx={{ minWidth: 650 }}
        aria-label="caption table"
        stickyHeader
        size={'small'}
      >
        <TableHead sx={{ bgcolor: 'black.main' }}>
          <TableRow>
            {props.headers.map((header) => (
              <TableCell
                sx={{ fontWeight: 'bold' }}
                align={header.align}
                key={header.label}
              >
                {header.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {props.rows.map((row, i) => (
            <TableRow
              key={i}
              sx={{ bgcolor: row.isHeader ? 'grey.200' : undefined }}
            >
              <TableCell
                scope="row"
                sx={{ fontWeight: 'bold' }}
                colSpan={row.colSpan}
              >
                {row.label}
              </TableCell>
              {row.values.map((value, j) => (
                <TableCell key={`${i}-${j}`} align={'center'}>
                  {value}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </AtomTable>
    </TableContainer>
  )
}

export default OneCustomer
