import Dagre from 'dagre'

import { isNode } from 'reactflow'
import { MATH_OPERATIONS, MATH_REGEX } from 'helpers/Constants'
import { validateConditions } from 'components/containers/Applications/InsightStudio/DashboardWidget/conf'

const dagreGraph = new Dagre.graphlib.Graph()

dagreGraph.setDefaultEdgeLabel(() => ({}))

const nodeHeight = (node) => {
  if (node.type in ['FILTER', 'OUTPUT', 'UNITE']) {
    return 100 + 20 * node.data.outputAttributes.length
  }

  return 100
}

export const layout = (setElements, direction) => {
  setElements((elements) => {
    const nodeWidth = 200
    const isHorizontal = direction === 'LR'

    dagreGraph.setGraph({
      rankdir: direction,
      nodesep: 150,
      ranksep: 150,
      ranker: 'tight-tree'
    })

    Object.values(elements).forEach((e) =>
      isNode(e)
        ? dagreGraph.setNode(e.id, {
          width: nodeWidth,
          height: nodeHeight(e)
        })
        : dagreGraph.setEdge(e.source, e.target)
    )

    Dagre.layout(dagreGraph)

    const newElements = {}

    Object.values(elements).forEach((e) => {
      if (isNode(e)) {
        const nodeWithPosition = dagreGraph.node(e.id)

        e.targetPosition = isHorizontal ? 'left' : 'top'
        e.sourcePosition = isHorizontal ? 'right' : 'bottom'

        e.position = {
          x: nodeWithPosition.x - nodeWidth / 2,
          y: nodeWithPosition.y - nodeHeight(e) / 2
        }
      }

      newElements[e.id] = e
    })

    return newElements
  })
}

export const validateExpression = (unTrimmedValue, validOptions) => {
  const value = unTrimmedValue.trim()

  if (MATH_OPERATIONS.includes(value[value.length - 1])) {
    return false
  }

  const testExpression = value
    .replace(MATH_REGEX, (match) =>
      validOptions.find((opt) => opt.value === match.replace('@', '').trim())
        ? Math.floor(Math.random() * 10000) + 1 // random int from 1-10001, +1 to avoid 0s
        : 'ERROR'
    )
    .replace(/@/g, '')

  try {
    const response = eval(testExpression)

    return response !== Infinity
  } catch (e) {
    return false
  }
}

export const replaceInputExpression = (toReplace, outputAttributes) => {
  // Sort attributes by length of 'value', longest first
  // to make sure the longest attribute gets replace first
  // e.g. @calc1 before @calc
  const sortedAttributes = Object.values(outputAttributes).sort(
    (a, b) => b.value.length - a.value.length
  )

  let value = toReplace.replace('-', '_-_')

  sortedAttributes.forEach((attribute) => {
    const regex = new RegExp(`@(${attribute.value})(?![a-zA-Z_\\d])`, 'g')

    value = value.replace(regex, `@${attribute.shortId}`)
  })

  return value
}

const LEFT_ATTRIBUTE_NODES = ['LEFT_JOIN', 'DATE_PARSER', 'REPLACE']
const HAS_TWO_INPUTS = ['LEFT_JOIN', 'UNITE']

export const validateNode = (elem, hasError, errorMessage) => {
  const nodeNumber = `(${elem.data.nodeNumberMapper[elem.id]})`

  if (elem.data.newAttribute) {
    newAttributeValidation(elem, hasError, errorMessage, nodeNumber)
  }

  if (LEFT_ATTRIBUTE_NODES.includes(elem.type) && !elem.data.leftAttribute) {
    hasError = true
    errorMessage.push(`Attribut är inte satt för ${elem.type} ${nodeNumber}.`)
  }

  if (elem.type === 'TIME_DIFFERENCE' && !validTimeDifference(elem)) {
    hasError = true
    errorMessage.push(`${elem.type} ${nodeNumber} uppfyller inte alla krav.`)
  }

  if (elem.type === 'AGGREGATE' && !elem.data.groupByAttribute) {
    hasError = true
    errorMessage.push(
      `Grupperingsattribut är inte satt för ${elem.type} ${nodeNumber}.`
    )
  }

  if (
    (elem.data.filterConditions.length === 0 && elem.type === 'FILTER') ||
    (elem.data.filterConditions?.length > 0 &&
      !validateConditions(elem.data.filterConditions))
  ) {
    hasError = true
    errorMessage.push(`Villkoret angivet i en ${elem.type}-nod är felaktigt.`)
  }

  if (
    HAS_TWO_INPUTS.includes(elem.type) &&
    !elem.data.leftNode &&
    !elem.data.rightNode
  ) {
    hasError = true
    errorMessage.push(`Saknas input till nod ${elem.type} ${nodeNumber}.`)
  }

  return hasError
}

const newAttributeValidation = (elem, hasError, errorMessage, nodeNumber) => {
  const newAttr = elem.data.outputAttributes.find(
    (attr) => attr.shortId === elem.data.newAttribute
  )

  if (
    !newAttr ||
    (!newAttr.name && !newAttr.realName) ||
    (newAttr.name === '' && newAttr.realName === '')
  ) {
    hasError = true
    errorMessage.push(
      `Den nya kolumnen saknar namn i ${elem.type} ${nodeNumber}.`
    )
  }
}

/*
  TimeDifference is valid if:
  - Both attributes are set
  - leftAttribute is set and value is set
  - rightAttribute is set and value is set
*/
const validTimeDifference = (elem) =>
  (elem.data.leftAttribute && !elem.data.rightAttribute && elem.data.value) ||
  (!elem.data.leftAttribute && elem.data.rightAttribute && elem.data.value) ||
  (elem.data.leftAttribute && elem.data.rightAttribute && !elem.data.value)

/*
  Seperate CALCULATE from other nodes, as formatting of the value is easier
  like this.
*/
export const validateCalculateNode = (elem, hasError, errorMessage) => {
  const options = elem.data.outputAttributes
    .filter(
      (oa) =>
        ['BIGINT', 'DOUBLE'].includes(oa.type) &&
        oa.shortId !== elem.data.newAttribute
    )
    .map((oa) => ({
      value: oa.name,
      shortId: oa.shortId
    }))

  const validExpression = validateExpression(elem.data.value, options)

  if (!validExpression) {
    hasError = true
    errorMessage.push(
      `Uttrycket i Calculate-noden (${
        elem.data.nodeNumberMapper[elem.id]
      }) är ogiltigt.`
    )

    return { hasError, value: elem.data.value }
  }

  return { hasError, value: replaceInputExpression(elem.data.value, options) }
}
