import React, { ReactNode } from 'react'

import Checkbox from 'components_new/atoms/Checkbox'
import FormControlLabel from 'components_new/atoms/FormControlLabel'

interface CheckboxItemProps {
  checked: boolean
  disabled?: boolean
  // If true, the label is rendered as it is passed without an
  // additional text node:
  disableText?: boolean
  label: ReactNode
  onChange?: () => void
  size?: 'small' | 'medium'
  sx?: object
  TextProps?: object
}

const CheckboxItem = (props: CheckboxItemProps) => {
  const {
    checked,
    disabled,
    disableText,
    label,
    onChange,
    size,
    sx,
    TextProps
  } = props

  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={checked}
          disabled={disabled}
          onChange={onChange}
          size={size}
        />
      }
      disableTypography={disableText}
      label={label}
      labelPlacement="end"
      slotProps={{
        typography: TextProps
      }}
      sx={{ mx: 0, ...sx }}
    />
  )
}

export default CheckboxItem
