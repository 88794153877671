import React, { useEffect, useMemo, useState } from 'react'

import { ThemeProvider } from '@mui/material'
import { getTheme } from 'themes'

import { ConnectedProps, connect } from 'react-redux'
import { ApplicationState } from 'redux/Stores/types'

import Box from 'components_new/atoms/Box'
import Button from 'components_new/atoms/Button'
import MenuItem from 'components_new/atoms/Menu/MenuItem'
import Portal from 'components_new/atoms/Portal'
import Snackbar from 'components_new/atoms/Snackbar'
import Text from 'components_new/atoms/Text'
import TextField from 'components_new/atoms/TextField'

import SectionHeader from 'components_new/molecules/SectionHeader'
import SimpleDialog from 'components_new/molecules/SimpleDialog'

import { description, title } from './content'
import { sendMessageToSlack } from './utils'
import { getAvailableGroups } from 'utils/functions'
import { sortAlphabeticalAsc } from 'utils/sortHelper'

interface SubmitDashboardExampleDialogProps {
  onClose: () => void
  open: boolean
}

const SubmitDashboardExampleDialog = (props: ComponentProps) => {
  const { AuthStore, KPIDashboardStore, onClose, open } = props

  const [data, setData] = useState<{
    description: string
    id: string
  }>({
    description: '',
    id: ''
  })

  const handleUpdate = (key: 'description' | 'id', value: string) => {
    setData((prevState) => ({
      ...prevState,
      [key]: value
    }))
  }

  const [submitSuccess, setSubmitSuccess] = useState<boolean>(false)

  const handleSubmit = () => {
    const email = AuthStore.user?.email ?? ''
    const title = KPIDashboardStore.groups[data.id]?.title

    sendMessageToSlack({ title: title, description: data.description }, email)
      .then(() => {
        onClose()
        setSubmitSuccess(true)
      })
      .catch((error) => console.log(error))
  }

  const availableGroups = useMemo(() => {
    return getAvailableGroups(KPIDashboardStore.groups).sort((a, b) =>
      sortAlphabeticalAsc(a.title, b.title)
    )
  }, [KPIDashboardStore])

  useEffect(() => {
    if (availableGroups[0]) {
      handleUpdate('id', availableGroups[0].id)
    }
  }, [KPIDashboardStore])

  return (
    <>
      <ThemeProvider theme={getTheme('light')}>
        <SimpleDialog
          actions={
            <>
              <Button onClick={onClose} variant="text">
                Avbryt
              </Button>
              <Button
                disabled={!Boolean(data.id) || !Boolean(data.description)}
                onClick={handleSubmit}
              >
                Skicka förfrågan
              </Button>
            </>
          }
          maxWidth="xs"
          onClose={onClose}
          open={open}
          title={title}
        >
          <Text color="text.secondary" variant="body1">
            {description}
          </Text>
          <SectionHeader title={'Dashboard'} sx={{ mt: 2 }} />
          <TextField
            onChange={(event) => handleUpdate('id', event.target.value)}
            placeholder={'Ex. Benchmarking av felanmälningar'}
            select={true}
            sx={{
              mt: 0,
              mb: 2,
              '& .MuiFormHelperText-root': {
                textAlign: 'right'
              }
            }}
            value={data.id}
          >
            {availableGroups.map((dashboard) => (
              <MenuItem key={dashboard.id} value={dashboard.id}>
                <Box sx={{ alignItems: 'center', display: 'flex', gap: 2 }}>
                  <img
                    loading="lazy"
                    height="24"
                    width="32"
                    src={dashboard.thumbnail ?? ''}
                    alt=""
                  />
                  {dashboard.title}
                </Box>
              </MenuItem>
            ))}
          </TextField>
          <SectionHeader title={'Motivering'} />
          <TextField
            helperText={`${data.description.length}/${500}`}
            InputProps={{
              inputProps: {
                maxLength: 500
              }
            }}
            multiline={true}
            onChange={(event) =>
              handleUpdate('description', event.target.value)
            }
            placeholder={
              'Ex. Jag tror att fliken som heter "Felanmälningar" hade kunnat vara väldigt intressant för andra bolag också. Ni får gärna publicera den här med er påhittade data!'
            }
            rows={6}
            sx={{
              mt: 0,
              '& .MuiFormHelperText-root': {
                textAlign: 'right'
              }
            }}
            value={data.description}
          />
        </SimpleDialog>
      </ThemeProvider>

      {/*-- snackbars --*/}
      <Portal>
        <Snackbar
          open={submitSuccess}
          autoHideDuration={3000}
          onClose={(event, reason) => {
            if (reason !== 'clickaway') {
              setSubmitSuccess(false)
            }
          }}
          message="Förfrågan skickad"
        />
      </Portal>
    </>
  )
}

/*-- redux --*/
const mapStateToProps = (state: ApplicationState) => ({
  AuthStore: state.AuthStore,
  KPIDashboardStore: state.KPIDashboardStore
})

const connector = connect(mapStateToProps)

type ComponentProps = ConnectedProps<typeof connector> &
  SubmitDashboardExampleDialogProps

export default connector(SubmitDashboardExampleDialog)
