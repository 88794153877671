import React from 'react'

import { ApplicationState } from 'redux/Stores/types'
import { ConnectedProps, connect } from 'react-redux'

import { SxProps } from '@mui/material'

import Banner from 'components_new/molecules/Banner'

interface BannerBarProps {
  sx?: SxProps
}

const BannerBar = (props: ComponentProps) => {
  const { StatusBannerStore, sx } = props

  if (StatusBannerStore.data.length === 0) return null

  return (
    <>
      {StatusBannerStore.data.map((banner) => {
        const iconName = banner.icon ?? 'InfoOutlined'

        return (
          <Banner
            body={banner.description}
            href={banner.link}
            iconName={iconName}
            key={banner.id}
            linkLabel={banner.link_label}
            severity={banner.severity}
            sx={sx}
            title={banner.title}
          />
        )
      })}
    </>
  )
}

/*-- redux --*/
const mapStateToProps = (state: ApplicationState) => ({
  StatusBannerStore: state.StatusBannerStore
})

const connector = connect(mapStateToProps)

type ComponentProps = ConnectedProps<typeof connector> & BannerBarProps

BannerBar.displayName = 'BannerBar'
export default connector(BannerBar)
