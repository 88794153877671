import React, { useEffect, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { ApplicationState } from 'redux/Stores/types'
import { ConnectedProps, connect } from 'react-redux'

import Alert from 'components_new/atoms/Alert'
import Box from 'components_new/atoms/Box'
import Icon from 'components_new/atoms/Icon'
import Text from 'components_new/atoms/Text'

import OnboardingDialog from 'components_new/molecules/OnboardingDialog'

import * as DashboardExampleActions from 'redux/actions/DashboardExamples'

import { getDashboardImageUrl, getThumbnail } from 'utils/functions'

const NewDashboardFromExampleDialog = (props: ComponentProps) => {
  const [step, setStep] = useState(0)
  const params = useParams<{ id: string }>()
  const history = useHistory()
  const { search } = useLocation()

  const searchParams = new URLSearchParams(search)
  const exampleId = searchParams.get('created_from_example')

  useEffect(() => {
    if (
      !props.DashboardExampleStore.fetched &&
      !props.DashboardExampleStore.fetching
    ) {
      props.tryGetAllDashboardExamples()
    }
  }, [])

  const handleClose = () => {
    history.replace(`/dashboards/${params.id}`)
  }

  const example = exampleId ? props.DashboardExampleStore.data[exampleId] : null

  if (!example) {
    return null
  }

  return (
    <OnboardingDialog
      contextText={getBody(step, example.fields.title)}
      imageAdornment={
        step === 0 ? (
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              bgcolor: 'background.backdrop',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <Icon
              color="success"
              name="CheckCircleOutline"
              sx={{ fontSize: 72 }}
            />
          </Box>
        ) : undefined
      }
      imageSrc={getImageSrc(step, exampleId ?? '')}
      onBack={() => setStep(step - 1)}
      onClose={handleClose}
      onNext={step === 3 ? () => handleClose() : () => setStep(step + 1)}
      open={Boolean(exampleId)}
      title={getTitle(step)}
      steps={3}
      step={step}
    >
      {getHint(step) ? (
        <Alert severity="info">
          <strong>{step === 0 ? 'Tänk på!' : 'Vad kan jag göra?'}</strong>
          <br />
          {getHint(step)}
        </Alert>
      ) : null}
    </OnboardingDialog>
  )
}

function getTitle(step: number) {
  if (step === 0) {
    return <>Grattis! Din dashboard har aktiverats</>
  }

  if (step === 1) {
    return 'Om data saknas'
  }

  if (step === 2) {
    return 'Om värden saknas'
  }

  return 'Om du föredrar någonting annat'
}

function getBody(step: number, dashboardTitle: string) {
  if (step === 0) {
    return (
      <>
        <Text gutterBottom variant="inherit">
          Genom att ha aktiverat ett exempel på en branschbeprövad dashboard har
          det sparats både tid, tankekraft och resurser.
        </Text>
        <Text fontStyle="italic" variant="inherit">
          &quot;Visst är det skönt att slippa återuppfinna hjulet?&quot;
        </Text>
      </>
    )
  }

  if (step === 1) {
    return (
      <>
        Er organisation kanske inte har anslutit all nödvändig data för det
        aktiverade exemplet. I sådana fall visas en tom widget med ett
        felmeddelande.
      </>
    )
  }

  if (step === 2) {
    return (
      <>
        Värden kan bli noll och grafer tomma om <code>{dashboardTitle}</code>{' '}
        använder filtreringar som inte finns i er organisations data.
      </>
    )
  }

  return (
    <>
      Vissa benämningar eller visualiseringar kanske inte överensstämmer med hur
      ni vanligtvis presenterar information.
    </>
  )
}

function getHint(step: number) {
  if (step === 0) {
    return (
      <>
        Innan dashboarden blir användbar kan några mindre justeringar behöva
        göras.
      </>
    )
  }

  if (step === 1) {
    return (
      <>
        Använd vår live-chatt (nere i högra hörnet), så hjälper vi dig direkt
        med att undersöka om datan kan aktiveras.
      </>
    )
  }

  if (step === 2) {
    return (
      <>
        Radera ogiltiga filter i widgetens inställningar och lägg till liknande
        filter som matchar er data.
      </>
    )
  }

  return (
    <>
      Klicka på ikonen för att redigera widgeten. Här kan du (bland mycket
      annat) justera både benämningar och visualiseringar så att de passar er
      verksamhet.
    </>
  )
}

function getImageSrc(step: number, id: string) {
  if (step === 0) {
    return `${getThumbnail(getDashboardImageUrl(id))}`
  }

  if (step === 1) {
    return '/assets/illustrations/no_data.svg'
  }

  if (step === 2) {
    return '/assets/illustrations/zero_values.svg'
  }

  return '/assets/illustrations/edit.svg'
}

/*-- redux --*/
const mapStateToProps = (state: ApplicationState) => ({
  DashboardExampleStore: state.DashboardExampleStore
})

const connector = connect(mapStateToProps, { ...DashboardExampleActions })

type ComponentProps = ConnectedProps<typeof connector>

export default connector(NewDashboardFromExampleDialog)
