import Text from 'components_new/atoms/Text'
import React, { ReactNode } from 'react'

export const welcomeStep: Record<
  number,
  {
    title: ReactNode
    body: ReactNode
    hint: ReactNode | null
    src: string
  }
> = {
  // welcome
  [0]: {
    title: <>Välkommen till Homepal! 🎉</>,
    body: (
      <>
        <Text paragraph={true} variant="inherit">
          Homepal är ett verktyg för att automatisera din uppföljning och
          rapportering med hjälp av färdiga nyckeltal och tillgänglig data.
        </Text>
      </>
    ),
    hint: (
      <>
        Den här guiden tar <b>mindre än 2 minuter</b> och visar dig hur du
        enkelt hittar den information som du är intresserad av.
      </>
    ),
    src: '/assets/media/welcome/welcome.png'
  },
  // dashboards
  [1]: {
    title: <>Dashboards</>,
    body: (
      <>
        <Text paragraph={true} variant="inherit">
          En <code>dashboard</code> är en yta (likt ett ritbräde) där olika
          nyckeltal, mätvärden och annan data visualiseras för att ge dig en
          enkel översikt.
        </Text>
        <Text paragraph={true} variant="inherit">
          De dashboards som du har tillgång till visas direkt i din startvy
          eller ligger fördelade i mappar. Du kan även nå dem via sidomenyn till
          vänster om den är utfälld.
        </Text>
        <Text paragraph={true} variant="inherit">
          <b>Om du inte ser några dashboards</b> bör du kontakta din{' '}
          administratör för att få rättigheter tilldelade.
        </Text>
      </>
    ),
    hint: (
      <>
        För snabbare tillgång till dina viktigaste dashboards kan du markera dem
        som favoriter!
      </>
    ),
    src: '/assets/media/welcome/dashboards.png'
  },
  // tabs
  [2]: {
    title: <>Flikar</>,
    body: (
      <>
        <Text paragraph={true} variant="inherit">
          Varje dashboard kan innehålla flera <code>flikar</code>. Använd dessa
          för att navigera mellan olika sidor i en dashboard.
        </Text>
      </>
    ),
    hint: (
      <>
        En dashboard <u>behöver inte</u> ha flera flikar. Ser du inga flikar är
        det antagligen för att det inte finns några.
      </>
    ),
    src: '/assets/media/welcome/tabs.png'
  },
  // filters
  [3]: {
    title: <>Filter</>,
    body: (
      <>
        <Text paragraph={true} variant="inherit">
          Använd tillgängliga <code>filter</code> i sidomenyn till höger för
          visa det urval som är intressant för dig.
        </Text>
        <Text paragraph={true} variant="inherit">
          Du kan även anpassa vilken tidsperiod som du vill titta närmre på.
          Välj ett standardval så som <code>Hittills det här året</code>,{' '}
          <code>Senaste 90 dagarna</code> eller <code>Igår</code> – eller mata
          in helt valfria datum.
        </Text>
      </>
    ),
    hint: (
      <>
        Om du saknar möjlighet att filtrera på någonting speciellt bör du
        kontakta din administratör. En administratör kan enkelt och snabbt lägga
        till filter.
      </>
    ),
    src: '/assets/media/welcome/filters.png'
  },
  // widgets
  [4]: {
    title: <>Widgets</>,
    body: (
      <>
        <Text paragraph={true} variant="inherit">
          Dashboards är uppbyggda av <code>widgets</code> (små rektanglar) som
          innehåller olika data i någon form av visualisering. I högra hörnet av
          en widget kan du:
        </Text>
        <Text paragraph={true} variant="inherit">
          <ul className="ul">
            <li className="li">
              <b>Förstora</b> och fokusera visualiseringen
            </li>
            <li className="li">
              <b>Visa information</b> om den visualiserade datan
            </li>
            <li className="li">
              <b>Visa innehållet</b> bakom i en tabell
            </li>
            <li className="li">
              <b>Exportera till .xslx</b>
            </li>
          </ul>
        </Text>
      </>
    ),
    hint: (
      <>
        Om du hellre vill arbeta med en widgets data i ett annat verktyg kan du
        enkelt exportera det till .xlsx.
      </>
    ),
    src: '/assets/media/welcome/widgets.png'
  },
  // charts
  [5]: {
    title: <>Grafer</>,
    body: (
      <>
        <Text paragraph={true} variant="inherit">
          I de flesta widgets visas datan i <code>grafer</code>. Klicka på ett
          segment (t.ex. en stapel, siffra eller pajbit) för att:
        </Text>
        <Text paragraph={true} variant="inherit">
          <ul className="ul">
            <li className="li">
              <b>Lägga till segmentet i filter</b> så att dashboarden endast
              visar data kopplat till valt segment
            </li>
            <li className="li">
              <b>Visa innehåll</b> bakom segmentet i en tabell
            </li>
            <li className="li">
              <b>Dela upp</b> segmentet ytterligare för vidare analys
            </li>
          </ul>
        </Text>
      </>
    ),
    hint: (
      <>
        Du kan snabbt analysera ett specifikt område genom att klicka på olika
        grafer och segment.
      </>
    ),
    src: '/assets/media/welcome/charts.png'
  },
  // catalog
  [6]: {
    title: <>Utforska</>,
    body: (
      <>
        <Text paragraph={true} variant="inherit">
          I sidomenyn till vänster (under <code>Utforska</code>) samlar vi våra
          kunders kunskap i en digital katalog med branschens vanligaste
          dashboards, så att ingen ska behöva uppfinna hjulet.
        </Text>
        <Text paragraph={true} variant="inherit">
          <b>Som administratör</b> kan du aktivera en dashboard från branschen
          direkt med ett klick till er Homepal-miljö.
        </Text>
      </>
    ),
    hint: (
      <>
        Har du hittat en intressant dashboard men saknar rättigheter för att
        aktivera den? Kontakta din administratör så kan denne aktivera din
        dashboard!
      </>
    ),
    src: '/assets/media/welcome/catalog.png'
  }
}
