import React, { ReactElement, ReactNode } from 'react'

import { Color } from 'themes'

import Box from 'components_new/atoms/Box'
import Divider from 'components_new/atoms/Divider'
import Icon from 'components_new/atoms/Icon'
import ListItem from 'components_new/atoms/List/ListItem'
import ListItemButton from 'components_new/atoms/List/ListItemButton'
import ListItemIcon from 'components_new/atoms/List/ListItemIcon'
import Text from 'components_new/atoms/Text'
import Tooltip from 'components_new/atoms/Tooltip'

export interface SettingsItemProps {
  actions?: ReactNode
  alignItems?: 'center' | 'flex-end' | 'flex-start'
  body?: string | ReactElement
  children?: ReactNode
  color?: 'error' | 'info' | 'success' | 'warning' | Color | 'brand'
  disabled?: boolean
  disabledText?: ReactNode
  dividerTitle?: ReactNode
  icon?: ReactNode
  indent?: number
  noDivider?: boolean
  onClick?: () => void
  sx?: object
  title?: ReactNode
  value?: null | number | ReactNode | string
  variant?: 'action' | 'add' | 'edit'
}

const SettingsItem = React.forwardRef((props: SettingsItemProps, ref: any) => {
  const {
    actions,
    alignItems,
    body,
    children,
    color,
    disabled,
    disabledText,
    dividerTitle,
    icon,
    indent,
    noDivider,
    onClick,
    sx,
    title,
    value,
    variant
  } = props

  return (
    <Box
      ref={ref}
      component="li"
      sx={{
        '& .MuiDivider-root': {
          marginLeft: icon ? 7 : 2
        },
        '&:last-child': {
          '& .MuiDivider-root': {
            display: 'none'
          }
        },
        ...sx
      }}
    >
      <SettingsItemWrapper
        alignItems={alignItems}
        center={variant === 'action'}
        disabled={disabled}
        disabledText={disabledText}
        onClick={onClick}
      >
        {icon ? (
          <ListItemIcon sx={{ minWidth: 0, mr: 2 }}>{icon}</ListItemIcon>
        ) : null}
        {(title || body) && variant !== 'action' ? (
          <Box sx={{ minWidth: 0, mr: 1, ml: indent }}>
            {title ? (
              <Box
                sx={
                  !Boolean(body)
                    ? { height: 24, display: 'flex', alignItems: 'center' }
                    : undefined
                }
              >
                <Text
                  data-openreplay-obscured
                  component="div"
                  color={
                    color
                      ? `${color}.text`
                      : variant === 'add'
                        ? 'success.text'
                        : 'text.primary'
                  }
                  noWrap={true}
                  sx={{
                    fontWeight: 'medium'
                  }}
                >
                  {title}
                  {variant === 'add' ? '...' : ''}
                </Text>
              </Box>
            ) : null}
            {body ? (
              <Text color={'text.secondary'} component="div">
                {body}
              </Text>
            ) : null}
          </Box>
        ) : null}
        {variant === 'action' ? (
          <Text
            align={'center'}
            component="div"
            color={`${color}.text` ?? 'text.primary'}
            sx={{
              fontWeight: 'medium'
            }}
          >
            {title}
          </Text>
        ) : undefined}
        {(actions || children || onClick || value !== undefined) &&
        variant !== 'action' ? (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                  flex: 1,
                  gap: 0.25,
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  minWidth: 0
                }}
              >
                {value !== undefined ? (
                  <>
                    {value === null || value === '' ? (
                      <Text
                        color="text.disabled"
                        fontStyle="italic"
                        variant="body1"
                      >
                    Inget valt än
                      </Text>
                    ) : typeof value === 'string' || typeof value === 'number' ? (
                      <Text
                        color="text.secondary"
                        variant="body1"
                        sx={{
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          minWidth: 0
                        }}
                      >
                        {value}
                      </Text>
                    ) : (
                      <Box
                        sx={{
                          minWidth: 0
                        }}
                      >
                        {value}
                      </Box>
                    )}
                  </>
                ) : null}
                {actions}
                {children ? (
                  <Box
                    sx={{
                      flex: 1,
                      minWidth: 0
                    }}
                  >
                    {children}
                  </Box>
                ) : null}
              </Box>
            ) : null}
        {onClick && (!variant || variant === 'edit') ? (
          <Icon
            color="disabled"
            fontSize="small"
            name={variant === 'edit' ? 'EditOutlined' : 'KeyboardArrowRight'}
            sx={{ ml: 1 }}
          />
        ) : null}
      </SettingsItemWrapper>
      {noDivider ? null : <Divider textAlign="left">{dividerTitle}</Divider>}
    </Box>
  )
})

const SettingsItemWrapper = (props: {
  alignItems?: 'center' | 'flex-end' | 'flex-start'
  center?: boolean
  children: ReactNode
  disabled?: boolean
  disabledText?: ReactNode
  onClick?: () => void
}) => {
  const { alignItems, center, children, disabled, disabledText, onClick } =
    props

  if (onClick) {
    return (
      <Tooltip
        placement="left"
        title={disabled && disabledText ? disabledText : ''}
      >
        <span style={disabled ? { cursor: 'not-allowed' } : undefined}>
          <ListItemButton
            disabled={disabled}
            onClick={onClick}
            sx={center ? { justifyContent: 'center' } : undefined}
          >
            {children}
          </ListItemButton>
        </span>
      </Tooltip>
    )
  } else {
    return (
      <Tooltip
        placement="left"
        title={disabled && disabledText ? disabledText : ''}
      >
        <span
          style={
            disabled ? { cursor: 'not-allowed', opacity: 0.38 } : undefined
          }
        >
          <ListItem
            component="div"
            sx={{
              alignItems: alignItems ?? 'flex-start',
              pointerEvents: disabled ? 'none' : undefined
            }}
          >
            {children}
          </ListItem>
        </span>
      </Tooltip>
    )
  }
}

SettingsItem.displayName = 'SettingsItem'

export default SettingsItem
