import React from 'react'

import Box from 'components_new/atoms/Box'
import Icon from 'components_new/atoms/Icon'
import IconButton from 'components_new/atoms/IconButton'
import Text from 'components_new/atoms/Text'

import Tooltip from 'components_new/atoms/Tooltip'

import { ApplicationState } from 'redux/Stores/types'
import { ConnectedProps, connect } from 'react-redux'

interface NavigationHeaderProps {
  navLock?: boolean
  title: string
}

const mapStateToProps = (state: ApplicationState) => ({
  NavigationMenuStore: state.NavigationMenuStore
})

const connector = connect(mapStateToProps)

export type ComponentProps = ConnectedProps<typeof connector> &
  NavigationHeaderProps

const NavigationHeader = (props: ComponentProps) => {
  const { NavigationMenuStore, navLock, title } = props

  const open = navLock ?? NavigationMenuStore.open

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
      <Tooltip title={open ? undefined : `Stäng ${title}`}>
        <IconButton goBack={true}>
          <Icon name="ArrowBack" />
        </IconButton>
      </Tooltip>
      {open ? (
        <Text noWrap={true} variant="subtitle1">
          {title}
        </Text>
      ) : null}
    </Box>
  )
}

export default connector(NavigationHeader)
