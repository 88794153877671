import React, { useMemo } from 'react'

import { ThemeProvider } from '@mui/material'
import { getTheme } from 'themes'

import Icon, { IconNames } from 'components_new/atoms/Icon'
import Paper from 'components_new/atoms/Paper'
import Text from 'components_new/atoms/Text'

import { filterViewsByPeriod, TDashboardActivityPeriod } from './utils'
import { TDashboardGroupActivity } from 'types/GlobalDashboardGroup'

interface MetricsProps {
  data: TDashboardGroupActivity
  period: TDashboardActivityPeriod
}

const Metrics = (props: MetricsProps) => {
  const { data, period } = props

  const filteredData = useMemo(() => {
    const { views } = filterViewsByPeriod(data.views, data.created_at, period)

    return {
      numberOfViews: views.length,
      numberOfAccounts: Array.from(new Set(views.map((view) => view.user_id)))
        .length
    }
  }, [period])

  return (
    <>
      <ThemeProvider theme={getTheme('dark')}>
        {[
          {
            iconName: 'VisibilityOutlined',
            title: <>Visningar</>,
            subtitle: <>Totalt antal visningar i dashboarden</>,
            value: filteredData.numberOfViews
          },
          {
            iconName: 'GroupOutlined',
            title: <>Användare</>,
            subtitle: <>Unika användare som någon gång öppnat dashboarden</>,
            value: filteredData.numberOfAccounts
          }
          // For next release:
          // {
          //   iconName: 'EmojiEventsOutlined',
          //   title: <>Ranking</>,
          //   subtitle: (
          //     <>Baserat på antal visningar jämfört med andra dashboards</>
          //   ),
          //   value: 1,
          //   value_addition: ` av ${10}`
          // }
        ].map((metric, i) => (
          <Paper key={i} sx={{ p: 2 }} variant="outlined">
            <Paper
              sx={{
                width: 48,
                height: 48,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                mb: 2
              }}
            >
              <Icon name={metric.iconName as IconNames} />
            </Paper>
            <Text variant="h3">
              {metric.value}
              {/* 
              For next release:
              {metric.value_addition ? (
                <Text color="text.secondary" component="span" variant="inherit">
                  {metric.value_addition}
                </Text>
              ) : null} */}
            </Text>
            <Text variant="subtitle2">{metric.title}</Text>
            <Text color="text.secondary" variant="caption">
              {metric.subtitle}
            </Text>
          </Paper>
        ))}
      </ThemeProvider>
    </>
  )
}

export default Metrics
