import { Dispatch } from 'redux'

import * as UserApi from 'redux/api/DataPlatform/users/v1'
import * as Types from 'redux/Types'

import { PatchSelfBody } from 'types/GlobalUser'

export function tryUpdateSelf(body: PatchSelfBody, showDialog = true) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: Types.UPDATE_SELF
    })

    UserApi.updateSelf(body)
      .then((response) => {
        dispatch({
          type: Types.UPDATE_SELF_SUCCESS,
          payload: response.data.data
        })

        if (showDialog) {
          dispatch({
            type: Types.TOGGLE_SNACKBAR_OPEN,
            payload: { message: 'Dina inställningar har uppdaterats' }
          })
        }
      })
      .catch(() => {
        dispatch({
          type: Types.UPDATE_SELF_FAILED
        })

        if (showDialog) {
          dispatch({
            type: Types.TOGGLE_SNACKBAR_OPEN,
            payload: { message: 'Dina inställningar kunde inte uppdateras' }
          })
        }
      })
  }
}
