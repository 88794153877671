import axios from 'axios'

import { parseAuthenticationHeader } from 'helpers/Functions'
import {
  Entity,
  PutEntityAttribute,
  PutEntityAttributeResponse
} from 'types/GlobalEntities'

const BASE_URL =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:3990/'
    : process.env.REACT_APP_HOMEPAL_MDM_API_PROD

const ENTITY_URL = `${BASE_URL}v1/entities`
const ENTITY_ATTRIBUTES_URL = `${BASE_URL}v1/entity-attributes`

export async function getAll() {
  return axios.get<{ data: Entity[] }>(ENTITY_URL, parseAuthenticationHeader())
}

export async function put(
  entityId: string,
  entityAttributes: PutEntityAttribute[]
) {
  return axios.put<{ data: PutEntityAttributeResponse }>(
    `${ENTITY_ATTRIBUTES_URL}/${entityId}`,
    { data: entityAttributes },
    parseAuthenticationHeader()
  )
}
