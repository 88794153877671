import React from 'react'

import Box from 'components_new/atoms/Box'
import Paper from 'components_new/atoms/Paper'
import Text from 'components_new/atoms/Text'

import SegmentVirtualizedTable, {
  TTableRow
} from 'components_new/molecules/SegmentVirtualizedTable'

import { InviteMessage } from 'types/GlobalInviteMessages'
import { DatasetType } from 'types/GlobalWidget'

interface InviteMessageTemplateProps {
  messages: InviteMessage[]
}

const InviteMessageTemplate = (props: InviteMessageTemplateProps) => {
  const { messages } = props

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <Text variant="h5" sx={{ lineHeight: 2 }}>
        Skickade meddelanden
      </Text>
      <Text sx={{ lineHeight: 2 }}>
        Här kan man se alla meddelanden som användare har valt att skicka med
        när de bjuder in en ny användare.
      </Text>
      <Paper sx={{ p: 2, borderRadius: 1, flex: 1, overflow: 'hidden' }}>
        <SegmentVirtualizedTable
          data={messages.map((message) => {
            const row = {
              /*-- segment --*/
              segment: {
                label: message.invited
              }
            } as TTableRow

            /*-- data --*/
            row['invited_by'] = [message.invited_by]
            row['message'] = [message.message]

            return row
          })}
          loading={false}
          columns={[
            {
              id: 'invited',
              label: 'Inbjuden användare',
              type: DatasetType.STRING
            },
            {
              id: 'invited_by',
              label: 'Inbjuden av',
              type: DatasetType.STRING
            },
            { id: 'message', label: 'Meddelande', type: DatasetType.STRING }
          ]}
        />
      </Paper>
    </Box>
  )
}

export default InviteMessageTemplate
