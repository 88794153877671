import React, { ReactNode } from 'react'

import Box from 'components_new/atoms/Box'
import Divider from 'components_new/atoms/Divider'
import ListItem from 'components_new/atoms/List/ListItem'
import ListItemIcon from 'components_new/atoms/List/ListItemIcon'
import ListItemText from 'components_new/atoms/List/ListItemText'
import Stack from 'components_new/atoms/Stack'
import Text from 'components_new/atoms/Text'

interface SectionHeaderProps {
  actions?: ReactNode
  divider?: boolean
  listItem?: boolean
  size?: 'small' | 'medium' | 'large'
  sx?: object
  title: ReactNode
  titleAdornment?: ReactNode
}

const SectionHeader = React.forwardRef(
  (props: SectionHeaderProps, ref: any) => {
    return props.listItem ? (
      <ListItem divider={props.divider} sx={props.sx}>
        <ListItemText
          primaryTypographyProps={{
            color: 'text.primary',
            variant:
              props.size === 'medium'
                ? 'subtitle1'
                : props.size === 'large'
                  ? 'h6'
                  : 'subtitle2'
          }}
        >
          {props.title}
        </ListItemText>
        {props.actions ? (
          <ListItemIcon position="end">
            <Stack spacing={1}>{props.actions}</Stack>
          </ListItemIcon>
        ) : null}
      </ListItem>
    ) : (
      <Box sx={props.sx} ref={ref}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: props.actions ? 'space-between' : undefined,
            alignItems: 'flex-end',
            mb: 0.5
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
            <Text
              variant={
                props.size === 'medium'
                  ? 'subtitle1'
                  : props.size === 'large'
                    ? 'h6'
                    : 'subtitle2'
              }
              color="text.primary"
            >
              {props.title}
            </Text>
            {props.titleAdornment}
          </Box>
          {props.actions ? (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
              {props.actions}
            </Box>
          ) : null}
        </Box>
        {props.divider ? <Divider /> : null}
      </Box>
    )
  }
)

SectionHeader.displayName = 'SectionHeader'
export default SectionHeader
