import React, { ElementType, ReactNode } from 'react'

import Avatar from 'components_new/atoms/Avatar'
import Box from 'components_new/atoms/Box'
import Icon from 'components_new/atoms/Icon'
import ListItem from 'components_new/atoms/List/ListItem'
import ListItemAvatar from 'components_new/atoms/List/ListItemAvatar'
import ListItemText from 'components_new/atoms/List/ListItemText'

import LogotypeIcon from 'assets/logotypes/LogotypeIcon'
import Badge from 'components_new/atoms/Badge'

interface UserListItemProps {
  actions?: ReactNode
  body?: ReactNode
  component?: ElementType<any>
  customTitle?: ReactNode
  disablePadding?: boolean
  title: string
  titleAdornment?: ReactNode
  size?: 'small' | 'medium' | 'large'
  src?: string
  status?: 'error' | 'info' | 'success' | 'warning'
  sx?: object
  type?: 'customer' | 'user' | 'homepal' | 'role' | 'chart' | 'companygroup'
}

/**
 * UserListItem displays a user (or a company)
 * in a structured way suited for a list, menu
 * or a table.
 */

const UserListItem = React.forwardRef((props: UserListItemProps, ref: any) => {
  const {
    actions,
    component,
    customTitle,
    disablePadding,
    title,
    titleAdornment,
    body,
    type,
    size,
    src,
    status,
    sx
  } = props

  let icon = null
  let avatarBgColor = undefined

  if (type === 'customer') {
    avatarBgColor = 'transparent'
    icon = <Icon color="disabled" name="ApartmentOutlined" />
  } else if (type === 'homepal') {
    avatarBgColor = 'black.main'
    icon = <LogotypeIcon />
  } else if (type === 'chart') {
    avatarBgColor = 'transparent'
    icon = <Icon color="disabled" name="AccountTreeOutlined" />
  } else if (type === 'companygroup') {
    avatarBgColor = 'transparent'
    icon = <Icon color="disabled" name="Business" />
  }

  return (
    <ListItem
      disablePadding={disablePadding}
      component={component ?? undefined}
      dense={size === 'small'}
      ref={ref}
      secondaryAction={actions}
      sx={sx}
    >
      <ListItemAvatar
        sx={{ minWidth: size === 'small' ? 32 : size === 'large' ? 64 : 48 }}
      >
        <Badge variant="dot" invisible={!status} color={status}>
          <Avatar
            data-openreplay-obscured
            bgcolor={avatarBgColor}
            alt={type === 'role' ? '' : title}
            src={src ?? undefined}
            sx={{
              width: size === 'small' ? 18 : size === 'large' ? 48 : 32,
              height: size === 'small' ? 18 : size === 'large' ? 48 : 32,
              fontSize: size === 'small' ? 12 : undefined
            }}
          >
            {icon}
          </Avatar>
        </Badge>
      </ListItemAvatar>
      <ListItemText
        data-openreplay-obscured
        primary={
          <>
            {customTitle ?? title}
            {titleAdornment}
          </>
        }
        primaryTypographyProps={{
          variant:
            size === 'small'
              ? 'body2'
              : size === 'large'
                ? 'subtitle1'
                : undefined,
          fontWeight: 500
        }}
        secondary={body}
        secondaryTypographyProps={{
          component: Box
        }}
      />
    </ListItem>
  )
})

UserListItem.displayName = 'UserListItem'
export default UserListItem
