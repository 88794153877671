import React, { ReactNode } from 'react'

import { ThemeProvider } from '@mui/material'
import { getTheme } from 'themes'

import { CustomColors } from 'types/GlobalCustomization'
import { WidgetType } from 'types/GlobalWidget'

import Box from 'components_new/atoms/Box'
import Button from 'components_new/atoms/Button'
import Collapse from 'components_new/atoms/Collapse'

import SimpleDialog from 'components_new/molecules/SimpleDialog'

interface FullSizeDialogProps {
  banner: ReactNode
  body: ReactNode
  colors?: CustomColors | null
  customSegmentBy?: string | null
  filterBar: ReactNode
  footer: ReactNode
  header: ReactNode
  menu: ReactNode
  onClose: () => void
  open: boolean
  widgetMode: 'dark' | 'light'
  widgetType: WidgetType
}

const FullSizeDialog = (props: FullSizeDialogProps) => {
  const {
    banner,
    body,
    colors,
    customSegmentBy,
    filterBar,
    footer,
    header,
    menu,
    onClose,
    open,
    widgetMode,
    widgetType
  } = props

  return (
    <SimpleDialog
      data-openreplay-obscured
      actions={
        <Button variant="text" onClick={onClose}>
          Stäng
        </Button>
      }
      disableOverflow={true}
      fullWidth={true}
      maxWidth={false}
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          height: '100%'
        }
      }}
      onMouseDown={(event) => event.stopPropagation()}
    >
      <Box
        sx={{
          height: '100%',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
          minWidth: 0
        }}
      >
        {menu}
        <Box
          sx={{
            flex: 0,
            display: 'flex',
            flexDirection: 'column',
            gap: 0.5
          }}
        >
          {banner}
          <Collapse in={!Boolean(customSegmentBy)}>{header}</Collapse>
          {filterBar}
        </Box>
        <ThemeProvider theme={getTheme(widgetMode, colors)}>
          <Box
            sx={{
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
              minWidth: 0,
              minHeight: 0,
              backgroundColor: colors?.widget_background_color
                ? colors?.widget_background_color
                : undefined,
              p: widgetType === WidgetType.IMAGE ? 0 : 2,
              borderRadius: 1
            }}
          >
            {body}
          </Box>
        </ThemeProvider>
        <Box sx={{ flex: 0 }}>{footer}</Box>
      </Box>
    </SimpleDialog>
  )
}

export default FullSizeDialog
