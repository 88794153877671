import React, { MouseEvent, useMemo, useState } from 'react'

import Box from 'components_new/atoms/Box'

import OptionsMenu from 'components_new/molecules/OptionsMenu'

import MainKpiFocusContainer from './MainKpiFocusContainer'
import ListContainer from './ListContainer'

import NoValue from '../../errors/NoValue'

import { WidgetObject, WidgetType } from 'types/GlobalWidget'

import { translateWidgetType } from 'utils/enumTranslator'
import { getKpiOptionsSettings, getOptions, TParsedKpiValues } from './utils'

interface KeyFigureProps {
  editMode: boolean
  setDetailsDialog: (initialId: string | null) => void
  scaleFactor: number
  widget: WidgetObject
}

const KeyFigure = (props: KeyFigureProps) => {
  const { editMode, scaleFactor, setDetailsDialog, widget } = props

  const [onClickMenu, setAnchorEl] = useState<{
    anchorEl: HTMLElement
    option: TParsedKpiValues
  } | null>(null)

  const handleOpenMenu = (
    event: MouseEvent<HTMLElement>,
    option: TParsedKpiValues
  ) => {
    event.stopPropagation()
    setAnchorEl({ anchorEl: event.currentTarget, option })
  }

  const handleMenuClose = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    setAnchorEl(null)
  }

  if (widget.data && widget.data.values && widget.data.values.length > 0) {
    const data = useMemo(() => {
      return getKpiOptionsSettings(widget)
    }, [widget])

    return (
      <Box
        sx={{
          position: 'relative',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          minWidth: 0,
          fontFamily: 'IBM Plex Sans', // 'Dm Sans'?
          color: 'text.primary'
        }}
      >
        <MainKpiFocusContainer
          data={data}
          editMode={editMode}
          widget={widget}
          onClick={handleOpenMenu}
          scaleFactor={scaleFactor}
        />
        <ListContainer
          data={data}
          editMode={editMode}
          widget={widget}
          scaleFactor={scaleFactor}
          onClick={handleOpenMenu}
        />
        <OptionsMenu
          anchorEl={onClickMenu?.anchorEl}
          onClose={handleMenuClose}
          open={Boolean(onClickMenu)}
          options={getOptions(setDetailsDialog, onClickMenu?.option)}
          title={onClickMenu?.option.title}
        />
      </Box>
    )
  }

  return (
    <NoValue
      embedded={false}
      iconName={translateWidgetType[WidgetType.KEY_FIGURE].iconName}
      scaleFactor={scaleFactor}
    />
  )
}

export default KeyFigure
