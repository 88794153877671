import { Dispatch } from 'redux'

import * as Types from 'redux/Types'
import { getAll, create } from 'redux/api/internal/Emails'
import { CreateEmailBody } from 'types/GlobalEmails'

export function tryGetEmails() {
  return (dispatch: Dispatch) => {
    dispatch({
      type: Types.GET_ALL_EMAILS
    })

    getAll()
      .then((response) => {
        dispatch({
          type: Types.GET_ALL_EMAILS_SUCCESS,
          payload: response.data
        })
      })
      .catch(() => {
        dispatch({
          type: Types.GET_ALL_EMAILS_FAILED
        })
      })
  }
}

export function tryCreateEmail(
  body: CreateEmailBody,
  callback?: (err?: boolean) => void
) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: Types.CREATE_EMAIL
    })

    create(body)
      .then((response) => {
        dispatch({
          type: Types.CREATE_EMAIL_SUCCESS,
          payload: response.data
        })

        dispatch({
          type: Types.TOGGLE_SNACKBAR_OPEN,
          payload: { message: 'Email skickat!' }
        })

        if (callback) {
          callback()
        }
      })
      .catch(() => {
        dispatch({
          type: Types.CREATE_EMAIL_FAILED
        })

        dispatch({
          type: Types.TOGGLE_SNACKBAR_OPEN,
          payload: { message: 'Email kunde inte skickas' }
        })

        if (callback) {
          callback(true)
        }
      })
  }
}
