import { cloneDeep } from 'lodash'

import { KpiOptionObject, UnitPrefix } from 'types/GlobalKpiOption'
import { TKPIValue, WidgetData, WidgetObject } from 'types/GlobalWidget'

import { formatValue, getUnitLabel } from '../utils'
import { IconNames } from 'components_new/atoms/Icon'
import { DETAILS_DISABLE_TEXT } from 'utils/constants'

export const hasValue = (value: number | null) => {
  return !!value || value === 0
}

export const getPercentage = (value: number, comparativeValue: number) => {
  const difference = getDifference(value, comparativeValue)

  return (difference / Math.abs(comparativeValue)) * 100
}

export const getColor = (difference: number, increaseIsPositive: boolean) => {
  if (difference === 0) {
    return 'warning'
  }

  return (increaseIsPositive && difference >= 0) ||
    (!increaseIsPositive && difference <= 0)
    ? 'success'
    : 'error'
}

export const getDifference = (value: number, comparativeValue: number) => {
  return value - comparativeValue
}

export const getStatus = (
  value: number | null,
  upperThresholdValue: number | null = null,
  lowerThresholdValue: number | null,
  increaseIsPositive: boolean
) => {
  if (upperThresholdValue === null || value == null) {
    return null
  }

  const success = increaseIsPositive
    ? value >= upperThresholdValue
    : value <= upperThresholdValue

  if (success) {
    return 'success'
  }

  const warning =
    lowerThresholdValue !== null &&
    (increaseIsPositive
      ? value > lowerThresholdValue
      : value < lowerThresholdValue)

  if (warning) {
    return 'warning'
  }

  return 'error'
}

export function getKpiOptionsSettings(widget: WidgetObject) {
  if (!widget.data) {
    return []
  }

  const indices = (widget.data as WidgetData).values.map((value) => value.index)
  const sortedOptions = cloneDeep(widget.settings.kpi_options).filter(
    (option) => indices.includes(option.index)
  )

  sortedOptions.sort((a, b) => a.index - b.index)

  if (widget.data && widget.data?.values && widget.data?.values.length > 0) {
    return sortedOptions.map((option) => {
      const value = (widget.data as WidgetData).values.find(
        (item) => item.kpi_option_id === option.id
      )

      return parseKpiOption(option, value as TKPIValue)
    })
  }

  return []
}

function parseKpiOption(
  option: KpiOptionObject,
  values: TKPIValue
): TParsedKpiValues {
  const increaseIsPositive = option.increase_is_positive
  const numberOfDecimals = option.number_of_decimals || 0
  const unitPrefixSelected = option.unit_prefix.selected || null
  const equivalentFactor = option.equivalent.factor
  const value = values.value || 0
  const comparativeValue = values.comparative_value || 0

  const difference = getDifference(value, comparativeValue)
  const percentage = getPercentage(value, comparativeValue)

  const formattedValue = formatValue(
    values.value,
    unitPrefixSelected,
    numberOfDecimals,
    false,
    equivalentFactor
  ) as number | null

  return {
    id: option.id,
    comparativeValue,
    increaseIsPositive,
    numberOfDecimals,
    unitPrefixSelected,
    equivalentFactor,
    difference,
    percentage,
    value: formatValue(
      values.value,
      unitPrefixSelected,
      numberOfDecimals,
      true,
      equivalentFactor
    ),
    status: getStatus(
      formattedValue,
      option.upper_bound_threshold ?? null,
      option.lower_bound_threshold ?? null,
      increaseIsPositive
    ),
    unit: getUnitLabel(
      option.unit || null,
      option.unit_prefix,
      option.equivalent.unit
    ),
    title: option.title,
    canShowDetails: option.can_show_details
  }
}

export type TParsedKpiValues = {
  id: string
  comparativeValue: number
  increaseIsPositive: boolean
  numberOfDecimals: number
  unitPrefixSelected: UnitPrefix | null
  equivalentFactor: number | null
  difference: number
  percentage: number
  value: string | number | null
  status: string | null
  unit: string
  title: string
  canShowDetails: boolean
}

export function getOptions(
  setDetailsDialog: (initialId: string | null) => void,
  option?: TParsedKpiValues
) {
  if (option) {
    const isPublic =
      location.pathname.includes('public') ||
      location.pathname.includes('api-access')

    let infoTip: string | undefined = undefined

    if (isPublic) {
      infoTip =
        'Logga in i Homepal för att få tillgång till den här funktionaliteten'
    } else if (!option.canShowDetails) {
      infoTip = DETAILS_DISABLE_TEXT
    }

    return [
      {
        disabled: isPublic || !option.canShowDetails,
        infoTip,
        iconName: 'TableRowsOutlined' as IconNames,
        onClick: () => setDetailsDialog(option.id),
        title: 'Visa innehåll'
      }
    ]
  }

  return []
}
