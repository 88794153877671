import {
  TableColumn,
  TTableRow
} from 'components_new/molecules/SegmentVirtualizedTable'

import {
  DatasetDataValue,
  DatasetType,
  IUnderlyingDataResponse
} from 'types/GlobalWidget'

import { Order, Sort } from 'utils/types'
import {
  sortAlphabeticalAsc,
  sortAlphabeticalDesc,
  sortNumericAsc,
  sortNumericDesc
} from 'utils/sortHelper'
import { TColumn } from './EditColumnsDialog'
import { parseDatasetValue, isNumberDatasetType } from 'utils/functions'

export const SEGMENT_COL = 'segment'

export const INITIAL_SORT = {
  attribute: SEGMENT_COL,
  direction: 'asc' as Order
}

export type TKpiOptionColumns = {
  [kpiOptionId: string]: {
    [columnId: string]: TColumn
  }
}

export type ParsedData = {
  columns: TableColumn[]
  rows: TTableRow[]
}

export function getParsedData(raw: IUnderlyingDataResponse): ParsedData {
  const columns: TableColumn[] = [
    {
      id: SEGMENT_COL,
      label: raw.labels.label,
      type: DatasetType.STRING,
      hidden: raw.labels.hidden
    },
    ...raw.data.map((col) => ({
      id: col.entity_column_id ?? (col.kpi_option_id as string),
      label: col.unit ? `${col.label} (${col.unit})` : col.label,
      type: col.type
    }))
  ]

  const rows: TTableRow[] = raw.labels.data.map((_, i) => {
    const row = {
      /*-- segment --*/
      segment: {
        label: raw.labels.data[i].label,
        link: raw.external_links[i]
      }
    } as TTableRow

    raw.data.forEach((col) => {
      if (col.entity_column_id) {
        row[col.entity_column_id] = col.data[i] as string[] | number[]
      } else if (col.kpi_option_id) {
        // parse kpi to use max 2 decimals
        const value = col.data[i] as number | null

        row[col.kpi_option_id] =
          value !== null ? parseFloat(value.toFixed(2)) : null
      }
    })

    return row
  })

  return { columns, rows }
}

export function getExportableData(input: ParsedData) {
  const headers = input.columns
    .filter((col) => !col.hidden)
    .map((col) => col.label)
  const rows = input.rows.map((row) => {
    const outputRow: { [key: string]: string | number | null } = {}

    Object.entries(row).forEach(([key, value]) => {
      const column = input.columns.find((col) => col.id === key)

      if (column && !column.hidden) {
        if (key === SEGMENT_COL) {
          outputRow[column.label] = (value as { label: string }).label

          return
        }

        const dataValue = parseDatasetValue(
          value as DatasetDataValue,
          column.type
        )

        if (isNumberDatasetType(column.type)) {
          outputRow[column.label] = dataValue as number | null
        } else {
          outputRow[column.label] = (dataValue as string[]).join(',')
        }
      }
    })

    return outputRow
  })

  return { headers, rows }
}

// Safe fetch of selected KPI from the data object.
export function getSelectedSortedParsedData(
  data: {
    [key: string]: {
      raw: IUnderlyingDataResponse
      parsed: ParsedData
      sort: Sort
    }
  },
  selected: string | null
): ParsedData & { sort: Sort } {
  if (!selected || !data[selected]) {
    return { columns: [], rows: [], sort: INITIAL_SORT }
  }

  const rows = [...data[selected].parsed.rows]
  const sort = data[selected].sort
  const sortingCol = data[selected].parsed.columns.find(
    (col) => col.id === sort.attribute
  )

  if (sortingCol) {
    rows.sort((a, b) => {
      const aValue = parseDatasetValue(a[sort.attribute], sortingCol.type)
      const bValue = parseDatasetValue(b[sort.attribute], sortingCol.type)

      if (isNumberDatasetType(sortingCol.type)) {
        const aNumber = aValue as number | null
        const bNumber = bValue as number | null

        if (sort.direction === 'asc') {
          return sortNumericAsc(aNumber, bNumber)
        }

        return sortNumericDesc(aNumber, bNumber)
      } else {
        const firstValue =
          sort.attribute === SEGMENT_COL
            ? a[SEGMENT_COL].label
            : (aValue as string[]).join(',')

        const secondValue =
          sort.attribute === SEGMENT_COL
            ? b[SEGMENT_COL].label
            : (bValue as string[]).join(',')

        if (sort.direction === 'asc') {
          return sortAlphabeticalAsc(firstValue, secondValue)
        }

        return sortAlphabeticalDesc(firstValue, secondValue)
      }
    })
  }

  return {
    columns: data[selected].parsed.columns,
    rows,
    sort
  }
}
