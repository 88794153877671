import _ from 'lodash'

import * as Types from 'redux/Types'
import * as Functions from 'helpers/Functions'

const INITIAL_STATE = {
  data: {},
  fetched: false,
  creating: false,
  creatingSFTP: false,
  updating: false,
  deleting: false,
  error: false,
  count: 0
}

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action
  let indexOf, source

  switch (type) {
  case Types.GET_ALL_SOURCES_SUCCESS:
    return {
      ...state,
      data: {
        ...Functions.arrayToObject(payload.data)
      },
      fetched: true,
      count: payload.data.length
    }

  case Types.GET_ONE_SOURCE_SUCCESS:
    return {
      ...state,
      data: {
        ...state.data,
        [payload.data.id]: parseOneActiveSource(payload, state)
      }
    }

  case Types.CREATE_SFTP_SETTINGS:
    return {
      ...state,
      creatingSFTP: true
    }

  case Types.CREATE_SFTP_SETTINGS_SUCCESS:
    return {
      ...state,
      data: {
        ...state.data,
        [payload.data.id]: parseOneActiveSource(payload, state)
      },
      creatingSFTP: false
    }

  case Types.CREATE_SFTP_SETTINGS_FAILED:
    return {
      ...state,
      creatingSFTP: false
    }

  case Types.CREATE_ACTIVE_SOURCE:
    return {
      ...state,
      creating: true
    }

  case Types.CREATE_ACTIVE_SOURCE_SUCCESS:
    return {
      ...state,
      data: {
        ...state.data,
        [payload.data.id]: parseOneActiveSource(payload, state)
      },
      creating: false
    }

  case Types.UPLOAD_FILE_ACTIVE_SOURCE:
    return {
      ...state,
      error: false,
      creating: true
    }

  case Types.UPLOAD_FILE_ACTIVE_SOURCE_SUCCESS:
    return {
      ...state,
      error: false,
      creating: false,
      data: {
        ...state.data,
        [payload.data.id]: parseOneActiveSource(payload, state)
      }
    }

  case Types.RESET_PASSWORD_SUCCESS:
    return {
      ...state,
      data: {
        ...state.data,
        [payload.data.id]: parseOneActiveSource(payload, state)
      }
    }

  case Types.UPLOAD_FILE_ACTIVE_SOURCE_FAILED:
    return {
      ...state,
      error: true,
      creating: false
    }

  case Types.UPDATE_ACTIVE_SOURCE:
    return {
      ...state,
      error: false,
      updating: true
    }

  case Types.UPDATE_ACTIVE_SOURCE_SUCCESS:
    return {
      ...state,
      data: {
        ...state.data,
        [payload.data.id]: parseOneActiveSource(payload, state)
      },
      error: false,
      updating: false
    }

  case Types.UPDATE_SOURCE_TABLE_SUCCESS:
    return {
      ...state,
      data: {
        ...state.data,
        [payload.data.id]: parseOneActiveSource(payload, state)
      }
    }

  case Types.PUT_COLUMNS_AND_FILTERS_SUCCESS:
    return {
      ...state,
      data: {
        ...state.data,
        [payload.data.id]: parseOneActiveSource(payload, state)
      }
    }

  case Types.CREATE_SOURCE_TABLE:
    return {
      ...state,
      creating: true
    }

  case Types.CREATE_SOURCE_TABLE_SUCCESS:
    return {
      ...state,
      data: {
        ...state.data,
        [payload.data.id]: parseOneActiveSource(payload, state)
      },
      creating: false
    }

  case Types.CREATE_SOURCE_TABLE_FAILED:
    return {
      ...state,
      creating: false
    }

  case Types.PUT_STREAM_VARIABLES:
    return {
      ...state,
      updating: true
    }

  case Types.PUT_STREAM_VARIABLES_SUCCESS:
    return {
      ...state,
      data: {
        ...state.data,
        [payload.data.id]: parseOneActiveSource(payload, state)
      },
      updating: false
    }

  case Types.PUT_STREAM_VARIABLES_FAILED:
    return {
      ...state,
      updating: false
    }

  case Types.CREATE_STREAM_TABLE:
    return {
      ...state,
      updating: true
    }

  case Types.CREATE_STREAM_TABLE_SUCCESS:
    return {
      ...state,
      data: {
        ...state.data,
        [payload.data.id]: parseOneActiveSource(payload, state)
      },
      updating: false
    }

  case Types.CREATE_STREAM_TABLE_FAILED:
    return {
      ...state,
      updating: false
    }

  case Types.DELETE_STREAM_TABLE:
    return {
      ...state,
      updating: true
    }

  case Types.DELETE_STREAM_TABLE_SUCCESS:
    return {
      ...state,
      data: {
        ...state.data,
        [payload.data.id]: parseOneActiveSource(payload, state)
      },
      updating: false
    }

  case Types.DELETE_STREAM_TABLE_FAILED:
    return {
      ...state,
      updating: false
    }

  case Types.UPDATE_ACTIVE_SOURCE_FAILED:
    return {
      ...state,
      error: true,
      updating: false
    }

  case Types.DELETE_ACTIVE_SOURCE:
    return {
      ...state,
      deleting: true
    }

  case Types.DELETE_ACTIVE_SOURCE_SUCCESS:
    // remove active source from list
    source = _.cloneDeep(state.data[payload.sourceId])
    indexOf = source.included.active_sources.indexOf(
      (item) => item.id === payload.id
    )
    source.included.active_sources.splice(indexOf, 1)

    return {
      ...state,
      data: {
        ...state.data,
        [payload.sourceId]: source
      },
      deleting: false
    }

  case Types.PUT_AUTH_PARAM_VALUES:
    return { ...state, creating: true }

  case Types.PUT_AUTH_PARAM_VALUES_SUCCESS:
    return { ...state, creating: false, error: false }

  case Types.PUT_AUTH_PARAM_VALUES_FAILED:
    return { ...state, creating: false, error: true }

  case Types.UPLOAD_FILE_SUCCESS:
    return state

  case Types.SIGN_OUT:
    return {
      ...INITIAL_STATE
    }

  default:
    return state
  }
}

const parseOneActiveSource = (payload, state) => {
  return {
    ...payload.data,
    included: {
      ...(state.data[payload.data.id]
        ? {
            ...state.data[payload.data.id].included,
            active_sources: parseActiveSourcesGetOne(
              state.data[payload.data.id].included.active_sources,
              payload.data.included.active_sources,
              payload.activeSourceId
            ),
            endpoints: payload.data.included.endpoints
          }
        : payload.data.included)
    }
  }
}

const parseActiveSourcesGetOne = (
  currentActiveSources = [],
  includedActiveSources = [],
  getOneId
) => {
  return [
    ...currentActiveSources.filter((as) => as.id !== getOneId),
    ...includedActiveSources.filter((as) => as.id === getOneId)
  ]
}
