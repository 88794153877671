import React, { useEffect, useState } from 'react'

import { bindActionCreators, Dispatch } from 'redux'
import { connect, ConnectedProps } from 'react-redux'
import { ApplicationState } from 'redux/Stores/types'

import * as EntityActions from 'redux/actions/Entities'

import Box from 'components_new/atoms/Box'

import SettingsGroup from 'components_new/molecules/SettingsGroup'
import SettingsItem from 'components_new/molecules/SettingsItem'

import EditEntityDialog from './EditEntityDialog'

import Text from 'components_new/atoms/Text'

/*-- redux --*/
const mapStateToProps = (state: ApplicationState) => ({
  EntityStore: state.EntityStore
})

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(EntityActions, dispatch)
}

const connector = connect(mapStateToProps, mapDispatchToProps)

interface EntityColumnSettingsProps {
  sx?: object
}
type ComponentProps = ConnectedProps<typeof connector> &
  EntityColumnSettingsProps

const EntityColumnSettings = (props: ComponentProps) => {
  const { EntityStore, sx, tryGetAllEntities } = props

  const [dialog, setDialog] = useState<{
    open: boolean
    entityId: string | null
  }>({ open: false, entityId: null })

  useEffect(() => {
    if (!EntityStore.fetched && !EntityStore.fetching) {
      tryGetAllEntities()
    }
  }, [])

  const handleClose = () => {
    setDialog({
      open: false,
      entityId: null
    })
  }

  const handleOpen = (entityId: string) => {
    setDialog({
      open: true,
      entityId: entityId
    })
  }

  return (
    <>
      <Box sx={{ maxWidth: 'content', ...sx }}>
        <Box sx={{ mb: 2 }}>
          <Text color="text.secondary" variant="body2">
            Anpassa den information som visas då poster av nedanstående typer
            öppnas upp i dashboards.
          </Text>
          <Text color="text.secondary" variant="body2">
            Det här sätter endast utgångsläget. En användare kan själv
            tillfälligt lägga till eller ta bort kolumner när den visar poster
            av en viss typ i en dashboard.
          </Text>
        </Box>
        <SettingsGroup title="Poster">
          {EntityStore.fetched
            ? Object.values(EntityStore.data)
              .sort((a, b) => a.name.localeCompare(b.name, 'sv'))
              .map((entity) => (
                <SettingsItem
                  key={entity.id}
                  onClick={() => handleOpen(entity.id)}
                  title={entity.name}
                  value={entity.customized ? 'Anpassad' : 'Auto'}
                  variant="edit"
                />
              ))
            : null}
        </SettingsGroup>
      </Box>

      {/*-- dialogs --*/}

      {dialog.entityId && EntityStore.data[dialog.entityId] ? (
        <EditEntityDialog
          entity={EntityStore.data[dialog.entityId]}
          onClose={handleClose}
          open={dialog.open}
        />
      ) : null}
    </>
  )
}

export default connector(EntityColumnSettings)
