import { getAll, put } from 'redux/api/Entities'
import * as Types from 'redux/Types'

import { Dispatch } from 'redux'
import { PutEntityAttribute } from 'types/GlobalEntities'

export function tryGetAllEntities() {
  return (dispatch: Dispatch) => {
    dispatch({
      type: Types.GET_ALL_ENTITIES
    })

    getAll()
      .then((response) => {
        dispatch({
          type: Types.GET_ALL_ENTITIES_SUCCESS,
          payload: response.data
        })
      })
      .catch(() => {
        dispatch({
          type: Types.GET_ALL_ENTITIES_FAILED
        })
      })
  }
}

export function tryPutEntityAttributes(
  entityId: string,
  entityAttributes: PutEntityAttribute[]
) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: Types.PUT_ENTITY_ATTRIBUTES
    })

    put(entityId, entityAttributes)
      .then((response) => {
        dispatch({
          type: Types.PUT_ENTITY_ATTRIBUTES_SUCCESS,
          payload: response.data
        })

        dispatch({
          type: Types.TOGGLE_SNACKBAR_OPEN,
          payload: { message: 'Post har uppdaterats' }
        })
      })
      .catch(() => {
        dispatch({
          type: Types.PUT_ENTITY_ATTRIBUTES_FAILED
        })

        dispatch({
          type: Types.TOGGLE_SNACKBAR_OPEN,
          payload: { message: 'Post kunde inte uppdateras' }
        })
      })
  }
}
