import React, { useState } from 'react'

import Alert from 'components_new/atoms/Alert'

import OnboardingDialog from 'components_new/molecules/OnboardingDialog'

import { welcomeStep } from './utils'

interface NewUserDialogProps {
  onClose: () => void
  open: boolean
}

const WelcomDialog = (props: NewUserDialogProps) => {
  const { onClose, open } = props

  const [step, setStep] = useState(0)

  const N_STEPS = 6

  return (
    <OnboardingDialog
      contextText={welcomeStep[step].body}
      disableCloseOnBackdropClick={true}
      imageSrc={welcomeStep[step].src}
      onBack={() => setStep(step - 1)}
      onClose={onClose}
      onNext={step === N_STEPS ? () => onClose() : () => setStep(step + 1)}
      open={open}
      title={welcomeStep[step].title}
      steps={N_STEPS}
      step={step}
    >
      {welcomeStep[step].hint ? (
        <Alert severity="info">
          {step !== 0 ? (
            <>
              <strong>Tips!</strong>
              <br />
            </>
          ) : null}
          {welcomeStep[step].hint}
        </Alert>
      ) : null}
    </OnboardingDialog>
  )
}

export default WelcomDialog
