import { Dispatch } from 'redux'

import * as Types from 'redux/Types'

import {
  addVisit,
  getDashboardExamples,
  copy
} from 'redux/api/DashboardExamples'

export function tryGetAllDashboardExamples() {
  return (dispatch: Dispatch) => {
    dispatch({
      type: Types.GET_DASHBOARD_EXAMPLES
    })

    getDashboardExamples()
      .then((response) => {
        dispatch({
          type: Types.GET_DASHBOARD_EXAMPLES_SUCCESS,
          payload: response.data
        })
      })
      .catch(() => {
        dispatch({
          type: Types.GET_DASHBOARD_EXAMPLES_FAILED
        })
      })
  }
}

export function tryAddDashboardExampleVisit(dashboardId: string) {
  return () => {
    addVisit({ data: { dashboard_id: dashboardId } }).catch(() => {
      // do nothing
    })
  }
}

export function tryCopyDashboardExample(
  dashboardId: string,
  folderId: null | string,
  title: string,
  callback: (id: string | null, err?: boolean) => void
) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: Types.CREATE_DASHBOARD
    })

    copy(dashboardId, { data: { folderId, title } })
      .then((response) => {
        dispatch({
          type: Types.CREATE_DASHBOARD_SUCCESS,
          payload: response.data
        })

        dispatch({
          type: Types.TOGGLE_SNACKBAR_OPEN,
          payload: {
            message: 'Dashboard aktiverad'
          }
        })

        callback(response.data.data.group.dashboards[0])
      })
      .catch(() => {
        dispatch({
          type: Types.CREATE_DASHBOARD_FAILED
        })

        dispatch({
          type: Types.TOGGLE_SNACKBAR_OPEN,
          payload: {
            message: 'Dashboard kunde inte aktiveras'
          }
        })

        callback(null, true)
      })
  }
}
