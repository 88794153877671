import React from 'react'

import { useParams } from 'react-router-dom'
import { ThemeProvider } from '@mui/material'
import { getTheme } from 'themes'

import { Dispatch, bindActionCreators } from 'redux'
import { ConnectedProps, connect } from 'react-redux'

import { ApplicationState } from 'redux/Stores/types'
import { DashboardStatus } from 'redux/reducers/Dashboards'

import * as DashboardGroupActions from 'redux/actions/DashboardGroups'

import Box from 'components_new/atoms/Box'
import Button from 'components_new/atoms/Button'
import Collapse from 'components_new/atoms/Collapse'
import Text from 'components_new/atoms/Text'

interface PublishBarProps {
  editMode: boolean
  sx?: object
}

const PublishBar = (props: ComponentProps) => {
  const {
    AuthStore,
    editMode,
    KPIDashboardStore,
    sx,
    tryUpdateDashboardGroup
  } = props

  const params = useParams<{ id: string }>()
  const dashboard = KPIDashboardStore.data[params.id]
  const group = KPIDashboardStore.groups[dashboard?.dashboard_group_id]

  if (!AuthStore.user?.is_homepal_user) return null

  // loading
  if (!KPIDashboardStore.fetched || !Boolean(dashboard) || !Boolean(group)) {
    return null
  }

  // success
  return (
    <Collapse in={group?.status === DashboardStatus.DRAFT && !editMode}>
      <ThemeProvider theme={getTheme('dark')}>
        <Box
          sx={{
            bgcolor: 'info.main',
            py: 0.5,
            px: 2,
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            ...sx
          }}
        >
          <Text color="text.primary">
            <b>Opublicerad.</b> Den här dashboarden visas inte för användare.
          </Text>
          <Button
            onClick={() => {
              tryUpdateDashboardGroup(group?.id, {
                data: { status: DashboardStatus.PUBLISHED }
              })
            }}
            size="small"
            variant="outlined"
          >
            Publicera
          </Button>
        </Box>
      </ThemeProvider>
    </Collapse>
  )
}

/*-- redux --*/
const mapStateToProps = (state: ApplicationState) => ({
  AuthStore: state.AuthStore,
  KPIDashboardStore: state.KPIDashboardStore
})

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      ...DashboardGroupActions
    },
    dispatch
  )
}

const connector = connect(mapStateToProps, mapDispatchToProps)

type ComponentProps = ConnectedProps<typeof connector> & PublishBarProps

export default connector(PublishBar)
