import React from 'react'

import Icon, { IconNames } from 'components_new/atoms/Icon'
import ListItemButton from 'components_new/atoms/List/ListItemButton'
import ListItemIcon from 'components_new/atoms/List/ListItemIcon'
import ListItemText from 'components_new/atoms/List/ListItemText'

interface LocationItemProps {
  iconName?: IconNames
  indent?: number
  onClick: () => void
  selected: boolean
  sx?: object
  title?: string
  tooltip?: string | null
}

const LocationItem = (props: LocationItemProps) => {
  const { iconName, indent, onClick, selected, sx, title } = props

  return (
    <ListItemButton
      dense={true}
      onClick={onClick}
      selected={selected}
      sx={{
        height: 32,
        borderRadius: 16,
        '&.Mui-selected': {
          bgcolor: 'brand.transparent',
          '&:hover': {
            bgcolor: 'brand.transparent'
          }
        },
        '&:hover': {
          bgcolor: undefined,
          color: 'brand.text'
        },
        pl: indent,
        py: 0.5,
        ...sx
      }}
    >
      {iconName ? (
        <ListItemIcon sx={{ minWidth: 36 }}>
          <Icon color={selected ? 'brand' : undefined} name={iconName} />
        </ListItemIcon>
      ) : null}
      <ListItemText
        primaryTypographyProps={{
          color: selected ? 'brand.text' : undefined,
          variant: 'subtitle2'
        }}
      >
        {title}
      </ListItemText>
    </ListItemButton>
  )
}

export default LocationItem
