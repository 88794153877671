import React, { useEffect } from 'react'

import { connect, ConnectedProps } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'

import { ApplicationState } from 'redux/Stores/types'

import EmailTemplate from 'components_new/templates/internal/Emails'

import * as EmailActions from 'redux/actions/internal/Emails'
import * as TrackingActions from 'redux/actions/Tracking'

import InternalTemplate from 'components_new/templates/InternalTemplate'

const mapStateToProps = (state: ApplicationState) => ({
  EmailStore: state.EmailStore,
  TrackingStore: state.TrackingStore
})

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators({ ...EmailActions, ...TrackingActions }, dispatch)
}

const connector = connect(mapStateToProps, mapDispatchToProps)

// If more props should be used, extend prop with connectedProps
export type EmailPageProps = ConnectedProps<typeof connector>

const EmailPage = (props: EmailPageProps) => {
  const { EmailStore, TrackingStore } = props

  useEffect(() => {
    if (!EmailStore.fetched) {
      props.tryGetEmails()
    }

    if (!TrackingStore.usersUsageFeteched) {
      props.tryGetUsersUsage()
    }
  }, [])

  return (
    <InternalTemplate>
      <EmailTemplate
        emails={EmailStore.data}
        users={TrackingStore.usersUsage}
        createEmail={props.tryCreateEmail}
      />
    </InternalTemplate>
  )
}

export default connector(EmailPage)
