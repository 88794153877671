import React, { useMemo, useState } from 'react'

import Box from 'components_new/atoms/Box'
import Chip from 'components_new/atoms/Chip'
import Collapse from 'components_new/atoms/Collapse'
import Icon from 'components_new/atoms/Icon'
import IconButton from 'components_new/atoms/IconButton'
import Link from 'components_new/atoms/Link'
import TableRow from 'components_new/atoms/Table/TableRow'
import TableCell from 'components_new/atoms/Table/TableCell'
import Tag from 'components_new/atoms/Tag'
import Text from 'components_new/atoms/Text'

import ButtonField from 'components_new/molecules/ButtonField'

import VariablesAccountsSection from './'
import { KpiVariableOption } from 'types/GlobalCompanyGroups'
import { EconomyAccountMap } from '../account_dialogs/utils'
import { KpiVariable } from 'types/GlobalKpiVariable'

interface RowProps {
  accountsMap: EconomyAccountMap
  allVariables: KpiVariable[]
  economySetId: string
  editVariable: {
    economySetId: string
    variableId: string
  } | null
  kpiVariableOptions: KpiVariableOption[]
  level: number
  onEdit: (economySetId: string) => void
  onUpdateVariableAccounts: (
    accounts: string[],
    economySetId: string,
    variableId: string,
    callback: (err?: boolean) => void
  ) => void
  setEditVariable: (
    body: {
      economySetId: string
      variableId: string
      selectedAccounts: string[]
      limitToAccounts?: string[]
    } | null
  ) => void
  variable: KpiVariable
}

const N_LIMIT = 12

const Row = (props: RowProps) => {
  const {
    accountsMap,
    allVariables,
    economySetId,
    editVariable,
    kpiVariableOptions,
    level,
    onEdit,
    onUpdateVariableAccounts,
    setEditVariable,
    variable
  } = props

  const [open, setOpen] = useState(false)

  const accounts = useMemo(() => {
    const accountsForVariable = kpiVariableOptions.find(
      (option) =>
        option.economy_set_id === economySetId &&
        option.kpi_variable_id === variable.id
    )

    return accountsForVariable?.selected || []
  }, [kpiVariableOptions, economySetId, variable])

  // const accounts = variable
  const N_ACCOUNTS = accounts.length

  const children = useMemo(
    () => allVariables.filter((v) => v.parent_kpi_variable_id === variable.id),
    [allVariables]
  )

  return (
    <>
      <TableRow
        hover={true}
        noBorder={true}
        sx={{
          '&:hover .edit-btn': {
            display: 'block'
          }
        }}
      >
        <TableCell>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 1,
              pl: level * 4
            }}
          >
            <Box sx={{ width: 30 }}>
              {level < 2 ? (
                <IconButton
                  disabled={children.length === 0}
                  size="small"
                  onClick={() => setOpen(!open)}
                >
                  {open ? (
                    <Icon fontSize="small" name="KeyboardArrowUp" />
                  ) : (
                    <Icon fontSize="small" name="KeyboardArrowDown" />
                  )}
                </IconButton>
              ) : null}
            </Box>
            <Tag startIconName="SellOutlined" color="primary" />
            <Text variant="subtitle2">{variable.name}</Text>
          </Box>
        </TableCell>
        <TableCell sx={{ width: 300 }}>
          <Box
            sx={{
              position: 'relative',
              height: '100%',
              width: '100%',
              pr: 3,
              minHeight: 32
            }}
          >
            {N_ACCOUNTS === 0 ? (
              <ButtonField onClick={() => onEdit(economySetId)}>
                Lägg till
              </ButtonField>
            ) : (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  flexWrap: 'wrap',
                  gap: 0.5
                }}
              >
                {accounts
                  .slice(0, N_LIMIT)
                  .sort(
                    (aAccount, bAccount) =>
                      parseInt(aAccount, 10) - parseInt(bAccount, 10)
                  )
                  .map((acc) => (
                    <Chip key={acc} label={acc} size="small" />
                  ))}
                {N_ACCOUNTS > N_LIMIT ? (
                  <Link onClick={() => onEdit(economySetId)}>{`...och ${
                    N_ACCOUNTS - N_LIMIT
                  } andra`}</Link>
                ) : null}
              </Box>
            )}
            <Box
              className="edit-btn"
              sx={{
                display: 'none',
                position: 'absolute',
                top: 0,
                right: 0
              }}
            >
              <IconButton size="small" onClick={() => onEdit(economySetId)}>
                <Icon fontSize="small" name="EditOutlined" />
              </IconButton>
            </Box>
          </Box>
        </TableCell>
      </TableRow>
      <TableRow noBorder={true}>
        <TableCell colSpan={3} sx={{ p: 0 }}>
          <Collapse in={open} timeout="auto" unmountOnExit={true}>
            <VariablesAccountsSection
              accountsMap={accountsMap}
              allVariables={allVariables}
              economySetId={economySetId}
              editVariable={editVariable}
              kpiVariableOptions={kpiVariableOptions}
              level={variable.level + 1}
              limitToAccounts={accounts}
              onUpdateVariableAccounts={onUpdateVariableAccounts}
              setEditVariable={setEditVariable}
              variables={children}
            />
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}

export default Row
