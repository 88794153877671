import React from 'react'

import Avatar from 'components_new/atoms/Avatar'
import Box from 'components_new/atoms/Box'
import List from 'components_new/atoms/List'
import ListItem from 'components_new/atoms/List/ListItem'
import ListItemIcon from 'components_new/atoms/List/ListItemIcon'
import ListItemText from 'components_new/atoms/List/ListItemText'
import Text from 'components_new/atoms/Text'

import Checklist from './Checklist'

const guidelines = [
  {
    title: 'Var tydlig med vad du vill uppnå',
    body: 'Syftet med din dashboard styr dess design. Se till att du har ett fokus och ett syfte innan du faktiskt börjar bygga.'
  },
  {
    title: 'Inkludera endast det som är viktigt',
    body: 'Allt ska stödja dashboardens avsikt. Skala hellre bort saker så att det som är viktigt framhävs.'
  },
  {
    title: 'Tänk på "data-bläck"-förhållandet',
    body: 'Undvik dekorativa element och för mycket text som inte förmedlar data. Ögat ska dras till mäte- och nyckeltalen.'
  },
  {
    title: 'Avrunda dina siffror',
    body: 'Att vara alltför exakt kan skymma viktiga förändringar.'
  },
  {
    title: 'Gruppera relaterade mått',
    body: 'Gör dina mätetal lätta att hitta.'
  },
  {
    title: 'Var konsekvent',
    body: 'Använd samma visualiseringar och layout för att underlätta jämförelse.'
  },
  {
    title: 'Använd storlek och position för att visa hierarki',
    body: 'Gör det tydligt för tittaren vad som är viktigast.'
  },
  {
    title: 'Ge dina siffror sammanhang',
    body: 'Hjälp tittarna att avgöra om ett tal är bra, dåligt, normalt eller ovanligt.'
  },
  {
    title: 'Använd tydliga etiketter för din målgrupp',
    body: 'Håll dem korta och självförklarande.'
  },
  {
    title: 'Kom ihåg att det är för människor',
    body: 'Det är okej att bryta regler om det ökar engagemanget.'
  }
]

const UsabilityView = () => {
  return (
    <>
      <Checklist />
      <Box
        sx={{
          p: 4,
          position: 'relative',
          bgcolor: 'background.default',
          borderRadius: 2
        }}
      >
        <Text color="inherit" gutterBottom={true} variant="h4">
          10 rekommendationer
        </Text>
        <Text color="inherit" variant="body1">
          Att bygga en användarvänlig dashboard är inte så lätt. Nedan har vi
          listat några saker att tänka på.
        </Text>
        <List dense={true}>
          {guidelines.map((item, i) => (
            <ListItem key={item.title} sx={{ px: 0 }}>
              <ListItemIcon>
                <Avatar sx={{ width: 32, height: 32 }}>{i + 1}</Avatar>
              </ListItemIcon>
              <ListItemText
                primary={item.title}
                primaryTypographyProps={{
                  fontWeight: 'medium',
                  variant: 'body1'
                }}
                secondary={item.body}
                secondaryTypographyProps={{
                  variant: 'body1'
                }}
              />
            </ListItem>
          ))}
        </List>
      </Box>
    </>
  )
}

export default UsabilityView
