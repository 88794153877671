import React, { useState, useMemo } from 'react'

import Button from 'components_new/atoms/Button'

import AdvancedDialog from 'components_new/molecules/AdvancedDialog'

import EditKpiSection from './EditKpiSection'

import { KPI_UNIT } from 'types/Enums'

import { formatBlock, getEmptyBlock, isValidBlock } from './utils'
import {
  KpiTemplate,
  KpiTemplateCalculationBlock,
  PutKpiTemplateCalculationBlock
} from 'types/GlobalKpiTemplates'
import { KpiVariable, KpiVariableWithChildren } from 'types/GlobalKpiVariable'

interface AddKpiDialogProps {
  formattedVariables: KpiVariableWithChildren[]
  onClose: () => void
  onSave: (body: {
    name: string
    unit: KPI_UNIT
    blocks: PutKpiTemplateCalculationBlock
  }) => void
  open: boolean
  variables: KpiVariable[]
  sx?: object
  templates: KpiTemplate[]
}

const AddKpiDialog = (props: AddKpiDialogProps) => {
  const {
    formattedVariables,
    onClose,
    onSave,
    open,
    variables,
    sx,
    templates
  } = props

  const [editUnit, setEditUnit] = useState<KPI_UNIT>(KPI_UNIT.SEK)
  const [editName, setEditName] = useState<string>('')
  const [editFormula, setEditFormula] = useState<KpiTemplateCalculationBlock>(
    getEmptyBlock()
  )

  const validBlock = useMemo(() => isValidBlock(editFormula), [editFormula])

  const validName = useMemo(() => {
    return !templates.find((t) => t.title === editName)
  }, [editName])

  const resetEdits = () => {
    setEditUnit(KPI_UNIT.SEK)
    setEditName('')
    setEditFormula(getEmptyBlock())
  }

  return (
    <AdvancedDialog
      fullWidth={true}
      maxWidth="lg"
      title="Lägg till nyckeltal"
      open={open}
      onClose={onClose}
      sx={sx}
      actions={
        <>
          <Button
            variant="text"
            onClick={() => {
              onClose()
              resetEdits()
            }}
          >
            Avbryt
          </Button>
          <Button
            disabled={!editName || !validBlock || !validName}
            variant="contained"
            onClick={() => {
              onSave({
                name: editName,
                unit: editUnit,
                blocks: formatBlock(editFormula)
              })
              resetEdits()
            }}
          >
            Spara
          </Button>
        </>
      }
    >
      <EditKpiSection
        formattedVariables={formattedVariables}
        editFormula={editFormula}
        editName={editName}
        editUnit={editUnit}
        setEditFormula={setEditFormula}
        setEditName={setEditName}
        setEditUnit={setEditUnit}
        variables={variables}
        validName={validName}
        templates={templates}
      />
    </AdvancedDialog>
  )
}

export default AddKpiDialog
