import React, { ReactNode } from 'react'

import Tooltip, { TooltipProps } from 'components_new/atoms/Tooltip'

interface TooltipWrapperProps extends Omit<TooltipProps, 'title'> {
  title?: ReactNode
}

/**
 * The Tooltip wrapper component is used for being able to
 * show tooltips on disabled items.
 */

const TooltipWrapper = React.forwardRef(
  (props: TooltipWrapperProps, ref: any) => {
    const { children, title } = props

    return title ? (
      <Tooltip ref={ref} title={title}>
        <div>{children}</div>
      </Tooltip>
    ) : (
      children
    )
  }
)

TooltipWrapper.displayName = 'TooltipWrapper'
export default TooltipWrapper
