import React from 'react'

import Box from 'components_new/atoms/Box'
import Button from 'components_new/atoms/Button'
import Icon from 'components_new/atoms/Icon'

interface ActionsProps {
  showActions: boolean
  isAdmin: boolean
  isPublic: boolean
  onClose: () => void
  onCopy: () => void
  setTab: (value: 'embed' | 'email' | 'default') => void
  tab: 'embed' | 'email' | 'default'
}

const Actions = (props: ActionsProps) => {
  const { showActions, isAdmin, isPublic, onClose, onCopy, setTab, tab } = props

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between'
      }}
    >
      <Box
        sx={{
          display: 'flex',
          gap: 1
        }}
      >
        {tab === 'default' ? (
          <>
            {showActions ? (
              <>
                {isPublic ? (
                  <Button
                    color="primary"
                    startIcon={<Icon name="Link" />}
                    variant="contained"
                    onClick={onCopy}
                  >
                    Kopiera publik länk
                  </Button>
                ) : null}
                <Button
                  startIcon={<Icon name="MailOutlined" />}
                  variant="outlined"
                  onClick={() => setTab('email')}
                >
                  Bjud in
                </Button>
                {isAdmin ? (
                  <Button
                    startIcon={<Icon name="CodeOutlined" />}
                    variant="outlined"
                    onClick={() => setTab('embed')}
                  >
                    Bädda in
                  </Button>
                ) : null}
              </>
            ) : null}
          </>
        ) : null}
      </Box>
      <Button onClick={onClose} variant="text">
        Stäng
      </Button>
    </Box>
  )
}

export default Actions
