import React, { useState } from 'react'

import {
  ComparativePeriod,
  DateFilterType,
  ForecastPeriod,
  InnerWidgetPatchBody,
  PeriodFilter,
  WidgetObject,
  WidgetType,
  translateForecastPeriod
} from 'types/GlobalWidget'

import {
  translateComparativePeriod,
  translateDateFilter
} from 'utils/enumTranslator'

import Icon from 'components_new/atoms/Icon'
import Switch from 'components_new/atoms/Switch'

import SettingsDrawer from 'components_new/molecules/SettingsDrawer'
import SettingsEditDialog from 'components_new/molecules/SettingsEditDialog'
import { TInputType } from 'components_new/molecules/SettingsEditDialog/utils'
import SettingsGroup from 'components_new/molecules/SettingsGroup'
import SettingsItem from 'components_new/molecules/SettingsItem'

import {
  getComparativePeriodOptions,
  getDateFilterOptions,
  getDisabledComparative,
  getDisabledForecast,
  getForecastPeriodOptions
} from './utils'

interface DatesSectionProps {
  onClose: () => void
  updateWidget: (arg0: string, arg1: InnerWidgetPatchBody) => void
  widget: WidgetObject
}

const DatesSection = (props: DatesSectionProps) => {
  const { onClose, updateWidget, widget } = props

  const handleToggle = (
    property: 'date_filter_is_locked' | 'restrict_to_period' | 'show_date'
  ) => {
    let body: InnerWidgetPatchBody = {}

    switch (property) {
    case 'date_filter_is_locked':
      body = {
        date_filter_is_locked: !widget.settings.date_filter_is_locked
      }
      break
    case 'restrict_to_period':
      body = {
        restrict_to_period:
            !widget.settings.date_filter.restrict_to_period.selected
      }
      break
    case 'show_date':
      body = {
        show_date: !widget.settings.show_date
      }
      break
    }

    updateWidget(widget.id, body)
  }

  /*-- drawer --*/
  const [drawer, setDrawer] = useState<{
    open: boolean
    title: string
  }>({
    open: false,
    title: ''
  })

  const handleBackDrawer = () => {
    setDrawer((prevState) => ({ ...prevState, open: false }))
  }

  const handleCloseDrawer = () => {
    handleBackDrawer()
    onClose()
  }

  const handleOpenDrawer = () => {
    setDrawer({
      open: true,
      title: 'Begränsa'
    })
  }

  /*-- dialogs --*/
  const [dialog, setDialog] = useState<{
    open: boolean
    property: TProperty
    title: string
    data: {
      options: { label: string; value: any }[]
      value: string
      type: TInputType
    }
  }>({
    open: false,
    property: 'comparative_period',
    title: '',
    data: {
      options: [],
      value: '',
      type: 'text'
    }
  })

  // Properties edited in this component.
  type TProperty = 'comparative_period' | 'date_filter' | 'forecast_period'

  const getPropertyTitle = (property: TProperty) => {
    switch (property) {
    case 'comparative_period':
      return 'Jämför med'
    case 'date_filter':
      return 'Historiskt'
    case 'forecast_period':
      return 'Prognos'
    }
  }

  const handleCloseDialog = () => {
    setDialog((prevState) => ({ ...prevState, open: false }))
  }

  const handleOpenDialog = (property: TProperty) => {
    let inputValue = ''
    let inputType: TInputType = 'text'
    let inputOptions: { label: string; value: any; disabled?: boolean }[] = []

    switch (property) {
    case 'comparative_period':
      inputValue = widget.settings.comparative_period.selected.value ?? 'none'
      inputType = 'select'
      inputOptions = getComparativePeriodOptions(
        widget.settings.comparative_period.options,
        widget.settings.date_filter.selected.value
      )
      break
    case 'date_filter':
      inputValue = widget.settings.date_filter.selected.value
      inputType = 'select'
      inputOptions = getDateFilterOptions(
        widget.settings.date_filter.options,
        widget.settings.comparative_period.selected.value
      )
      break
    case 'forecast_period':
      inputValue = widget.settings.forecast_period.selected ?? 'none'
      inputType = 'select'
      inputOptions = getForecastPeriodOptions()
      break
    }

    setDialog({
      open: true,
      property: property,
      title: getPropertyTitle(property),
      data: {
        options: inputOptions,
        type: inputType,
        value: inputValue
      }
    })
  }

  const handleEditDialogValue = (value: string) => {
    setDialog((prevState) => ({
      ...prevState,
      data: { ...prevState.data, value }
    }))
  }

  const handleSubmitDialog = () => {
    if (true) {
      let body: InnerWidgetPatchBody = {}

      switch (dialog.property) {
      case 'comparative_period':
        body = {
          comparative_period:
              dialog.data.value === 'none' || !dialog.data.value
                ? null
                : (dialog.data.value as ComparativePeriod)
        }
        break
      case 'date_filter':
        body = {
          date_filter: dialog.data.value as PeriodFilter
        }
        break
      case 'forecast_period':
        body = {
          forecast_period:
              dialog.data.value === 'none'
                ? null
                : (dialog.data.value as ForecastPeriod)
        }
        break
      }

      updateWidget(widget.id, body)
    }
  }

  /*-- disabled --*/
  const { disabledComparative, disabledComparativeText } =
    getDisabledComparative(widget)
  const { disabledForecast, disabledForecastText } = getDisabledForecast(widget)

  return (
    <>
      <SettingsGroup title={'Tidsperiod'}>
        <SettingsItem
          actions={
            <Switch
              checked={widget.settings.show_date}
              onChange={() => handleToggle('show_date')}
              size="small"
            />
          }
          title="Visa"
        />
        {[
          WidgetType.BAR_CHART,
          WidgetType.BUBBLE_CHART,
          WidgetType.COMBO_CHART,
          WidgetType.KEY_FIGURE,
          WidgetType.LINE_CHART,
          WidgetType.MAP,
          WidgetType.LIST,
          WidgetType.PIE_CHART
        ].includes(widget.settings.type.selected) ? (
              <>
                <SettingsItem
                  icon={<Icon name={'HistoryOutlined'} />}
                  onClick={() => handleOpenDialog('date_filter')}
                  title="Historiskt"
                  value={
                    widget.settings.date_filter.selected?.value
                      ? translateDateFilter[
                        widget.settings.date_filter.selected.value
                      ]
                      : null
                  }
                  variant="edit"
                />
                {widget.settings.forecast_period.available ? (
                  <SettingsItem
                    disabled={disabledForecast}
                    disabledText={disabledForecastText}
                    icon={<Icon name={'UpdateOutlined'} />}
                    onClick={() => handleOpenDialog('forecast_period')}
                    title="Prognos"
                    value={
                      widget.settings.forecast_period.selected
                        ? translateForecastPeriod[
                          widget.settings.forecast_period.selected
                        ]
                        : null
                    }
                    variant="edit"
                  />
                ) : null}
                {[
                  WidgetType.BAR_CHART,
                  WidgetType.COMBO_CHART,
                  WidgetType.KEY_FIGURE,
                  WidgetType.LINE_CHART,
                  WidgetType.MAP,
                  WidgetType.LIST
                ].includes(widget.settings.type.selected) ? (
                      <SettingsItem
                        disabled={disabledComparative}
                        disabledText={disabledComparativeText}
                        icon={<Icon name={'ImportExportOutlined'} />}
                        onClick={() => handleOpenDialog('comparative_period')}
                        title="Jämför med"
                        value={
                          widget.settings.comparative_period.selected.value
                            ? translateComparativePeriod[DateFilterType.PERIOD][
                              widget.settings.date_filter.selected?.value
                            ][widget.settings.comparative_period.selected.value]
                            : null
                        }
                        variant="edit"
                      />
                    ) : null}
              </>
            ) : null}
        <SettingsItem onClick={handleOpenDrawer} title="Begränsa" />
      </SettingsGroup>

      {/*-- drawer --*/}
      <SettingsDrawer
        open={drawer.open}
        onBack={handleBackDrawer}
        onClose={handleCloseDrawer}
        title={drawer.title}
      >
        <SettingsGroup
          helperText={
            <>
              Om du låser tidsperioden kommer användarens egna tidsperiodfilter
              inte kunna påverka widgeten.
            </>
          }
        >
          <SettingsItem
            actions={
              <Switch
                checked={widget.settings.date_filter_is_locked}
                onChange={() => handleToggle('date_filter_is_locked')}
              />
            }
            title={'Lås'}
          />
        </SettingsGroup>
        {widget.settings.date_filter.restrict_to_period.available ? (
          <SettingsGroup
            helperText={
              <>
                Om du begränsar tidsperioden till senaste stängda bokslutsperiod
                kommer din widget aldrig att visa data senare än det, oavsett
                vad som ställs in.
              </>
            }
          >
            <SettingsItem
              actions={
                <Switch
                  checked={
                    widget.settings.date_filter.restrict_to_period.selected
                  }
                  onChange={() => handleToggle('restrict_to_period')}
                />
              }
              title="Senaste stängda bokslutsperiod"
            />
          </SettingsGroup>
        ) : null}
      </SettingsDrawer>

      {/*-- dialogs --*/}
      <SettingsEditDialog
        onChange={handleEditDialogValue}
        onClose={handleCloseDialog}
        onSubmit={handleSubmitDialog}
        open={dialog.open}
        options={dialog.data.options}
        title={dialog.title}
        type={dialog.data.type}
        value={dialog.data.value}
      />
    </>
  )
}

export default DatesSection
