import React, { ReactNode, useRef } from 'react'

import 'chart.js/auto'
import { Chart } from 'react-chartjs-2'

import { useTheme } from '@mui/material'

import Box from 'components_new/atoms/Box'

interface BarChartProps {
  data: {
    labels: string[]
    datasets: {
      label: string
      values: number[]
    }[]
  }
  flexItem?: boolean
  orientation?: 'horizontal' | 'vertical'
}

const BarChart = (props: BarChartProps) => {
  const { data, flexItem, orientation } = props

  const chartRef = useRef<any>(null)

  const theme = useTheme()

  return (
    <>
      <ChartContainer flexItem={Boolean(flexItem)}>
        <Chart
          ref={chartRef}
          type="bar"
          data={{
            labels: data.labels,
            datasets: data.datasets.map((dataset) => {
              return {
                label: dataset.label,
                data: dataset.values,
                backgroundColor: [theme.palette.primary.main],
                borderColor: [theme.palette.primary.main],
                borderWidth: 0,
                borderRadius: 4
              }
            })
          }}
          options={{
            indexAxis: orientation === 'horizontal' ? 'y' : 'x',
            interaction: {
              intersect: true
            },
            maintainAspectRatio: false,
            plugins: {
              legend: {
                display: false
              }
            },
            responsive: true,
            scales: {
              x: {
                grid: {
                  display: false,
                  color: theme.palette.divider
                },
                border: {
                  display: false
                },
                ticks: {
                  color: theme.palette.text.secondary
                }
              }
            }
          }}
        />
      </ChartContainer>
    </>
  )
}

interface ChartContainerProps {
  children: ReactNode
  flexItem: boolean
}

const ChartContainer = (props: ChartContainerProps) => {
  const { children, flexItem } = props

  if (flexItem) {
    return (
      <Box sx={{ position: 'relative', flexGrow: 1, minHeight: 0 }}>
        {children}
      </Box>
    )
  }

  return <>{children}</>
}

export default BarChart
