import React from 'react'

import Alert from 'components_new/atoms/Alert'
import Link from 'components_new/atoms/Link'
import Icon from 'components_new/atoms/Icon'

const NotInLicense = () => {
  return (
    <Alert severity="warning" icon={<Icon name="PrivacyTip" />}>
      <strong>Licens saknas.</strong> Din organisation har inte inkluderat
      funktionaliteten (att externt dela dashboards) i sin licens. Kontakta{' '}
      <Link color="inherit" href={'mailto:hej@homepal.se'}>
        hej@homepal.se
      </Link>{' '}
      för att lägga till det här.
    </Alert>
  )
}

export default NotInLicense
