import { Category } from 'redux/reducers/Dashboards'

import { WidgetType } from './GlobalWidget'
import { KpiVariable } from './GlobalKpiVariable'

import { FilterOption } from 'utils/types'

export enum KpiTemplateType {
  STANDARD = 'STANDARD',
  CUSTOM = 'CUSTOM',
  KEY_FIGURE = 'KEY_FIGURE'
}

export enum KpiTemplateCalculationBlockType {
  'BLOCK' = 'block',
  'VARIABLE' = 'variable',
  'TEMPLATE' = 'template',
  'NUMBER' = 'number'
}

export enum KpiTemplateCalculationOperation {
  PLUS = '+',
  MINUS = '-',
  DIVISION = '/',
  MULTIPLICATION = '*'
}

export interface KpiTemplateCalculationBlock {
  id: string
  left_value: string | null
  right_value: string | null
  left_kpi_template: KpiTemplate | null
  right_kpi_template: KpiTemplate | null
  left_kpi_variable: KpiVariable | null
  right_kpi_variable: KpiVariable | null
  left_kpi_calculation_block: KpiTemplateCalculationBlock | null
  right_kpi_calculation_block: KpiTemplateCalculationBlock | null
  left_type: KpiTemplateCalculationBlockType | null
  right_type: KpiTemplateCalculationBlockType | null
  operation: KpiTemplateCalculationOperation
}

export interface KpiStatus {
  perform_template_id?: string
  available_in_license: boolean
  ordered: boolean
  integrated: boolean
  modelled: boolean
  quality_assured: boolean
  required_target_missing: boolean
}

export interface KpiTemplate {
  allow_delete: boolean
  allow_per_sqm: boolean
  allow_per_number_of_objects: boolean
  allow_rolling: boolean
  id: string
  custom_calculation_block: KpiTemplateCalculationBlock | null
  default_calculation_block: KpiTemplateCalculationBlock | null
  category: Category
  title: string
  summary: string
  can_show_comparative: boolean
  segment_relation_keys: string[]
  filter_relation_keys: string[]
  status: KpiStatus | null
  subcategory: string
  sub_kpis: KpiTemplate[]
  is_premium: boolean
  unit: string | null
  url: string
  demo_url: string | null
  type: KpiTemplateType
  widget_type: WidgetType
}

export type KpiTemplateRedux = { [key: string]: KpiTemplate }

export interface KpiTemplateFilterOptionsBody {
  [attributeOptionId: string]: FilterOption
}

export interface KpiTemplateReducerType {
  data: KpiTemplateRedux
  fetchedKpis: boolean
  fetchingKpis: boolean
  fetchedFilterOptions: boolean
  fetchingFilterOptions: boolean
  filterOptions: KpiTemplateFilterOptionsBody
}

export type PutKpiTemplateCalculationBlock = {
  left_kpi_calculation_block: PutKpiTemplateCalculationBlock | null
  right_kpi_calculation_block: PutKpiTemplateCalculationBlock | null
  left_kpi_template_id: string | null
  right_kpi_template_id: string | null
  left_kpi_variable_id: string | null
  right_kpi_variable_id: string | null
  left_value: string | null
  right_value: string | null
  operation: KpiTemplateCalculationOperation
}
