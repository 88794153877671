import { KpiVariable } from 'types/GlobalKpiVariable'
import { translateOperator } from './kpi_dialogs/KpiFormulaBlock/utils'

import {
  KpiTemplate,
  KpiTemplateCalculationBlock
} from 'types/GlobalKpiTemplates'

export function blockToString(
  block: KpiTemplateCalculationBlock,
  variables: KpiVariable[],
  templates: KpiTemplate[]
): string {
  let left = ''
  let right = ''

  if (block.left_value) {
    left = block.left_value.toString()
  }
  if (block.right_value) {
    right = block.right_value.toString()
  }

  if (block.left_kpi_variable) {
    const id = block.left_kpi_variable.id
    const variable = variables.find((v) => v.id === id) as KpiVariable

    left = variable.name
  }
  if (block.right_kpi_variable) {
    const id = block.right_kpi_variable.id
    const variable = variables.find((v) => v.id === id) as KpiVariable

    right = variable.name
  }
  if (block.left_kpi_template) {
    const id = block.left_kpi_template.id
    const template = templates.find((v) => v.id === id) as KpiTemplate

    left = template.title
  }
  if (block.right_kpi_template) {
    const id = block.right_kpi_template.id
    const template = templates.find((v) => v.id === id) as KpiTemplate

    right = template.title
  }

  if (block.left_kpi_calculation_block) {
    left = `(${blockToString(
      block.left_kpi_calculation_block,
      variables,
      templates
    )})`
  }
  if (block.right_kpi_calculation_block) {
    right = `(${blockToString(
      block.right_kpi_calculation_block,
      variables,
      templates
    )})`
  }

  return `${left} ${
    block.operation ? translateOperator[block.operation] : ''
  } ${right}`
}

export function getKpiFormulaString(
  formula: KpiTemplateCalculationBlock | null,
  variables: KpiVariable[],
  templates: KpiTemplate[]
): string | null {
  if (formula) {
    return blockToString(formula, variables, templates)
  }

  return null
}

export function parseKpiVariableWithChildren(variables: KpiVariable[]) {
  const levelZero = variables.filter((v) => v.level === 0)

  const levelOne = variables.filter((v) => v.level === 1)

  const levelTwo = variables.filter((v) => v.level === 2)

  return levelZero.map((variable) => ({
    ...variable,
    children: levelOne
      .filter((v) => v.parent_kpi_variable_id === variable.id)
      .map((variableOne) => ({
        ...variableOne,
        children: levelTwo
          .filter((v) => v.parent_kpi_variable_id === variableOne.id)
          .map((v) => ({
            ...v,
            children: []
          }))
      }))
  }))
}

// Fetches the sort when indices has not been set yet
export function getInitialSort(variables: KpiVariable[]) {
  const levelZero = variables
    .filter((v) => v.level === 0)
    .sort((a, b) => a.name.localeCompare(b.name, 'sv'))
  const levelOne = variables
    .filter((v) => v.level === 1)
    .sort((a, b) => a.name.localeCompare(b.name, 'sv'))
  const levelTwo = variables
    .filter((v) => v.level === 2)
    .sort((a, b) => a.name.localeCompare(b.name, 'sv'))

  const initialSort: KpiVariable[] = []

  levelZero.forEach((v0) => {
    initialSort.push(v0)

    const levelOneChildren = levelOne.filter(
      (v1) => v1.parent_kpi_variable_id === v0.id
    )

    levelOneChildren.forEach((v1) => {
      initialSort.push(v1)

      const levelTwoChildren = levelTwo.filter(
        (v2) => v2.parent_kpi_variable_id === v1.id
      )

      initialSort.push(...levelTwoChildren)
    })
  })

  return initialSort
}

export function getPutOrder(
  variables: KpiVariable[],
  order: KpiVariable[],
  parentId?: string
) {
  const levelZero = parentId ? variables.filter((v) => v.level === 0) : order
  const levelOne = variables.filter((v) => v.level === 1)
  const levelTwo = variables.filter((v) => v.level === 2)

  const initialSort: KpiVariable[] = []

  levelZero.forEach((v0) => {
    initialSort.push(v0)

    const levelOneChildren =
      v0.id === parentId
        ? order
        : levelOne.filter((v1) => v1.parent_kpi_variable_id === v0.id)

    levelOneChildren.forEach((v1) => {
      initialSort.push(v1)

      const levelTwoChildren =
        v1.id === parentId
          ? order
          : levelTwo.filter((v2) => v2.parent_kpi_variable_id === v1.id)

      initialSort.push(...levelTwoChildren)
    })
  })

  return initialSort.map((v) => v.id)
}
