import React, { ElementType, MouseEventHandler, ReactNode } from 'react'

import { DialogActions, DialogContent } from '@mui/material'

import Box from 'components_new/atoms/Box'
import Button from 'components_new/atoms/Button'
import Dialog from 'components_new/atoms/Dialog'
import DialogTitle from 'components_new/atoms/Dialog/DialogTitle'
import Icon from 'components_new/atoms/Icon'
import IconButton from 'components_new/atoms/IconButton'
import Text from 'components_new/atoms/Text'

interface OnboardingDialogProps {
  children?: ReactNode
  component?: ElementType<any>
  contextText?: ReactNode
  disableOverflow?: boolean
  disableCloseOnBackdropClick?: boolean
  imageAdornment?: ReactNode
  imageSrc?: string
  onBack?: () => void
  onClose?: () => void
  onNext?: () => void
  onMouseDown?: MouseEventHandler<HTMLElement>
  open: boolean
  sx?: object
  step: number
  steps: number
  title?: ReactNode
}

/**
 * The OnboardingDialog component is a
 * variant of a Dialog that is designed
 * to be used when onboarding a user.
 */

const OnboardingDialog = React.forwardRef(
  (props: OnboardingDialogProps, ref: any) => {
    const {
      children,
      contextText,
      disableOverflow,
      disableCloseOnBackdropClick,
      imageAdornment,
      imageSrc,
      onBack,
      onClose,
      onNext,
      onMouseDown = (e) => e.stopPropagation(),
      step,
      steps,
      title,
      ...rest
    } = props

    return (
      <Dialog
        onClose={disableCloseOnBackdropClick ? undefined : onClose}
        onMouseDown={onMouseDown}
        maxWidth="xs"
        {...rest}
        ref={ref}
      >
        <DialogTitle sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          {title}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={onClose}
          size="small"
          sx={{
            position: 'absolute',
            right: 8,
            top: 8
          }}
        >
          <Icon fontSize="small" name="Close" />
        </IconButton>
        <DialogContent
          sx={{
            overflow: disableOverflow ? 'hidden' : undefined,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: 2,
            pt: 0
          }}
        >
          {contextText ? (
            <Box sx={{ maxWidth: 'content' }}>{contextText}</Box>
          ) : null}
          {imageSrc ? (
            <Box
              sx={{
                width: '100%',
                height: '100%',
                position: 'relative',
                borderRadius: 4,
                overflow: 'hidden'
              }}
            >
              {imageAdornment}
              <img
                src={imageSrc}
                width="100%"
                height="100%"
                style={{ objectFit: 'cover', objectPosition: '50% 50%' }}
              />
            </Box>
          ) : null}
          <Box sx={{ width: '100%', maxWidth: 'content' }}>{children}</Box>
        </DialogContent>
        <DialogActions>
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'space-between',
              flex: 1,
              pl: 1
            }}
          >
            {step !== 0 ? (
              <Text color="text.secondary" variant="caption">
                {`${step} av ${steps}`}
              </Text>
            ) : null}
            <Box sx={{ flex: 1, display: 'flex', justifyContent: 'flex-end' }}>
              {step !== 0 ? (
                <Button variant="text" onClick={onBack}>
                  Föregående
                </Button>
              ) : null}
              <Button
                fullWidth={step === 0}
                size={step === 0 ? 'large' : undefined}
                onClick={onNext}
              >
                {step === 0 ? 'Kom igång' : step === steps ? 'Klar' : 'Nästa'}
              </Button>
            </Box>
          </Box>
        </DialogActions>
      </Dialog>
    )
  }
)

OnboardingDialog.displayName = 'OnboardingDialog'
export default OnboardingDialog
