import React, { MouseEvent, ReactNode, useState } from 'react'

import { Popover } from '@mui/material'

import Box from 'components_new/atoms/Box'
import Icon from 'components_new/atoms/Icon'
import ListItemIcon from 'components_new/atoms/List/ListItemIcon'
import ListItemText from 'components_new/atoms/List/ListItemText'
import Menu from 'components_new/atoms/Menu/'
import MenuItem, { MenuItemProps } from 'components_new/atoms/Menu/MenuItem'

interface NestedOptionsMenuItemProps extends Omit<MenuItemProps, 'onClick'> {
  closeParentMenu: (event: MouseEvent<HTMLElement>) => void
  icon?: ReactNode
  MenuSx?: object
  parentMenuOpen: boolean
  title: string
  variant: 'content' | 'menu'
}

const NestedOptionsMenuItem = React.forwardRef(
  (props: NestedOptionsMenuItemProps, ref: any) => {
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
    const open = Boolean(anchorEl)

    const handleOpen = (event: MouseEvent<HTMLElement>) => {
      event.stopPropagation()
      setAnchorEl(event.currentTarget)
    }

    const handleClose = (event: MouseEvent<HTMLElement>) => {
      event.stopPropagation()
      setAnchorEl(null)
    }

    return (
      <Box
        ref={ref}
        onClick={anchorEl ? handleClose : handleOpen}
        onMouseEnter={handleOpen}
        onMouseLeave={handleClose}
      >
        <MenuItem
          onClick={(e) => {
            handleOpen(e)
            e.stopPropagation()
          }}
        >
          {props.icon ? <ListItemIcon>{props.icon}</ListItemIcon> : null}
          <ListItemText primary={props.title} />
          <ListItemIcon position="end">
            <Icon name="ArrowRightOutlined" />
          </ListItemIcon>
        </MenuItem>
        {
          /*-- content --*/
          props.variant === 'content' ? (
            <Popover
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
              disableScrollLock
              onMouseLeave={handleClose}
              onClose={handleClose}
              onMouseDown={(e) => e.stopPropagation()}
              onKeyDown={(e) => {
                e.stopPropagation()
              }}
              open={open && props.parentMenuOpen}
              PaperProps={{
                sx: { minWidth: 220, py: 1 }
              }}
              sx={{ pointerEvents: 'none', ...props.MenuSx }}
              transformOrigin={{ vertical: 'top', horizontal: 'left' }}
            >
              <Box sx={{ pointerEvents: 'auto' }}>{props.children}</Box>
            </Popover>
          ) : (
            /*-- menu --*/
            <Menu
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
              disableScrollLock
              MenuListProps={{ onMouseLeave: handleClose }}
              onClose={handleClose}
              onMouseDown={(e) => e.stopPropagation()}
              open={open && props.parentMenuOpen}
              PaperProps={{
                sx: { minWidth: 220 }
              }}
              sx={{ pointerEvents: 'none', ...props.MenuSx }}
              transformOrigin={{ vertical: 'top', horizontal: 'left' }}
            >
              <Box sx={{ pointerEvents: 'auto' }}>{props.children}</Box>
            </Menu>
          )
        }
      </Box>
    )
  }
)

NestedOptionsMenuItem.displayName = 'NestedOptionsMenuItem'
export default NestedOptionsMenuItem
