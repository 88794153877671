import React, { FC, useEffect, useState } from 'react'

import { connect, ConnectedProps } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { useParams, useLocation } from 'react-router-dom'

import { ApplicationState } from 'redux/Stores/types'

import * as DashboardActions from 'redux/actions/Dashboards'
import * as WidgetActions from 'redux/actions/Widgets'
import * as StatusBannerActions from 'redux/actions/StatusBanner'

import { LOADING_WIDGET_TITLES } from 'utils/loadingTitles'
import { getDataForWidgets } from './utils'
import { STATUS_BANNER_TIMER } from 'utils/constants'
import { DashboardFilter } from 'types/GlobalDashboardFilter'

import Box from 'components_new/atoms/Box'

import LoadingSection from 'components_new/molecules/LoadingSection'

import PublicDashboardTemplate from 'components_new/templates/PublicDashboardTemplate'
import { CustomPeriodFilter } from 'types/GlobalWidget'

const mapStateToProps = (state: ApplicationState) => ({
  AuthStore: state.AuthStore,
  KPIDashboardStore: state.KPIDashboardStore,
  StatusBannerStore: state.StatusBannerStore
})

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      ...DashboardActions,
      ...StatusBannerActions,
      ...WidgetActions
    },
    dispatch
  )
}

const connector = connect(mapStateToProps, mapDispatchToProps)

// If more props should be used, extend prop with connectedProps
export type PublicDashboardPageProps = ConnectedProps<typeof connector>

const PublicDashboardPage: FC<PublicDashboardPageProps> = (
  props: PublicDashboardPageProps
) => {
  const [dataFetched, setDataFetched] = useState(false)
  const [dashboardFilter, setDashboardFilter] = useState<DashboardFilter>([])
  const [periodFilter, setPeriodFilter] = useState<CustomPeriodFilter | null>(
    null
  )

  const { KPIDashboardStore, StatusBannerStore } = props

  const params = useParams<{ id: string }>()
  const location = useLocation()

  const dashboard = KPIDashboardStore.data[params.id]

  useEffect(() => {
    const { tryGetOnePublicDashboard } = props

    tryGetOnePublicDashboard(params.id, () => {})
  }, [])

  useEffect(() => {
    if (dashboard && !dataFetched) {
      setDataFetched(true)
      getDataForWidgets(props, dashboard, [], periodFilter, location.search)
    }
  }, [KPIDashboardStore.data])

  useEffect(() => {
    if (dashboard?.customer_id) {
      props.tryGetStatusBanner(dashboard.customer_id, true)

      const interval = setInterval(
        () => props.tryGetStatusBanner(dashboard.customer_id as string, true),
        STATUS_BANNER_TIMER
      )

      return () => clearInterval(interval)
    }

    return () => {}
  }, [dashboard?.customer_id])

  const handleResetAllTempStates = () => {
    setDashboardFilter([])
    setPeriodFilter(null)

    getDataForWidgets(props, dashboard, [], null, location.search)
  }

  return KPIDashboardStore.activeFetchOne === params.id ? (
    <PublicDashboardTemplate
      dashboard={dashboard}
      dashboardFilter={dashboardFilter}
      internal={false}
      periodFilter={periodFilter}
      resetAllTempStates={handleResetAllTempStates}
      setDashboardFilter={(newDashboardFilter, newPeriodFilter) => {
        const period =
          newPeriodFilter === undefined ? periodFilter : newPeriodFilter

        getDataForWidgets(
          props,
          dashboard,
          newDashboardFilter,
          period,
          location.search
        )

        setDashboardFilter(newDashboardFilter)

        if (newPeriodFilter !== undefined) {
          setPeriodFilter(newPeriodFilter)
        }
      }}
      setPeriodFilter={(pFilter) => {
        getDataForWidgets(
          props,
          dashboard,
          dashboardFilter,
          pFilter,
          location.search
        )

        setPeriodFilter(pFilter)
      }}
      widgets={KPIDashboardStore.data?.[params.id]?.widgets || {}}
      statusBanner={StatusBannerStore.data}
    />
  ) : (
    <Box
      sx={{
        display: 'flex',
        height: '100vh',
        width: '100vw',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      {KPIDashboardStore.errorFetchOne === params.id ? (
        <p>Det går inte att hämta dashboarden.</p>
      ) : (
        <LoadingSection titles={LOADING_WIDGET_TITLES} loading={true} />
      )}
    </Box>
  )
}

export default connector(PublicDashboardPage)
