import React, { useEffect, useState } from 'react'
import { bindActionCreators, Dispatch } from 'redux'
import { connect, ConnectedProps } from 'react-redux'

import Button from 'components_new/atoms/Button'
import Box from 'components_new/atoms/Box'
import Chip from 'components_new/atoms/Chip'
import Icon from 'components_new/atoms/Icon'
import Link from 'components_new/atoms/Link'
import List from 'components_new/atoms/List'
import ListItemButton from 'components_new/atoms/List/ListItemButton'
import ListItemText from 'components_new/atoms/List/ListItemText'
import Text from 'components_new/atoms/Text'
import TooltipWrapper from 'components_new/atoms/TooltipWrapper'

import SegmentVirtualizedTable from 'components_new/molecules/SegmentVirtualizedTable'
import SidebarDialog from 'components_new/molecules/SidebarDialog'

import EditColumnsDialog, { TColumn } from './EditColumnsDialog'
import Loading from './loading'

import { IUnderlyingDataResponse, WidgetObject } from 'types/GlobalWidget'

import { Sort } from 'utils/types'

import {
  getUnderlyingPosts,
  IUnderlyingQueryParams
} from 'redux/api/KpiOptions'
import { QueryParams } from 'redux/api/Widgets'
import { KpiOptionObject } from 'types/GlobalKpiOption'
import { arrayToObject } from 'utils/functions'
import { DETAILS_DISABLE_TEXT } from 'utils/constants'

import * as UtilActions from 'redux/actions/Utilities'
import {
  getExportableData,
  getParsedData,
  getSelectedSortedParsedData,
  INITIAL_SORT,
  ParsedData,
  TKpiOptionColumns
} from './utils'

interface UnderlyingContentDialogProps {
  content: {
    widget: WidgetObject
    initialKpiId: string
    segmentLabel: string | number | null
    queryParams: QueryParams | null
  } | null
  onClose: () => void
  open: boolean
}

/*-- redux --*/
const mapStateToProps = () => ({})

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators({ ...UtilActions }, dispatch)
}

const connector = connect(mapStateToProps, mapDispatchToProps)

type ComponentProps = ConnectedProps<typeof connector> &
  UnderlyingContentDialogProps

const UnderlyingContentDialog = (props: ComponentProps) => {
  const { content, onClose, open, tryExportDataXlsx } = props
  const [selectedKpi, setSelectedKpi] = useState<KpiOptionObject | null>(null)
  const [exporting, isExporting] = useState(false)
  const [editColumns, setEditColumns] = useState<boolean>(false)
  const [fetched, setFetched] = useState<boolean>(false)

  const [data, setData] = useState<{
    [key: string]: {
      raw: IUnderlyingDataResponse
      parsed: ParsedData
      sort: Sort
    }
  }>({})
  const kpis = content?.widget.settings.kpi_options || []

  // mapper of column and their index for each kpi option
  const [selectedColumns, setSelectedColumns] = useState<TKpiOptionColumns>({})

  const fetchData = async (columns?: TColumn[]) => {
    if (!selectedKpi) {
      // cant process
      return
    }

    const queryParams: IUnderlyingQueryParams = content?.queryParams || {}

    if (columns) {
      // add columns to query params
      queryParams.entity_columns = [...columns]
        .filter((item) => !item.hidden)
        .sort((a, b) => a.index - b.index)
        .map((item) => item.id)
    }

    setFetched(false)
    getUnderlyingPosts(selectedKpi.id, queryParams)
      .then((response) => {
        setFetched(true)
        const raw = response.data.data
        let newSort = data[selectedKpi.id]?.sort ?? INITIAL_SORT

        // reset sort if columns has been changed and
        // sort column doesn't exist among selected columns
        if (
          queryParams.entity_columns &&
          newSort &&
          !queryParams.entity_columns.includes(newSort.attribute)
        ) {
          newSort = INITIAL_SORT
        }

        setData({
          ...data,
          [selectedKpi.id]: {
            raw,
            parsed: getParsedData(raw),
            sort: newSort
          }
        })

        const kpiColumns = Object.keys(selectedColumns[selectedKpi.id] || {})

        // only update state on first fetch
        if (kpiColumns.length === 0) {
          const selectedEntityColumnIds: {
            [columnId: string]: TColumn
          } = {}

          raw.data.forEach((item) => {
            if (item.entity_column_id) {
              selectedEntityColumnIds[item.entity_column_id] = {
                id: item.entity_column_id,
                index: -1, // set -1 to use default index of entity.
                hidden: false
              }
            }
          })

          setSelectedColumns((prevState) => ({
            ...prevState,
            [selectedKpi.id]: selectedEntityColumnIds
          }))
        }
      })
      .catch(() => {})
  }

  // Hook is used to perform functions on open and on close.
  useEffect(() => {
    if (open && content) {
      const selected =
        kpis.find((item) => item.id === content.initialKpiId) || null

      setSelectedKpi(selected)
    }
  }, [open])

  // Hook is used to perform functions when selectedKpi change.
  useEffect(() => {
    if (selectedKpi && !data[selectedKpi.id]) {
      fetchData()
    }
  }, [selectedKpi?.id])

  function handleSort(sort: Sort) {
    if (selectedKpi) {
      setData({ ...data, [selectedKpi.id]: { ...data[selectedKpi.id], sort } })
    }
  }

  const handleClose = () => {
    setSelectedKpi(null)
    setSelectedColumns({})
    setEditColumns(false)
    setData({})

    onClose()
  }

  const parsedData = getSelectedSortedParsedData(data, selectedKpi?.id ?? null)

  return (
    <>
      <SidebarDialog
        data-openreplay-obscured
        open={Boolean(content)}
        onClose={handleClose}
        fullWidth
        fullHeight
        disableOverflow={true}
        maxWidth={false}
        title={
          <Box
            sx={{
              flex: 1,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1
              }}
            >
              Visa innehåll{' '}
              {content?.segmentLabel ? (
                <>
                  för{' '}
                  <Chip
                    label={content.segmentLabel}
                    size="small"
                    sx={{
                      letterSpacing: '0!important'
                    }}
                  />
                </>
              ) : null}
            </Box>
            <Button
              disabled={false}
              onClick={() => setEditColumns(true)}
              size="small"
              startIcon={<Icon name="EditOutlined" />}
            >
              Redigera kolumner
            </Button>
          </Box>
        }
        leftSidebar={
          kpis.length > 1 ? (
            <List dense={true}>
              {kpis.map((kpi) => (
                <TooltipWrapper
                  key={kpi.id}
                  title={
                    !kpi.can_show_details ? DETAILS_DISABLE_TEXT : undefined
                  }
                >
                  <ListItemButton
                    disabled={!kpi.can_show_details}
                    onClick={() => setSelectedKpi(kpi)}
                    selected={!!selectedKpi && selectedKpi.id === kpi.id}
                  >
                    <ListItemText
                      primary={kpi.title}
                      primaryTypographyProps={{
                        noWrap: true
                      }}
                    />
                  </ListItemButton>
                </TooltipWrapper>
              ))}
            </List>
          ) : null
        }
        leftSidebarWidth={192}
        actions={
          <Box
            sx={{
              flex: 1,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1,
                px: 1
              }}
            >
              <Link
                variant="body2"
                onClick={() => {
                  if (selectedKpi && fetched) {
                    isExporting(true)

                    const { headers, rows } = getExportableData(parsedData)

                    tryExportDataXlsx(headers, rows, selectedKpi.title, () =>
                      isExporting(false)
                    )
                  }
                }}
                disabled={!fetched || exporting}
              >
                <Icon
                  fontSize="inherit"
                  name="FileDownloadOutlined"
                  sx={{ mr: 0.5 }}
                />
                {exporting ? 'Exporterar till .xslx...' : 'Exportera som .xlsx'}
              </Link>
            </Box>
            <Button onClick={handleClose} variant="text">
              Stäng
            </Button>
          </Box>
        }
      >
        {fetched ? (
          <>
            <Box sx={{ borderRadius: 1, flex: 1, overflow: 'hidden' }}>
              <SegmentVirtualizedTable
                data={parsedData.rows}
                loading={!fetched}
                columns={parsedData.columns}
                sort={parsedData.sort}
                onSort={(newSort) => handleSort(newSort)}
              />
            </Box>
            <Box sx={{ pt: 1, px: 2 }}>
              <Text color="text.secondary" variant="caption">
                Totalt {parsedData.rows.length} poster
              </Text>
            </Box>
          </>
        ) : (
          <Loading sx={{ flex: 1, overflow: 'hidden' }} />
        )}
      </SidebarDialog>

      {/*-- dialogs --*/}
      {selectedKpi && data[selectedKpi.id] ? (
        <EditColumnsDialog
          columns={selectedColumns[selectedKpi.id] || {}}
          entityId={selectedKpi.entity_id as string}
          kpi={
            // if kpi_option_id is set on first column then it will
            // be displayed.
            Boolean(data[selectedKpi.id].raw.data[0]?.kpi_option_id)
              ? data[selectedKpi.id].raw.data[0].label
              : null
          }
          onClose={() => setEditColumns(false)}
          onSubmit={(columns) => {
            fetchData(columns)
            setSelectedColumns((prevState) => ({
              ...prevState,
              [selectedKpi.id]: arrayToObject(columns)
            }))
          }}
          open={editColumns}
        />
      ) : null}
    </>
  )
}

export default connector(UnderlyingContentDialog)
