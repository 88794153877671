import React, { useMemo } from 'react'

import { ThemeProvider } from '@mui/material'
import { getColorMode, getTheme } from 'themes'

import { CustomColors } from 'types/GlobalCustomization'

import Box from 'components_new/atoms/Box'
import Collapse from 'components_new/atoms/Collapse'
import Icon from 'components_new/atoms/Icon'
import IconButton from 'components_new/atoms/IconButton'
import Text from 'components_new/atoms/Text'

import IconTip from 'components_new/molecules/IconTip'
import { WidgetObject } from 'types/GlobalWidget'

interface WidgetBannerProps {
  clearCustomSegmentBy: () => void
  colors?: CustomColors | null
  customSegmentBy: string | null
  fullSize?: boolean
  scaleFactor: number
  widget: WidgetObject
}

const WidgetBanner = (props: WidgetBannerProps) => {
  const { colors, customSegmentBy, fullSize, clearCustomSegmentBy, widget } =
    props

  const mode =
    colors?.widget_background_color && !fullSize
      ? getColorMode(colors?.widget_background_color)
      : 'light'

  const segmentOption = useMemo(() => {
    if (customSegmentBy) {
      return (
        widget.settings.segment_by.options.find(
          (item) => item.id === customSegmentBy
        ) || null
      )
    }

    return null
  }, [customSegmentBy])

  return (
    <Collapse
      in={!!segmentOption}
      sx={fullSize ? undefined : { p: 1 }}
      unmountOnExit={true}
    >
      <ThemeProvider theme={getTheme(mode === 'light' ? 'dark' : 'light')}>
        <Box
          sx={{
            position: 'relative',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: 2,
            flexGrow: 0,
            flexShrink: 0,
            width: fullSize ? 'fit-content' : '100%',
            bgcolor: 'background.default',
            pl: 2,
            pr: 1,
            py: 1,
            mb: fullSize ? undefined : 2,
            borderRadius: 1
          }}
        >
          <Box
            sx={{
              minWidth: 0,
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                mb: -0.25
              }}
            >
              <Text
                color="text.secondary"
                variant="caption"
                sx={{ cursor: 'default', opacity: 0.8 }}
              >
                Anpassad uppdelning
              </Text>
              <IconTip
                size="small"
                title={
                  <>
                    <Text fontWeight="bold" gutterBottom={true} variant="body2">
                      Anpassad uppdelning
                    </Text>
                    <Text gutterBottom variant="body2">
                      Du har lagt på en anpassad uppdelning som skiljer sig åt
                      från den ursprungliga visualiseringen.
                    </Text>
                    <Text variant="body2">
                      Rubriker är tillfälligt dolda eftersom de kanske inte
                      längre beskriver det som visas.
                    </Text>
                  </>
                }
              />
            </Box>
            <Text
              color="text.primary"
              fontWeight="medium"
              sx={{ cursor: 'default' }}
            >
              {segmentOption?.name || ''}
            </Text>
          </Box>
          <Box>
            <IconButton
              sx={{
                '&:hover': {
                  color: 'text.primary'
                }
              }}
              onClick={() => clearCustomSegmentBy()}
              onMouseDown={(event) => event.stopPropagation()}
              size="small"
            >
              <Icon name="Cancel" fontSize="small" />
            </IconButton>
          </Box>
        </Box>
      </ThemeProvider>
    </Collapse>
  )
}

export default WidgetBanner
