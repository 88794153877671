import React from 'react'

import Box from 'components_new/atoms/Box'
import Skeleton from 'components_new/atoms/Skeleton'

interface LoadingProps {
  sx?: object
}

const Loading = (props: LoadingProps) => {
  const { sx } = props

  return (
    <Box sx={sx}>
      {Array.from({ length: 25 }, (_, i) => i).map((i) => (
        <Box key={i} sx={{ display: 'flex', gap: 1, mb: 1 }}>
          <Skeleton sx={{ width: 128 }} />
          <Skeleton sx={{ flex: 1 }} />
        </Box>
      ))}
    </Box>
  )
}

export default Loading
