import { Dispatch } from 'redux'
import * as Types from 'redux/Types'

import { create, destroy, update } from 'redux/api/KpiOptions'
import {
  KpiOptionPatchAttributes,
  KpiOptionPostAttributes
} from 'types/GlobalKpiOption'

export function tryCreateKpiOption(
  body: KpiOptionPostAttributes,
  dashboardId: string
) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: Types.CREATE_KPI_OPTION
    })

    create(body)
      .then((response) => {
        dispatch({
          type: Types.CREATE_KPI_OPTION_SUCCESS,
          payload: {
            dashboardId: dashboardId,
            option: response.data.data
          }
        })

        dispatch({
          type: Types.TOGGLE_SNACKBAR_OPEN,
          payload: { message: 'Nyckeltalet har lagts till' }
        })
      })
      .catch(() => {
        dispatch({
          type: Types.CREATE_KPI_OPTION_FAILED
        })

        dispatch({
          type: Types.TOGGLE_SNACKBAR_OPEN,
          payload: { message: 'Nyckeltalet kunde inte läggas till' }
        })
      })
  }
}

export function tryUpdateKpiOption(
  id: string,
  body: KpiOptionPatchAttributes,
  dashboardId: string
) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: Types.UPDATE_KPI_OPTION
    })

    const skipFetchData =
      'increase_is_positive' in body.data ||
      'upper_bound_threshold' in body.data ||
      'lower_bound_threshold' in body.data ||
      'number_of_decimals' in body.data ||
      'unit_prefix' in body.data ||
      'equivalent' in body.data ||
      'hidden' in body.data

    update(id, body)
      .then((response) => {
        dispatch({
          type: Types.UPDATE_KPI_OPTION_SUCCESS,
          payload: {
            dashboardId: dashboardId,
            data: response.data.data,
            skipFetchData
          }
        })

        dispatch({
          type: Types.TOGGLE_SNACKBAR_OPEN,
          payload: { message: 'Nyckeltalet har uppdaterats' }
        })
      })
      .catch(() => {
        dispatch({
          type: Types.UPDATE_KPI_OPTION_FAILED
        })

        dispatch({
          type: Types.TOGGLE_SNACKBAR_OPEN,
          payload: { message: 'Nyckeltalet kunde inte uppdateras' }
        })
      })
  }
}

export function tryDeleteKpiOption(
  id: string,
  widgetId: string,
  dashboardId: string
) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: Types.DELETE_KPI_OPTION
    })

    destroy(id)
      .then(() => {
        dispatch({
          type: Types.DELETE_KPI_OPTION_SUCCESS,
          payload: {
            dashboardId: dashboardId,
            id,
            widgetId: widgetId
          }
        })

        dispatch({
          type: Types.TOGGLE_SNACKBAR_OPEN,
          payload: { message: 'Nyckeltalet har tagits bort' }
        })
      })
      .catch(() => {
        dispatch({
          type: Types.DELETE_KPI_OPTION_FAILED
        })

        dispatch({
          type: Types.TOGGLE_SNACKBAR_OPEN,
          payload: { message: 'Nyckeltalet kunde inte tas bort' }
        })
      })
  }
}
