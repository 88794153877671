import React, { useEffect, useState } from 'react'

import { bindActionCreators, Dispatch } from 'redux'
import { connect, ConnectedProps } from 'react-redux'
import { ApplicationState } from 'redux/Stores/types'

import * as EntityActions from 'redux/actions/Entities'

import Box from 'components_new/atoms/Box'
import Button from 'components_new/atoms/Button'
import Chip from 'components_new/atoms/Chip'
import Icon from 'components_new/atoms/Icon'
import Link from 'components_new/atoms/Link'
import Text from 'components_new/atoms/Text'
import Tooltip from 'components_new/atoms/Tooltip'

import CheckboxItem from 'components_new/molecules/CheckboxItem'
import DndList from 'components_new/molecules/DndList'
import DndListItem from 'components_new/molecules/DndList/DndListItem'
import SimpleDialog from 'components_new/molecules/SimpleDialog'

import { TableColumn } from 'components_new/molecules/SegmentVirtualizedTable'
import { Entity, EntityColumn } from 'types/GlobalEntities'

/*-- redux --*/
const mapStateToProps = (state: ApplicationState) => ({
  EntityStore: state.EntityStore
})

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(EntityActions, dispatch)
}

const connector = connect(mapStateToProps, mapDispatchToProps)

interface EditPostDialogProps {
  entity: Entity
  onClose: () => void
  open: boolean
}

type ComponentProps = ConnectedProps<typeof connector> & EditPostDialogProps

type EntityTableColumn = TableColumn & {
  hidden: boolean
  index: number
  kpis: string[]
}

function formatEntityColumn(column: EntityColumn): EntityTableColumn {
  return {
    id: column.id,
    index: column.index,
    hidden: column.hidden,
    label: column.name,
    kpis: column.kpis
  }
}

const EditPostDialog = (props: ComponentProps) => {
  const { entity, onClose, open, tryPutEntityAttributes } = props

  const [tempElements, setTempElements] = useState<EntityTableColumn[]>([])
  const [isReset, setIsReset] = useState<boolean>(false)

  useEffect(() => {
    setTempElements(entity.columns.map(formatEntityColumn))
  }, [entity.columns, open])

  const handleSubmit = () => {
    // on reset we delete all entity attributes
    const newColumns = isReset
      ? []
      : // put entity attribute in sorted order.
        [...tempElements]
          .sort((a, b) => a.index - b.index)
          .map((item) => ({
            id: item.id,
            hidden: item.hidden
          }))

    tryPutEntityAttributes(entity.id, newColumns)
    onClose()
  }

  const handleReset = () => {
    setTempElements(
      entity.columns.map((column) =>
        formatEntityColumn({ ...column, hidden: !column.is_default })
      )
    )
    setIsReset(true)
  }

  const handleToggle = (id: string) => {
    if (isReset) {
      setIsReset(false)
    }
    setTempElements((prevElements) =>
      prevElements.map((column) =>
        column.id === id ? { ...column, hidden: !column.hidden } : column
      )
    )
  }

  return (
    <>
      <SimpleDialog
        actions={
          <Box
            sx={{
              flex: 1,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              pl: 2
            }}
          >
            <Link onClick={handleReset}>Återställ</Link>
            <Box>
              <Button onClick={onClose} variant="text">
                Avbryt
              </Button>
              <Button onClick={handleSubmit}>Spara</Button>
            </Box>
          </Box>
        }
        disableOverflow={true}
        onClose={onClose}
        open={open}
        maxWidth="sm"
        title={
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 1
            }}
          >
            Redigera kolumner för{' '}
            <Chip
              label={entity.name}
              size="small"
              sx={{
                letterSpacing: '0!important'
              }}
            />
          </Box>
        }
      >
        <Box sx={{ flex: 1, overflow: 'auto' }}>
          <Box sx={{ mb: 2 }}>
            {entity.segment_is_hidden ? null : (
              <DndListItem disabled={true} sx={{ mb: 0.5 }} variant="outlined">
                <CheckboxItem
                  checked={true}
                  label={entity.segment}
                  sx={{ mr: 1 }}
                />
                <Chip label={'Identifierare'} size="small" />
              </DndListItem>
            )}
            <DndList
              handleDragEnd={(newOrder) => {
                setTempElements((prevState) => {
                  return newOrder.map((id, index) => {
                    const element = prevState.find(
                      (item) => item.id === id
                    ) as EntityTableColumn

                    return {
                      ...element,
                      index
                    }
                  })
                })
              }}
              items={tempElements.map((item) => {
                return {
                  id: item.id,
                  content: (
                    <>
                      <CheckboxItem
                        checked={!item.hidden}
                        label={item.label}
                        onChange={() => handleToggle(item.id)}
                      />
                      {item.kpis.length > 0 ? (
                        <Tooltip
                          disableMaxWidth={true}
                          title={
                            <>
                              <Text
                                color="text.primary"
                                component="div"
                                fontWeight="medium"
                                variant="caption"
                              >
                                Kolumnen är väsentlig för{' '}
                                <b>{item.kpis.length} nyckeltal</b>, och kommer
                                därför alltid att <br />
                                visas (oavsett val) när innehållet av ett av
                                följande nyckeltal visas:
                              </Text>
                              <Text
                                color="text.secondary"
                                sx={{ my: 1 }}
                                variant="inherit"
                              >
                                <ol className="ol">
                                  {item.kpis.map((kpi) => (
                                    <li className="li" key={kpi}>
                                      {kpi}
                                    </li>
                                  ))}
                                </ol>
                              </Text>
                            </>
                          }
                        >
                          <Chip
                            icon={<Icon name="CheckBoxOutlined" />}
                            label={`${item.kpis.length} nyckeltal`}
                            size="small"
                            sx={{ cursor: 'default', ml: 1 }}
                          />
                        </Tooltip>
                      ) : null}
                    </>
                  ),
                  containerSx: {
                    mb: 0.5
                  }
                }
              })}
            />
          </Box>
        </Box>
      </SimpleDialog>
    </>
  )
}

export default connector(EditPostDialog)
