import React, { useMemo } from 'react'

import Box from 'components_new/atoms/Box'
import Text from 'components_new/atoms/Text'

import BarChart from 'components_new/molecules/charts/BarChart'

import { filterViewsByPeriod, TDashboardActivityPeriod } from './utils'
import { getParsedDate } from 'utils/dateParser'

import('dayjs/locale/sv')
import dayjs from 'dayjs'
import { TDashboardGroupActivity } from 'types/GlobalDashboardGroup'

interface DashboardViewProps {
  data: TDashboardGroupActivity
  period: TDashboardActivityPeriod
}

const DashboardView = (props: DashboardViewProps) => {
  const { data, period } = props

  // views over time
  const overTimeData = useMemo(() => {
    const today = dayjs()

    const { start_date, views } = filterViewsByPeriod(
      data.views,
      data.created_at,
      period
    )

    const dataMap = views.reduce((acc, log) => {
      const date = dayjs(log.date).format('YYYY-MM-DD')

      acc[date] = (acc[date] || 0) + 1

      return acc
    }, {} as Record<string, number>)

    const labels: string[] = []
    let currentDate = start_date

    while (currentDate.isBefore(today) || currentDate.isSame(today, 'day')) {
      labels.push(currentDate.format('YYYY-MM-DD'))
      currentDate = currentDate.add(1, 'day')
    }

    const values = labels.map((date) => dataMap[date] || 0)

    return {
      labels,
      values
    }
  }, [period])

  // views per dashboard
  const perDashboardData = useMemo(() => {
    const { views } = filterViewsByPeriod(data.views, data.created_at, period)

    const dashboardViews: { [dashboardId: string]: number } = {}

    Object.keys(data.dashboards).forEach((dashboardId) => {
      dashboardViews[dashboardId] = 0
    })

    views.forEach((view) => {
      const { dashboard_id } = view

      dashboardViews[dashboard_id] = (dashboardViews[dashboard_id] || 0) + 1
    })

    const labels = Object.keys(dashboardViews)
      .sort((a, b) => dashboardViews[b] - dashboardViews[a])
      .map((dashboardId) => data.dashboards[dashboardId].title)

    const values = Object.keys(dashboardViews)
      .sort((a, b) => dashboardViews[b] - dashboardViews[a])
      .map((dashboardId) => dashboardViews[dashboardId])

    return {
      labels,
      values
    }
  }, [period])

  const showPerDashboard = perDashboardData.labels.length > 1

  return (
    <>
      <Box
        sx={{
          p: 2,
          gap: 2,
          height: showPerDashboard ? '60%' : '100%',
          width: '100%',
          overflow: 'hidden',
          display: 'flex',
          flexDirection: 'column',
          minWidth: 0,
          position: 'relative',
          bgcolor: 'background.default',
          borderRadius: 2
        }}
      >
        <Box>
          <Text variant="subtitle2">Visningar</Text>
          <Text color="text.secondary" variant="caption">
            Över tid per dag
          </Text>
        </Box>
        <BarChart
          data={{
            labels: overTimeData.labels.map((date) => getParsedDate(date)),
            datasets: [
              {
                label: 'Antal visningar',
                values: overTimeData.values
              }
            ]
          }}
          flexItem={true}
        />
      </Box>

      {showPerDashboard ? (
        <Box
          sx={{
            p: 2,
            gap: 2,
            height: '40%',
            width: '100%',
            overflow: 'hidden',
            display: 'flex',
            flexDirection: 'column',
            minWidth: 0,
            position: 'relative',
            bgcolor: 'background.default',
            borderRadius: 2
          }}
        >
          <Box>
            <Text variant="subtitle2">Visningar</Text>
            <Text color="text.secondary" variant="caption">
              Totalt per flik
            </Text>
          </Box>
          <BarChart
            data={{
              labels: perDashboardData.labels,
              datasets: [
                {
                  label: 'Antal visningar per flik',
                  values: perDashboardData.values
                }
              ]
            }}
            flexItem={true}
            orientation="horizontal"
          />
        </Box>
      ) : null}
    </>
  )
}

export default DashboardView
