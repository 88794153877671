'use client'

import { ErrorBoundary } from 'react-error-boundary'
import React, { FC, useEffect, useState } from 'react'
import Axios from 'axios'
import { useParams } from 'react-router-dom'

import { connect, ConnectedProps } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'

import { cloneDeep } from 'lodash'
import { useLocation } from 'react-router-dom'

import { ApplicationState } from 'redux/Stores/types'

import * as StatusBannerActions from 'redux/actions/StatusBanner'

import {
  getOneAdditionalSegmentPublic,
  getOnePublic,
  getOneTotalPublic
} from 'redux/api/Widgets'
import { GetOneWidget, WidgetObject } from 'types/GlobalWidget'

import PublicWidgetTemplate, {
  ErrorCode
} from 'components_new/templates/PublicWidgetTemplate'
import { STATUS_BANNER_TIMER } from 'utils/constants'
import { mergeAdditionalSegments } from 'redux/actions/Widgets'

const mapStateToProps = (state: ApplicationState) => ({
  AuthStore: state.AuthStore,
  KPIDashboardStore: state.KPIDashboardStore,
  StatusBannerStore: state.StatusBannerStore
})

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      ...StatusBannerActions
    },
    dispatch
  )
}

const connector = connect(mapStateToProps, mapDispatchToProps)

// If more props should be used, extend prop with connectedProps
type EmbeddedWidgetPageProps = ConnectedProps<typeof connector>

function sendMessage(message: string, error?: string) {
  const url =
    'https://hooks.slack.com/services/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'

  Axios.post(
    url,
    JSON.stringify({
      message,
      navigator: window.navigator,
      error
    }),
    {
      headers: { 'content-type': 'application/x-www-form-urlencoded' }
    }
  )
}

function fallbackRender({ error }: { error: any }) {
  // Call resetErrorBoundary() to reset the error boundary and retry the render.
  sendMessage('Krasch vid load', error?.message)

  return (
    <div role="alert">
      <p>Något gick fel:</p>
      <pre style={{ color: 'red' }}>{error?.message}</pre>
    </div>
  )
}

const EmbeddedWidgetPage: FC<EmbeddedWidgetPageProps> = (
  props: EmbeddedWidgetPageProps
) => {
  const { StatusBannerStore } = props
  const params = useParams<{ id: string }>()
  const id = params.id

  if (params.id === 'cefed825-43de-4641-abd9-87d4ea479f79') {
    sendMessage('EHBs widget laddade')
  }

  const [widget, setWidget] = useState<WidgetObject | null>(null)
  const [errorCode, setErrorCode] = useState<ErrorCode>(null)
  const location = useLocation()

  useEffect(() => {
    getOnePublic(id, null, location.search)
      .then<GetOneWidget>(async (response) => {
        let data = cloneDeep(response.data.data)

        const totalPromise = data.settings.show_total
          ? getOneTotalPublic(id, null, location.search)
          : null

        const additionalsPromise = Promise.all(
          data.settings.segment_by.additional.map((item) =>
            getOneAdditionalSegmentPublic(id, item.id, null, location.search)
          )
        )

        const [responseTotal, responseAdditionals] = await Promise.all([
          totalPromise,
          additionalsPromise
        ])

        if (responseTotal) {
          data.data.totals = responseTotal.data.data
        }

        if (responseAdditionals.length > 0) {
          data = mergeAdditionalSegments(data, responseAdditionals)
        }

        return data
      })
      .then((data) => setWidget(data))
      .catch((e) => {
        if (e.message.includes('401') || e.message.includes('404')) {
          setErrorCode(404)
        } else if (e.message.includes('500')) {
          setErrorCode(500)
        }
      })
  }, [])

  useEffect(() => {
    if (widget?.customer_id) {
      props.tryGetStatusBanner(widget.customer_id, true)

      const interval = setInterval(
        () => props.tryGetStatusBanner(widget.customer_id as string, true),
        STATUS_BANNER_TIMER
      )

      return () => clearInterval(interval)
    }

    return () => {}
  }, [widget?.customer_id])

  if (widget) {
    return (
      <ErrorBoundary fallbackRender={fallbackRender}>
        <PublicWidgetTemplate
          widget={widget}
          errorCode={errorCode}
          statusBanner={StatusBannerStore.data}
        />
      </ErrorBoundary>
    )
  }

  return <ErrorBoundary fallbackRender={fallbackRender}>{null}</ErrorBoundary>
}

export default connector(EmbeddedWidgetPage)
