import React from 'react'
import { Switch } from 'react-router-dom'

import StatusBannerPage from 'components_new/pages/internal/StatusBanner'
import DataQualityPage from 'components_new/pages/internal/DataQuality'
import EmailPage from 'components_new/pages/internal/Emails'

import AuthRoute from './AuthRoute'

const Routes = ({ AuthStore }) => {
  return (
    <Switch>
      <AuthRoute
        isAuthenticated={
          AuthStore.isAuthenticated && AuthStore.user.is_homepal_user
        }
        Component={() => <StatusBannerPage />}
        path={'/internal/status-banner'}
        exact
      />
      <AuthRoute
        isAuthenticated={
          AuthStore.isAuthenticated && AuthStore.user.is_homepal_user
        }
        Component={() => <EmailPage />}
        path={'/internal/emails'}
        exact
      />
      <AuthRoute
        isAuthenticated={
          AuthStore.isAuthenticated && AuthStore.user.is_homepal_user
        }
        Component={() => <DataQualityPage />}
        path={'/internal/data-quality'}
        exact
      />
    </Switch>
  )
}

export default Routes
