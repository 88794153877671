import * as React from 'react'
import {
  TextareaAutosize as BaseTextareaAutosize,
  TextareaAutosizeProps
} from '@mui/base/TextareaAutosize'
import { styled } from '@mui/system'

interface ExtendedProps extends TextareaAutosizeProps {
  sx?: object
}

const Textarea = React.forwardRef((props: ExtendedProps, ref: any) => {
  const { sx, ...rest } = props

  const StyledTextarea = styled(BaseTextareaAutosize)(
    ({ theme }) => `
        box-sizing: border-box;
        width: 320px;
        font-family: 'IBM Plex Sans', sans-serif;
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 1.5;
        padding: 8px 12px;
        border-radius: 8px;
        color: ${
  theme.palette.mode === 'dark' ? 'background.default' : 'grey.100'
};
        background: ${theme.palette.mode === 'dark' ? 'grey.100' : 'white'};
        border: 1px solid ${
  theme.palette.mode === 'dark' ? 'grey.700' : 'grey.200'
};
        box-shadow: 0px 2px 2px ${
  theme.palette.mode === 'dark' ? 'background.default' : 'grey.50'
};
    
        &:hover {
          border-color: 'primary.main';
        }
    
        &:focus {
          border-color: 'primary.main';
          box-shadow: 0 0 0 3px ${
  theme.palette.mode === 'dark' ? 'primary.dark' : 'primary.light'
};
        }
    
        // firefox
        &:focus-visible {
          outline: 0;
        }
      `
  )

  return <StyledTextarea {...rest} ref={ref} sx={sx} />
})

Textarea.displayName = 'Textarea'
export default Textarea
