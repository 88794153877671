import { CreateEmailBody } from 'types/GlobalEmails'

const UserAttributes = {
  id: 'id',
  firstName: 'firstName',
  lastName: 'lastName',
  email: 'email',
  customerName: 'customerName',
  companyGroupName: 'companyGroupName'
}

function findIllegalTemplateAttributes(html: string) {
  const found = []
  const rxp = /{{([^}]+)}}/g
  let curMatch = rxp.exec(html)

  while (curMatch) {
    found.push(curMatch[1])
    curMatch = rxp.exec(html)
  }

  return found.filter(
    (templateAttribute) => !(templateAttribute in UserAttributes)
  )
}

function validateHtml(html: string) {
  const illegalAttributes = findIllegalTemplateAttributes(html)

  if (illegalAttributes.length > 0) {
    return `Found illegal template attributes ${illegalAttributes} allowed attribtues are ${Object.keys(
      UserAttributes
    )}`
  }

  return null
}

export function validateEmail(body: CreateEmailBody) {
  const { batch_name, user_ids, html, subject } = body
  const errors = []

  if (!batch_name) {
    errors.push('batch_name needs to be set')
  }

  if (!batch_name.match(/^[0-9a-zA-Z-_.@]+$/)) {
    errors.push('batch_name needs to be alphanumerical or "_", "-", "-", "@"')
  }

  if (!user_ids || user_ids.length === 0) {
    errors.push('Please set user ids to send to')
  }

  if (!html) {
    errors.push('Need to define html')
  }

  if (!subject) {
    errors.push('Need to define a subject for your html-email')
  }

  const htmlError = html ? validateHtml(html) : null

  if (htmlError) {
    errors.push(htmlError)
  }

  return errors
}
