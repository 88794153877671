import React, { useMemo, useEffect, useState } from 'react'

import _ from 'lodash'

import { usePrevious } from 'helpers/Functions'

import Select from 'components/common/Select'
import DatePicker from 'components/common/DatePicker'
import Input from 'components/common/Input'

import Box from 'components_new/atoms/Box'
import Divider from 'components_new/atoms/Divider'
import ToggleButton from 'components_new/atoms/ToggleButton'

import Node from './index.js'
import {
  DATE_UNITS,
  TODAY,
  parseAttributeOptions,
  getSensitiveRefColumn
} from './conf'

const TimeDifferenceNode = ({ id, selected, data, setData }) => {
  const leftNodeId = useMemo(() => data?.leftNode, [data?.globalNodeData])
  const previousLeftNodeId = usePrevious(leftNodeId)
  const leftNode = useMemo(
    () => data?.globalNodeData?.[data.leftNode],
    [data?.globalNodeData]
  )

  const leftAttributes = useMemo(() => {
    return leftNode?.data?.outputAttributes || []
  }, [leftNode])
  const [mounted, setMounted] = useState(false)
  const [newAttribute, setNewAttribute] = useState(undefined)
  const [columnState, setNewColumnState] = useState({
    from: !data.value || !!data.leftAttribute,
    to: !data.value || !!data.rightAttribute
  })

  useEffect(() => {
    const tmpNewAttribute = data.outputAttributes.find(
      (attr) => attr.shortId === data.newAttribute
    )

    setNewAttribute(tmpNewAttribute)
  }, [])

  useEffect(() => {
    if (mounted && previousLeftNodeId !== leftNodeId) {
      const timer = setTimeout(() => {
        setData(id, {
          outputAttributes: [
            ...leftAttributes.map((attr) => ({
              ...attr,
              leftAttribute: null,
              rightAttribute: null
            })),
            newAttribute
          ]
        })
      }, 1000)

      return () => clearTimeout(timer)
    }

    setMounted(true)
  }, [leftNodeId])

  const options = parseAttributeOptions(leftAttributes, data.nodeNumberMapper, [
    'DATE',
    'TIMESTAMP',
    'STRING'
  ])

  return (
    <Node
      icon="TimerOutlined"
      title={'Time difference'}
      selected={selected}
      nodeNumber={data.nodeNumberMapper[id]}
    >
      <Box sx={{ width: '240px' }}>
        <Input
          size={'small'}
          containerClassName={'width-100 nodrag'}
          placeholder={'Ny kolumn...'}
          value={newAttribute?.name}
          onChange={(val) => {
            const tmpOutput = _.cloneDeep(data.outputAttributes)
            const newAttributeIndex = tmpOutput.findIndex(
              (attr) => attr.shortId === data.newAttribute
            )

            if (newAttributeIndex > -1) {
              tmpOutput[newAttributeIndex].name = val.target.value
              tmpOutput[newAttributeIndex].realName = val.target.value

              setNewAttribute({
                ...newAttribute,
                name: val.target.value,
                realName: val.target.value
              })

              setData(id, { outputAttributes: tmpOutput })
            }
          }}
        />

        <Divider sx={{ my: 1 }} />

        <DateSection
          label={'Från'}
          column={columnState.from}
          setNewColumnState={setNewColumnState}
          itemKey={'from'}
          options={options}
          setData={(obj) => setData(id, obj)}
          data={data}
          updateSensitiveRefColumnId={(value) =>
            updateSensitiveRefColumnId(
              id,
              data,
              setData,
              newAttribute,
              setNewAttribute,
              value,
              columnState.to,
              leftAttributes
            )
          }
        />

        <DateSection
          label={'Till'}
          column={columnState.to}
          setNewColumnState={setNewColumnState}
          itemKey={'to'}
          options={options}
          setData={(obj) => setData(id, obj)}
          data={data}
          updateSensitiveRefColumnId={(value) =>
            updateSensitiveRefColumnId(
              id,
              data,
              setData,
              newAttribute,
              setNewAttribute,
              columnState.from,
              value,
              leftAttributes
            )
          }
        />

        <Select
          className={'nodrag'}
          placeholder={'Tidsenhet'}
          options={DATE_UNITS}
          size={'small'}
          sorted={false}
          value={data.unit}
          onChange={(unit) => setData(id, { unit })}
        />
      </Box>
    </Node>
  )
}

const DateSection = ({
  label,
  column,
  itemKey,
  options,
  setNewColumnState,
  setData,
  data,
  updateSensitiveRefColumnId
}) => {
  const [valueType, setValueType] = useState(
    data.value ? (data.value === TODAY ? TODAY : 'INPUT_VALUE') : null
  )
  const otherKey = itemKey === 'from' ? 'to' : 'from'
  const attributeKey = itemKey === 'from' ? 'leftAttribute' : 'rightAttribute'

  return (
    <Box sx={{ mb: 1 }}>
      {column ? (
        <Box sx={{ mb: 0.25 }}>
          <Select
            className={'nodrag'}
            inNode
            dropdownMatchSelectWidth={false}
            search
            placeholder={label}
            options={options}
            size={'small'}
            value={data[attributeKey]}
            onChange={(value) => {
              updateSensitiveRefColumnId(value)

              setData({ [attributeKey]: value })
            }}
            ignoreLockedMode
          />
        </Box>
      ) : (
        <>
          <Box sx={{ mb: 0.25 }}>
            <Select
              className={'nodrag'}
              inNode
              dropdownMatchSelectWidth={false}
              search
              placeholder={label}
              options={[
                { value: TODAY, label: 'Idag' },
                { value: 'INPUT_VALUE', label: 'Specifikt datum' }
              ]}
              onChange={(value) => {
                setValueType(value)

                setData({ value: value === 'INPUT_VALUE' ? null : value })
              }}
              size={'small'}
              value={valueType}
              ignoreLockedMode
            />
          </Box>
          {valueType === 'INPUT_VALUE' && (
            <Box sx={{ mb: 0.25 }}>
              <DatePicker
                className={'width-100 nodrag'}
                size={'small'}
                onChange={(value) => setData({ value })}
                value={data.value}
                ignoreLockedMode
              />{' '}
            </Box>
          )}
        </>
      )}
      <ToggleButton
        className={'nodrag'}
        items={[
          { title: 'Kolumn', value: true },
          { title: 'Datum', value: false }
        ]}
        onChange={(event, value) => {
          // set null to attributeKey if changed to specific date
          setData({ value: null, ...(!value ? { [attributeKey]: null } : {}) })
          if (!value) {
            setValueType(null)
          }
          setNewColumnState((currentState) => ({
            [itemKey]: value,
            [otherKey]:
              !currentState[otherKey] && !value ? true : currentState[otherKey]
          }))
        }}
        size="small"
        value={column}
      />
    </Box>
  )
}

const updateSensitiveRefColumnId = (
  id,
  data,
  setData,
  newAttribute,
  setNewAttribute,
  leftAttributeId,
  rightAttributeId,
  leftAttributes
) => {
  const tmpOutput = _.cloneDeep(data.outputAttributes)
  const newAttributeIndex = tmpOutput.findIndex(
    (attr) => attr.shortId === data.newAttribute
  )

  if (newAttributeIndex > -1) {
    const sensitiveRefColumnId = getSensitiveRefColumn(
      leftAttributeId,
      rightAttributeId,
      leftAttributes
    )

    tmpOutput[newAttributeIndex].sensitiveRefColumnId = sensitiveRefColumnId

    setNewAttribute({
      ...newAttribute,
      sensitiveRefColumnId
    })

    setData(id, { outputAttributes: tmpOutput })
  }
}

export default TimeDifferenceNode
